<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-6">
                <div class="card log-box auth">
                    <div class="card-header">
                        <h3 class="mb-0">Nová položka</h3>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="store">
                            <div class="form-group">
                                <label for="name">
                                    Název
                                </label>
                                <input id="name" type="text" v-model="form.name" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="en_name">
                                    Název anglicky
                                </label>
                                <input id="en_name" type="text" v-model="form.en_name" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="category">Kategorie</label>
                                <select id="category" v-model="form.category_id" class="custom-select" name="category">
                                    <template v-for="category in categories">
                                        <option :value="category.id">{{ category.name }}</option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="price">
                                    Cena s DPH
                                </label>
                                <input id="price" type="number" min="0" v-model="form.price_dph" class="form-control" required/>
                            </div>

                            <div class="form-group">
                                <label for="desc">
                                    Český popis
                                </label>
                                <textarea id="desc" type="text" v-model="form.item_desc" class="form-control" required></textarea>
                            </div>

                            <div class="form-group">
                                <label for="desc_en">
                                    Anglický popis
                                </label>
                                <textarea id="desc_en" type="text" v-model="form.item_desc_en" class="form-control" required></textarea>
                            </div>

                            <hr>
<!--                            <div v-for="(desc,key) in form.description">-->
<!--                                <div class="form-group">-->
<!--                                    <input type="file" accept="image/*" @click="selectPhotoId(key)" @change="selectPhoto"-->
<!--                                           :name="'photo'+key">-->
<!--                                    <img v-if="desc.url" :src="desc.url" width="75" height="75"/>-->
<!--                                </div>-->
<!--                                <div class="form-group">-->
<!--                                    <label :for="'description'+key">-->
<!--                                        Český popis-->
<!--                                    </label>-->
<!--                                    <textarea :id="'description'+key" type="text" class="form-control"-->
<!--                                              v-model="desc.text"-->
<!--                                              ></textarea>-->
<!--                                </div>-->
<!--                                <div class="form-group">-->
<!--                                    <label :for="'description_en'+key">-->
<!--                                        Anglický popis-->
<!--                                    </label>-->
<!--                                    <textarea :id="'description_en'+key" type="text" class="form-control"-->
<!--                                              v-model="desc.text_en"-->
<!--                                              ></textarea>-->
<!--                                </div>-->
<!--                                <button class="btn-danger" @click="deleteDesc(key)">delete</button>-->
<!--                                <hr>-->

<!--                            </div>-->
<!--                            <div>-->
<!--                                <button @click="addDesc" type="button">Přidat popis</button>-->
<!--                            </div>-->
<!--                            <hr v-if="form.description.count==0">-->


                            <div class="form-group flex-row float-left">
                                <label for="highlight" class="mb-0">
                                    Zobrazit na první stránce
                                </label>
                                <input id="highlight" type="checkbox" class="ml-2 form-check" v-model="form.is_highlighted"/>
                            </div>
                            <input type="submit" value="Uložit" class="btn btn-primary">
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateItem",
    props: {
        categories: Object
    },
    components: {},
    data: () => {
        return {
            form: {
                en_name: null,
                name: null,
                category_id: Number,
                price_dph: null,
                description: [],
                is_highlighted: false,
                item_desc: '',
                item_desc_en: '',
            },
            selected_photo_id: 0

        }
    },
    mounted() {
    },
    methods: {
        idk(e) {
            console.log(e)
        },
        store() {
            axios.post('/admin/items/create', this.form).catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.status) {
                    location.href = '/admin/items/' + res.data.result;
                }
            })
        },
        addDesc() {
            this.form.description.push({
                photo: "",
                text: "",
                url: "",
            })
        },
        deleteDesc(key) {
            this.form.description = this.form.description.filter((item, index) => index !== key)
        },
        selectPhoto(e, desc) {
            const file = e.target.files[0];
            this.form.description[this.selected_photo_id].url = URL.createObjectURL(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.form.description[this.selected_photo_id].photo = e.target.result;
            };

        },
        selectPhotoId(key) {
            this.selected_photo_id = key;
        },
    }
}
</script>

<style scoped>
#preview {
    display: flex;
    justify-content: center;
    align-items: center;
}

#preview img {
    max-width: 100%;
    max-height: 500px;
}
</style>
