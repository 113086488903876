<template>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-6">
                    <div class="card shadow">
                        <div class="card-header">
                            {{ __('Upravit profil') }}
                        </div>
                        <div class="card-body">
                            <form  @submit.prevent="checkAndSave">
                                <div class="form-group">
                                    <label for="first">{{__('Křestní jméno')}}</label>
                                    <input id="first" type="text" class="form-control" v-model="form.first_name"
                                           @blur="v$.form.first_name.$touch" required/>
                                    <div v-if="v$.form.first_name.$error" class="is-invalid">{{__('Křestní jméno musí být vyplněno')}}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="last">{{__('Příjmení')}}</label>
                                    <input id="last" type="text" class="form-control" v-model="form.last_name"
                                           @blur="v$.form.last_name.$touch" required/>
                                    <div v-if="v$.form.last_name.$error" class="is-invalid">{{__('Příjmení musí být vyplněno')}}
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="email">E-mail</label>
                                    <input id="email" type="text" class="form-control" v-model="form.email"
                                           @blur="v$.form.email.$touch" required/>
                                    <div v-if="v$.form.email.$error" class="is-invalid">{{__('Email není ve správném tvaru')}}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="password">{{__('Heslo')}}</label>
                                    <input id="password" type="password" class="form-control" v-model="form.password"
                                           @blur="v$.form.password.$touch"/>
                                    <div v-if="v$.form.password.$error" class="is-invalid">{{__('Heslo musí obsahovat minimálně 8 znaků, 1 velké a 1 malé písmeno')}}</div>
                                </div>
                                <div class="form-group">
                                    <label for="password_confirm">{{__('Heslo znovu')}}</label>
                                    <input id="password_confirm" type="password" class="form-control" v-model="form.password_confirm"
                                           @blur="v$.form.password_confirm.$touch" :required="form.password != null"/>
                                    <div v-if="v$.form.password_confirm.$error" class="is-invalid">{{__('Hesla se musí shodovat')}}</div>
                                </div>

                                <input type="submit" class="btn btn-primary" :value="__('Uložit')"/>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
</template>

<script>
import Swal from "sweetalert2";
import useVuelidate from '@vuelidate/core'
import {required, email, helpers, requiredIf, sameAs, minLength} from '@vuelidate/validators'
export default {
    name: "CustomerProfileEdit",
    props: {_user: Object},
    data: () => {
        return {
            form: {}
        }
    },setup: () => ({
        v$: useVuelidate()
    }),
    validations() {
        const checkDic = async (value) => {
            if (value != null && value != "") {
                const res = await axios.get('/eshop/checkout/company_check/' + value)
                return res.data.valid
            } else {
                return true
            }
        }
        return {
            name: {
                requiredIfPassword: requiredIf(this.password != null),
                minLengthPassword: minLength(8),
                sameAsPassword: sameAs(this.password)
            },
            form: {
                first_name: {required},
                last_name: {required},
                email: {email, required},
                password: {requiredIfPassword: requiredIf(this.form.password != null), validator: helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/) },
                password_confirm: {
                    requiredIfPassword: requiredIf(this.form.password != null), sameAsPassword: sameAs(this.form.password)
                },
                dic: {
                    dic: helpers.withAsync(checkDic),
                    requiredIfDic: requiredIf(this.form.dic != null && this.form.dic != "")
                }
            }
        }
    },
    mounted() {
        this.form = this._user
    },
    methods: {
        async checkAndSave(){
            Swal.showLoading();
            if(await this.v$.$validate()){
                axios.put('/customer/edit', this.form).then(res => {
                    if(res.data.success){
                        Swal.fire({
                            icon: 'success',
                            confirmButtonText: 'OK',
                            text: res.data.message
                        }).then(result => {
                            if(result.isConfirmed){
                               window.location.href = res.data.location
                            }
                        })
                    }
                }).catch(err => {
                    Swal.fire({
                        icon: "error",
                        confirmButtonText: "OK",
                        text: err.response.data.message
                    })
                })
            } else {
                Swal.fire({
                    icon: "error",
                    confirmButtonText: "OK",
                    text: this.__("Některé pole není správně vyplněno.")
                })
                Swal.hideLoading();
            }
        }
    }
}
</script>

<style scoped>

</style>
