<template>
    <Multiselect
                :class="'searching'"
                 :options="items"
                 :label="'name'"
                 :searchable="true"
                 :valueProp="'id'"
                 :trackBy="'name'"
                 :placeholder="__('Vyhledat položky')"
                 :object="true"
                 :noOptionsText="__('Seznam položek je prázdný.')"
                 :noResultsText="__('Žádná odpovídající položka nebyla nalezena.')"
                 :loading="is_loading"
                 :internal-search="false"
                 :clear-on-select="false"
                 :close-on-select="false"
                 :options-limit="300"
                 :limit="3"
                 :max-height="600"
                 :show-no-results="false"
                 :hide-selected="true"
                 @search-change="find"
                 @select="redirect"
                 class="form-control sharp mt-auto">
        <template v-slot:option="{ option }" style="z-index: 9999">
            <img class="avatar" :src="option.photo.thumb_path">
            {{ locale == 'en'?option.en_name:option.name }}
        </template>
    </Multiselect>
    <i class="fa fa-search position-absolute"></i>
</template>

<script>
import axios from "axios";
import Multiselect from "@vueform/multiselect";

export default {
    name: "SearchComponent",
    components: {Multiselect},
    data: () => {
        return {
            is_loading: false,
            items: [],
            toggle: false,
        }
    },
    computed(){
        return window._locale
    },
    mounted() {
        if (localStorage.last_items) this.items = JSON.parse(localStorage.last_items)
    },
    methods: {
        redirect(item) {
            let stg = localStorage.last_items
            if (stg) {
                let items = JSON.parse(stg)
                if (!items.includes(item)) items.push(item)
                localStorage.last_items = JSON.stringify(items)
            } else {
                let items = []
                items.push(item)
                localStorage.last_items = JSON.stringify(items)
            }
            window.location.href = '/eshop/product/' + item.slug
        },
        find(query) {
            this.is_loading = true
            axios.get('/eshop/products/search', {params: {keyword: query}}).then(res => {
                this.items = res.data
                this.is_loading = false;
            })
        },

    }
}
</script>

<style scoped>
.overlay {
    z-index: 9;
    background: #009938;
    position: absolute;
    top: 50px;
}

.sharp {
    border-radius: 0 !important;
    width: 500px;
}

@media (max-width: 992px) {
    .sharp {
        width: 100%;
    }
}


.sharpitem {
    border-radius: 0 !important;
    position: relative;
    z-index: 9;
    width: 250px;
}

.avatar {
    object-fit: cover;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.multiselect-caret {
    display: none !important;
}

.fa-search {
    right: 3%;
    top: 15%;
    font-size: 1.5rem;
    color: lightgray;
}
</style>
