<template>
    <div class="container-fluid  p-0" id="categoryPage">
        <div class="row">
            <div class="col-12 p-0" v-if="_collection.photo_path != null">
                <img :src="_collection.photo_path" class="intro-image" alt="Úvodní fotografie">
            </div>
            <div class="col-md-12 p-0 float-right">
                <div class="category-title" v-if="_collection.photo_path != null">
                    <h1 class="m-0" v-if="locale == 'en'">{{ _collection.name_en }}</h1>
                    <h1 class="m-0" v-else>{{ _collection.name }}</h1>
                </div>
                <div class="category-title-pictureless" v-if="_collection.photo_path == null">
                    <h1 class="m-0" v-if="locale == 'en'">{{ _collection.name_en }}</h1>
                    <h1 class="m-0" v-else>>{{ _collection.name }}</h1>
                </div>
                <div class="d-flex filter-bar border-bottom">
                    <div class="dropdown " :class="_collection.photo_path == null ? 'ml-auto' : ''">
                        <button class="btn btn-success mr-auto m-0 filter-btn dropdown-toggle h-100" @click="this.filtering = !this.filtering">
                            <i class="fa fa-sliders mr-2" aria-hidden="true"></i>{{ __('Filtrovat') }}
                        </button>
                        <div class="dropdown-menu border-top" :class="filtering ? 'show' : 'hide'">
                            <div class="container">
                                <ul class="nav flex-row">
                                    <div v-if="_sizes.length > 0" class="col-12">
                                        <div class="param-title">
                                            {{ __('Velikost') }}
                                        </div>
                                        <div class="param-values">
                                            <div v-for="(param, index) in _sizes"><input type="checkbox"
                                                                                        class="custom-checkbox mx-2"
                                                                                        @change="querySize(param)"/>
                                                <label class="form-check-label">{{ param.name }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="_params.length > 0">
                                        <div v-for="(param, index) in _params" class="col-12">
                                            <div :id="'paramContainer'+param.id">
                                                <div class="param-title">
                                                    {{ param.name }}
                                                </div>

                                                <div class="param-values">
                                                    <div class="" v-for="(value, i) in param.values">
                                                        <input type="checkbox" class="custom-checkbox mx-2"
                                                               @change="query(index, i, value)"/>
                                                        <label class="form-check-label">{{ value.value }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </ul>
                                <button class="btn btn-success filter-btn search-btn my-0 mx-auto" @click="queryReq(); this.filtering = !this.filtering">Hledat</button>
                            </div>

                        </div>
                    </div>
                    <select class="custom-select border-y-0" :class="_collection.photo_path == null ? '' : 'ml-auto'" style="max-width: 250px; height: 40px; border-radius: 0" v-model="filter" @change="queryReq">
                        <option v-for="(f, index) in _filters" :value="f">{{ f[locale + '_name'] }}</option>
                    </select>
<!--                    <div class="d-md-block d-none"><search-component></search-component></div>-->
                    <div class="dropdown-divider"></div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12 mb-2 item-card" v-for="item in items">
                        <div class="card itmImg border-0">
                            <a :href="'/eshop/product/'+item.slug" class="d-flex" :class="item.photos[1] != undefined ? 'item-card-link' : ''" style="height: 320px"><img class="card-img-top pb-0 item primary-photo"
                                                                                                                                                                          v-lazy="item.photos[0].thumb_path" :alt="item.slug"/>
                                <img class="card-img-top pb-0 item secondary-photo" v-if="item.photos[1] != undefined"
                                     v-lazy="item.photos[1].thumb_path" :alt="item.slug"/>
                                <!--                                <img class="card-img-top pb-0 item" v-if=""-->
                                <!--                                     v-lazy="item.photo.thumb_path"/>-->

                            </a>
                            <div class="d-flex" v-if="user">
                                <svg xmlns="http://www.w3.org/2000/svg" v-if="favorites[item.id] === undefined"
                                     width="16"
                                     height="16"
                                     fill="currentColor"
                                     class="bi bi-heart ml-auto mb-auto"
                                     viewBox="0 0 16 16"
                                     @click="setFavourite(item)">
                                    <path
                                        d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" v-else
                                     width="16"
                                     height="16"
                                     fill="red"
                                     class="bi bi-heart bi-heart-fill ml-auto mb-auto"
                                     viewBox="0 0 16 16"
                                     @click="setFavourite(item)">
                                    <path
                                        d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                </svg>
                            </div>
                            <div class="card-body text-center d-flex flex-grow-0 mt-auto p-0">
                                <div class="mt-auto mx-auto col p-0">
                                    <div class="item-card-name">{{ item.name }}</div>
                                    <div class="row mb-2 align-items-end split" style="min-height: 60px">
                                        <div class="col text-left p-0">
                                            <div v-if="item.discounted_price_dph > 0" class="split" style="line-height: 26px">
                                                <span class="obsolete">{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</span><br/>
                                                <span class="discounted">{{ calculatePrice(item.discounted_price, currency) }} {{currency.symbol}}</span>
                                            </div>
                                            <div v-else class="d-flex split">
                                                <span class="regular mt-auto">{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</span>
                                            </div>
                                        </div>
                                        <a :href="'/eshop/product/'+item.slug" class="btn btn-primary btn-detail mx-auto mb-2 mt-auto h-100">Detail</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";

export default {
    name: "CollectionIndex",
    props: ["_params", "_filters", "_sizes", "_favorites","_collection", "_items"],
    data: () => {
        return {
            parameters: {},
            selected_sizes: [],
            items: [],
            filter: {},
            blurred: true,
            favorites: [],
            filtering: false,
        }
    },
    setup() {
        const toast = useToast();
        return {toast}
    },
    mounted() {
        this.favorites = this._favorites
        this.items = this._collection.items
        for (let i in this._params) {
            this.parameters[i] = []
        }
        this.filter = this._filters[0];
        window.addEventListener('scroll', this.updateScroll);
    },
    computed: {
        locale() {
            return window._locale;
        },
        user() {
            return window.auth_user !== null;
        },
        currency() {
            return window._currency;
        },
    },
    destroyed() {
        window.removeEventListener('scroll', this.updateScroll)
    },
    methods: {
        setFavourite(item) {
            axios.post('/customer/favourites/' + item.id + "/set").catch(err => {
                this.toast.error(err.response.data.message)
            }).then(res => {
                if (res.data.success) {
                    this.toast.success(res.data.message)
                    this.favorites = res.data.favorites;
                }
            })
        },
        querySize(value) {
            let exists = this.selected_sizes.includes(value.id)
            if (!exists) this.selected_sizes.push(value.id)
            else this.selected_sizes.splice(this.selected_sizes.findIndex(e => e === value.id), 1)
        },
        query(pindex, vindex, value) {
            let exists = false;
            for (let i = 0; i < this.parameters[pindex].length; i++) {
                if (this.parameters[pindex][i].value == value.value) {
                    this.parameters[pindex].splice(i, 1)
                    exists = true
                    break;
                }
            }
            if (!exists) {
                this.parameters[pindex].push(value);
            }
        },
        queryReq() {
            let url = '/kolekce/' + this._collection.slug + '/items?filter=' + this.filter.id + '&'
            for (const i in this.parameters) {
                for (const j in this.parameters[i]) {
                    url += 'params[]=' + this.parameters[i][j].id + '&'
                }
            }
           if(this.selected_sizes.length > 0) for (const i in this.selected_sizes) url += 'sizes[]=' + this.selected_sizes[i] + '&'
            axios.get(url).then(res => {
                this.items = res.data
            })
        },
    }
}
</script>

<style scoped>
/*.card-img-top {*/
/*    flex: 1 1 auto;*/
/*}*/

.item:hover {
    cursor: pointer;
}

.bi-heart {
    position: absolute;
    top: 7.5%;
    right: 10%;
    cursor: pointer;
}

.collapsable::before{
    font-family: "Font Awesome 5 Free";
    content: "\f077";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    float: right;
    margin-left: 10px;

    transition: transform .2s;
}

.collapsable.collapsed::before{
    content: "\f078";

    transition: transform .2s;
}
</style>

