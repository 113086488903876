<template>
    <div>
        <div class="container">
            <h1 class="text-center mb-3">Vytvoření objednávky</h1>
            <div class="row">
                <div class="col-md-6">
                    <div class="card log-box pt-2">
                        <div class="card-body">
                            <div class="form-group" :class="v$.form.first_name.$error ? 'invalid' : ''">
                                <label for="first">
                                    Křestní jméno
                                </label>
                                <input id="first" type="text" class="form-control" v-model="form.first_name" required
                                       @blur="v$.form.first_name.$touch"/>
                                <div v-if="v$.form.first_name.$error" class="is-invalid">Křestní jméno musí být
                                    vyplněno
                                </div>
                            </div>
                            <div class="form-group" :class="v$.form.last_name.$error ? 'invalid' : ''">
                                <label for="last">
                                    Příjmení
                                </label>
                                <input id="last" type="text" class="form-control" @blur="v$.form.last_name.$touch"
                                       v-model="form.last_name" required/>
                                <div v-if="v$.form.last_name.$error" class="is-invalid">Příjmení musí být vyplněno</div>
                            </div>
                            <div class="form-group">
                                <div class="form-check p-0">
                                    <input type="radio" class="custom-radio" v-model="form.type" :value="0"/>
                                    <label class="form-check-label pl-2">Fyzická osoba nepodnikající</label><br/>
                                </div>
                                <div class="form-check p-0">

                                    <input type="radio" class="custom-radio" v-model="form.type" :value="1"/>
                                    <label class="form-check-label pl-2">Fyzická osoba podnikající</label><br/>
                                </div>
                                <div class="form-check p-0">

                                    <input type="radio" class="custom-radio" v-model="form.type" :value="2"/>
                                    <label class="form-check-label pl-2">Firma</label><br/>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="company">
                                    Název firmy (nepovinné)
                                </label>
                                <input id="company" type="text" class="form-control" v-model="form.company"/>
                            </div>
                            <div class="form-group">
                                <label for="ico">
                                    IČO
                                </label>
                                <input id="ico" type="text" class="form-control" v-model="form.ico" />
                            </div>
                            <div class="form-group">
                                <label for="dic">
                                    DIČ
                                </label>
                                <input id="dic" type="text" class="form-control" v-model="form.dic"
                                       @blur="v$.form.dic.$touch"/>
                                <div v-if="v$.form.dic.$error" class="is-invalid">DIČ je neplatné</div>
                            </div>
                            <div class="form-group" :class="v$.form.email.$error ? 'invalid' : ''">
                                <label for="email">
                                    Email
                                </label>
                                <input id="email" type="text" class="form-control" v-model="form.email" required
                                       @blur="v$.form.email.$touch"/>
                                <div v-if="v$.form.email.$error" class="is-invalid">Email není ve správném tvaru</div>
                            </div>
                            <div class="form-group" :class="v$.form.address.$error ? 'invalid' : ''">
                                <label for="address">
                                    Ulice, č.p.
                                </label>
                                <input id="address" type="text" class="form-control" v-model="form.address" required
                                       @blur="v$.form.address.$touch"/>
                                <div v-if="v$.form.address.$error" class="is-invalid">Adresa není ve správném tvaru
                                </div>
                            </div>
                            <div class="form-group" :class="v$.form.city.$error ? 'invalid' : ''">
                                <label for="city">
                                    Město
                                </label>
                                <input id="city" type="text" class="form-control" v-model="form.city" required
                                       @blur="v$.form.city.$touch"/>
                                <div v-if="v$.form.city.$error" class="is-invalid">Město není ve správném tvaru</div>
                            </div>
                            <div class="form-group" :class="v$.form.zip.$error ? 'invalid' : ''">
                                <label for="zip">
                                    PSČ
                                </label>
                                <input id="zip" type="text" class="form-control" v-model="form.zip" required
                                       @blur="v$.form.zip.$touch"/>
                                <div v-if="v$.form.zip.$error" class="is-invalid">PSČ není ve správném tvaru</div>
                            </div>
                            <div class="form-group">
                                <label for="country">
                                    Stát
                                </label>
                                <select class="custom-select" id="country" v-model="form.country_id">
                                    <option v-for="country in countries" :value="country.id">{{ country.name }}</option>
                                </select>
                            </div>
                            <div class="form-group" :class="v$.form.telephone.$error ? 'invalid' : ''">
                                <label for="telephone">
                                    Telefon
                                </label>
                                <input id="telephone" type="text" class="form-control" v-model="form.telephone"
                                       @blur="v$.form.telephone.$touch" required/>
                                <div v-if="v$.form.telephone.$error" class="is-invalid">Telefonní číslo není ve správném
                                    tvaru
                                </div>
                            </div>
                            <div class="form-group form-check">
                                <input id="check" type="checkbox" class="form-check-input" v-model="checked"/>
                                <label for="check" class="form-check-label">Fakturační adresa se liší od
                                    dodací</label>
                            </div>
                            <div class="form-group">
                                <label for="lang">
                                    Jazyk komunikace (sledování objednávky, emaily..)
                                </label>
                                <select class="custom-select" id="lang" v-model="form.lang">
                                    <option value="cs">Čeština</option>
                                    <option value="en">Angličtina</option>
                                </select>
                            </div>
                            <div v-if="checked">
                                <h5>Dodací adresa</h5>
                                <div class="form-group" :class="v$.form.fa_address.$error ? 'invalid' : ''">
                                    <label for="fa_address">Ulice, č.p.</label>
                                    <input id="fa_address" type="text" class="form-control"
                                           v-model="form.fa_address" required @blur="v$.form.fa_address.$touch"/>
                                    <div v-if="v$.form.fa_address.$error" class="is-invalid">Adresa není ve správném
                                        tvaru
                                    </div>
                                </div>
                                <div class="form-group" :class="v$.form.fa_city.$error ? 'invalid' : ''">
                                    <label for="city">Město</label>
                                    <input id="fa_city" type="text" class="form-control" v-model="form.fa_city"
                                           required @blur="v$.form.fa_city.$touch"/>
                                    <div v-if="v$.form.fa_city.$error" class="is-invalid">Město není ve správném
                                        tvaru
                                    </div>
                                </div>
                                <div class="form-group" :class="v$.form.fa_zip.$error ? 'invalid' : ''">
                                    <label for="zip">PSČ</label>
                                    <input id="fa_zip" type="text" class="form-control" v-model="form.fa_zip"
                                           required @blur="v$.form.fa_zip.$touch"/>
                                    <div v-if="v$.form.fa_zip.$error" class="is-invalid">PSČ není ve správném
                                        tvaru
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="country">Stát</label>
                                    <select id="fa_country" class="custom-select" v-model="form.fa_country_id">
                                        <option v-for="country in countries" :value="country.id">{{ country.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group" :class="v$.form.fa_telephone.$error ? 'invalid' : ''">
                                    <label for="fa_telephone">Telefon</label>
                                    <input id="fa_telephone" type="text" class="form-control"
                                           v-model="form.fa_telephone"
                                           @blur="v$.form.fa_telephone.$touch" required/>
                                    <div v-if="v$.form.fa_telephone.$error" class="is-invalid">Telefonní číslo není
                                        ve správném tvaru
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 ">
                    <div class="card log-box pt-2 mt-md-0 mt-4">
                        <div class="card-body">
                            <div class="form-group" :class="v$.selected.items.$error ? 'invalid' : ''">
                                <label>Produkty</label>
                                <Multiselect v-model="params.item" :options="items" :label="'name'" :searchable="true"
                                             :valueProp="'id'" :trackBy="'id'" :placeholder="'Vyberte položky'"
                                             :object="true"
                                             :noOptionsText="'Seznam produktů je prázdný'"
                                             :noResultsText="'Žádný odpovídající produkt nebyl nalezen.'"
                                             :option-height="104"
                                             @blur="v$.selected.items.$touch" @select="openItem"
                                             @deselect="closeItem" @clear="closeItem"
                                >
                                    <template v-slot:option="{ option }">
                                        <img class="avatar" :src="option.photo.thumb_path">
                                        {{ option.name }}
                                    </template>

                                </Multiselect>
                                <div v-if="v$.selected.items.$error" class="is-invalid">Pro vytvoření objednávky musí
                                    být vybrán
                                    aspoň jeden produkt.
                                </div>
                            </div>

                            <div class="form-group" v-if="params.open">
                                <label>Velikost produktu</label>
                                <div class="jumbotron">
                                    <Multiselect v-model="params.selected" :options="params.params" :label="'name'"
                                                 :searchable="true"
                                                 :valueProp="'id'" :trackBy="'value'" :placeholder="'Vyberte velikost'"
                                                 :object="true"
                                                 :noOptionsText="'Seznam velikostí je prázdný'"
                                                 :noResultsText="'Žádná odpovídající velikost nebyla nalezena.'"

                                    ></Multiselect>

                                </div>
                                <button class="btn btn-primary btn-sm" @click="addItem()">Přidat položku</button>
                                <!--                        <div v-if="v$.selected.items.$error" class="is-invalid">Pro vytvoření objednávky musí být vybrán aspoň jeden produkt.-->
                                <!--                        </div>-->

                            </div>

                            <Carousel :settings='carouselSettings' :breakpoints='carouselBreakpoints'
                                      v-if="selected.items.length > 0">
                                <Slide v-for="(i,index) in selected.items" :key="i">
                                    <div class='carousel__item'>
                                        <div class="orderItemImgFilter"></div>
                                        <img class="orderItemImg" :src="i.item.photo.path">
                                        <span class="close" @click="delItem(index)">X</span>
                                        <label class="mb-0">Název:</label><br/> {{ i.item.name }}<br/>
                                        <label class="mb-0">Počet:</label> {{ i.count }}<br/>
                                        <label class="mb-0">Velikost: </label> {{ i.size.name }} <br/>
                                    </div>
                                </Slide>

                                <template #addons>
                                    <Pagination/>
                                    <Navigation/>
                                </template>
                            </Carousel>

                            <!--                    <div class="row">-->
                            <!--                        <div class="small orderItem col-4" v-for="(i, index) in selected.items">-->
                            <!--                            <div class="orderItemImgFilter"></div>-->
                            <!--                            <img class="orderItemImg" :src="i.item.photo.path">-->
                            <!--                            <span class="close" @click="delItem(index)">X</span>-->
                            <!--                            <label>Název:</label> {{ i.item.name }}<br/>-->
                            <!--                            <label>Počet:</label> {{ i.count }}<br/>-->
                            <!--                            <div v-for="(p, index) in i.params">-->
                            <!--                                <label>{{ index }}: </label> {{ p.value }}<br/>-->
                            <!--                            </div>-->
                            <!--                        </div>-->
                            <!--                    </div>-->

                            <div class="form-group" :class="v$.selected.giftcards.$error ? 'invalid' : ''">
                                <label>Dárkové poukazy</label>
                                <Multiselect v-model="giftcard" :options="giftcards" :label="'name'" :searchable="true"
                                             :valueProp="'id'" :trackBy="'id'" :placeholder="'Vyberte dárkové poukazy'"
                                             :object="true"
                                             :noOptionsText="'Seznam dárkových poukazů je prázdný'"
                                             :noResultsText="'Žádný odpovídající dárkový poukaz nebyl nalezen.'"
                                             :option-height="104"
                                             @deselect="deselectGift"
                                             @select="selectGift"
                                >
                                    <template v-slot:option="{ option }">
                                        <img class="avatar" src="/images/giftCard.png">
                                        {{ option.name }}
                                    </template>
                                </Multiselect>
                                <div v-if="v$.selected.giftcards.$error" class="is-invalid">Pro vytvoření objednávky
                                    musí
                                    být vybrán alespoň jeden dárkový poukaz. (Pokud není vybrán alespoň jeden produkt)
                                </div>
                            </div>
                            <Carousel :settings='carouselSettings' :breakpoints='carouselBreakpoints'
                                      v-if="selected.giftcards.length > 0">
                                <Slide v-for="(i,index) in selected.giftcards" :key="i">
                                    <div class='carousel__item'>
                                        <div class="orderItemImgFilter"></div>
                                        <img class="orderItemImg" src="/images/giftCard.png">
                                        <span class="close" @click="deselectGift(i.giftcard)">X</span>
                                        <label class="mb-0">Název:</label><br/> {{ i.giftcard.name }}<br/>
                                        <label class="mb-0">Počet:</label> {{ i.count }}<br/>
                                    </div>
                                </Slide>

                                <template #addons>
                                    <Pagination/>
                                    <Navigation/>
                                </template>
                            </Carousel>
                            <div v-if="selected.giftcards.length > 0" class="form-group">
                                <h5 class="mb-3"><b>Možnosti zaslání dárkových poukazů</b></h5>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" v-model="selected.gift_address.id"
                                           :value="0">
                                    <label class="form-check-label">
                                        Poslat přes email
                                    </label>
                                </div>
                                <hr>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" v-model="selected.gift_address.id"
                                           :value="1">
                                    <label class="form-check-label">
                                        Poslat přes dopravce
                                    </label>
                                </div>
                                <div class="form-check pl-0" v-if="selected.gift_address.id == 1">
                                    <hr/>
                                    <div class="form-group">
                                        <label>Jméno</label>
                                        <input type="text" class="form-control" v-model="selected.gift_address.name"
                                               @blur="v$.selected.gift_address.name.$touch">
                                        <div v-if="v$.selected.gift_address.name.$error" class="is-invalid">
                                            Jméno není ve správném tvaru
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="text" class="form-control" v-model="selected.gift_address.email"
                                               @blur="v$.selected.gift_address.email.$touch">
                                        <div v-if="v$.selected.gift_address.email.$error" class="is-invalid">
                                            Email není ve správném tvaru
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Telefonní číslo</label>
                                        <input type="text" class="form-control"
                                               v-model="selected.gift_address.telephone"
                                               @blur="v$.selected.gift_address.telephone.$touch">
                                        <div v-if="v$.selected.gift_address.telephone.$error" class="is-invalid">
                                            Telefonní číslo není ve správném tvaru
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Adresa</label>
                                        <input type="text" class="form-control" v-model="selected.gift_address.address"
                                               @blur="v$.selected.gift_address.address.$touch">
                                        <div v-if="v$.selected.gift_address.address.$error" class="is-invalid">
                                            Adresa není ve správném tvaru
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Město</label>
                                        <input type="text" class="form-control" v-model="selected.gift_address.city"
                                               @blur="v$.selected.gift_address.city.$touch">
                                        <div v-if="v$.selected.gift_address.city.$error" class="is-invalid">
                                            Město není ve správném tvaru
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>PSČ</label>
                                        <input type="text" class="form-control" v-model="selected.gift_address.zip"
                                               @blur="v$.selected.gift_address.zip.$touch">
                                        <div v-if="v$.selected.gift_address.zip.$error" class="is-invalid">
                                            PSČ není ve správném tvaru
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Stát</label>
                                        <select class="custom-select" v-model="selected.gift_address.country_id">
                                            <option v-for="country in countries" :value="country.id">{{ country.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <hr/>
                                </div>
                            </div>
                            <div class="form-group" :class="v$.selected.payment.$error ? 'invalid' : ''">
                                <label>Platba</label>
                                <Multiselect :options="payment" :label="'name'" :searchable="true"
                                             :valueProp="'id'" :trackBy="'name'"
                                             :placeholder="'Vyberte platební možnost'"
                                             :object="true"
                                             :noOptionsText="'Seznam platebních možností je prázdný'"
                                             :noResultsText="'Žádná odpovídající platební možnost nebyla nalezena.'"
                                             @blur="v$.selected.payment.$touch" v-model="selected.payment"
                                ></Multiselect>
                                <div v-if="v$.selected.payment.$error" class="is-invalid">Pro vytvoření objednávky musí
                                    být
                                    vybrána možnost platby.
                                </div>
                            </div>
                            <div class="form-group" :class="v$.selected.shipping.$error ? 'invalid' : ''" v-if="need_shipping">
                                <label>Doprava</label>
                                <Multiselect :options="shipping" :label="'name'" :searchable="true"
                                             :valueProp="'id'" :trackBy="'name'" :placeholder="'Vyberte typ dopravy'"
                                             :object="true" @select="openIfPacketa"
                                             :noOptionsText="'Seznam typů dopravy je prázdný'"
                                             :noResultsText="'Žádný odpovídající typ dopravy nebyl nalezen.'"
                                             @blur="v$.selected.shipping.$touch"></Multiselect>
                                <div v-if="v$.selected.shipping.$error" class="is-invalid">Pro vytvoření objednávky musí
                                    být
                                    vybrán typ dopravy.
                                </div>
                                <div v-if="selected.zasilkovna != null">
                                    ID: {{ selected.zasilkovna.id }}<br/>
                                    Místo: {{ selected.zasilkovna.place }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Uživatel</label>
                                <Multiselect v-model="selected.user" :options="users" :label="'full_name'" :searchable="true"
                                             :valueProp="'id'" :trackBy="'full_name'" :placeholder="'Vyberte uživatele'"
                                             :object="true"
                                             :noOptionsText="'Seznam uživatelé je prázdný'"
                                             :noResultsText="'Žádný odpovídající uživatel nebyl nalezen.'"
                                             @select="setUserAddress"></Multiselect>

                            </div>
                            <div class="form-group">
                                <label>Sleva</label>
                                <div class="input-group mb-3">
                                    <input type="number" class="form-control" v-model="selected.discount"
                                           :max="calculatePrice(total_price, {rate: 1}, true, country)" aria-describedby="basic-addon2" :min="0">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">Kč</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card log-box mt-4 auth">
                        <div class="card-body">
                            <h3>Souhrn objednávky</h3>

                            <div v-if="selected.shipping"><label class="mr-1"> Doprava: </label>
                                <strong>{{ selected.shipping.name }}</strong> - Cena:
                                <strong>{{ selected.shipping.price }}</strong> Kč<br/></div>
                            <div v-if="selected.payment">
                                <label> Platba: </label> <strong>{{ selected.payment.name }}</strong> - Cena:
                                <strong>{{ selected.payment.price }}</strong> Kč<br/>
                            </div>
                            <div v-if="selected.gift_address.id == 1">
                                Doprava poukazu - Cena:
                                <strong>{{
                                        calculatePrice(selected.gift_address.price/1.21, {rate: 1}, true, country)
                                    }}</strong>
                                Kč<br/>
                            </div>
                            <div v-if="selected.discount > 0">
                                <label>Sleva: </label> <strong> - {{selected.discount}} </strong> Kč<br/>
                            </div>
                            <div>
                                <label>{{ total_count }} položky</label> <br/>
                                <label> Cena: </label> <strong> {{
                                    calculatePrice(total_price, {rate: 1}, true, country)
                                }}</strong> Kč<br/>
                                <label> Cena bez DPH: </label> <strong> {{
                                    calculatePrice(total_price, {rate: 1}, false)
                                }}</strong> Kč <br/>
                            </div>
                            <button :disabled="ordering" class="btn btn-primary" @click="checkAndSend">Dokončit objednávku</button>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, email, helpers, sameAs, requiredIf, minLength, maxLength} from '@vuelidate/validators'
import Swal from 'sweetalert2'
import Multiselect from '@vueform/multiselect'
import {defineComponent, watch} from 'vue';
import {Carousel, Navigation, Slide, Pagination} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: "OrderCreate",
    components: {
        Multiselect,
        Pagination,
        Carousel,
        Slide,
        Navigation,
    },
    props: {
        items: Array,
        shipping: Array,
        payment: Array,
        users: Array,
        countries: Array,
        giftcards: Array
    },
    setup: () => ({
        v$: useVuelidate()
    }),
    validations() {
        const checkDic = async (value) => {
            if (value != null && value != "") {
                const res = await axios.get('/eshop/checkout/company_check/' + value)
                return res.data.valid
            } else {
                return true
            }
        }
        let form = {
            email: {email, required},
            zip: {
                zip(value) {
                    return value.length === 5 && Number.isInteger(parseInt(value))
                }, required
            },
            telephone: {
                telephone(value) {
                    return value.match(/\d/g).length === 9;
                }, required
            },
            city: {
                city(value) {
                    return value.length >= 2 && typeof value === 'string'
                }, required
            },
            address: {
                address(value) {
                    let regex = new RegExp("[1-9]");
                    return regex.test(value);
                }, required, maxLength: maxLength(50), minLength: minLength(2)
            },
            first_name: {
                required
            },
            last_name: {
                required
            },
            dic: {
                dic: helpers.withAsync(checkDic),
                requiredIfDic: requiredIf(this.form.dic != null && this.form.dic != "")
            }

        }
        let gift = {};
        if (this.selected.giftcards.length > 0 && this.selected.gift_address.id == 1) {
            ["name", "zip", "city", "email", "address", "telephone"].forEach(i => {
                gift[i] = form[i]
            })
            gift.name = {required}
        }
        let selected = {
            items: {
                requiredIf: requiredIf(this.selected.giftcards.length < 1)
            },
            shipping: {
                requiredIf: requiredIf(this.selected.items.length > 0),
                haveTo: ({id})=>{
                    if(id == 1)return this.selected.zasilkovna.id > 0;
                    if(id == 3)return this.selected.balikovna.id?.length > 0;
                }
            },
            payment: {
                required
            },
            giftcards: {
                requiredIf: requiredIf(this.selected.items.length < 1)
            },
            gift_address: gift
        }
        if (!this.checked) {
            return {
                form: form,
                selected: selected
            }
        } else {
            let others = {
                fa_zip: {
                    zip(value) {
                        return value.length === 5 && Number.isInteger(parseInt(value))
                    }, required
                },
                fa_telephone: {
                    telephone(value) {
                        return value.match(/\d/g).length === 9;
                    }, required
                },
                fa_city: {
                    city(value) {
                        return value.length >= 2 && typeof value === 'string'
                    }, required
                },
                fa_address: {
                    address(value) {
                        return value.length >= 2
                    }, required
                }
            }

            return {
                form: {
                    ...form,
                    ...others

                },
                selected: selected

            }
        }
    },
    data: () => {
        return {
            selected: {
                balikovna: {},
                items: [],
                discount: 0,
                payment: null,
                shipping: null,
                user: null,
                giftcards: [],
                gift_address: {
                    id: 0,
                    price: 0,
                    name: null,
                    email: null,
                    address: null,
                    city: null,
                    zip: null,
                    country_id: 1,
                    telephone: null
                },
                zasilkovna: null,
            },
            giftcard: null,
            checkout: {
                price_dph: 0,
                price: 0,
                discount: null
            },
            form: {
                id: 0,
                type: 0,
                first_name: null,
                last_name: null,
                company: null,
                email: null,
                address: null,
                city: null,
                zip: null,
                country_id: 1,
                telephone: null,
                fa_address: null,
                fa_city: null,
                fa_country_id: 1,
                fa_telephone: null,
                fa_zip: null,
                discount: 0,
                ico: null,
                dic: null,
                lang:'cs'
            },
            ordering: false,
            params: {
                open: false,
                params: [],
                selected: null,
                item: {}
            },
            checked: false,
            carouselSettings: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            carouselBreakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },
        }
    },
    mounted() {
    },
    watch: {
        selected: {
            deep: true,
            handler: function(a,b ) {
                if (a.gift_address.id == 1) this.selected.gift_address.price = 30;
                else this.selected.gift_address.price = 0;
                if (a.shipping == null && b?.id == 1) {
                    this.selected.zasilkovna = null
                }
                if(a.shipping == null && b?.id == 3){
                    this.selected.balikovna = null
                }
            },
            immediate: true,
        }
    },
    computed: {
        need_shipping(){
            return this.selected.items.length > 0
        },
        total_count() {
            let count = 0;
            for (let i = 0; i < this.selected.items.length; i++) {
                count += this.selected.items[i].count;
            }
            for (let i = 0; i < this.selected.giftcards.length; i++) {
                count += this.selected.giftcards[i].count;
            }
            return count;
        },
        total_price() {
            let total_price = 0;
            for (let i = 0; i < this.selected.items.length; i++) {
                let price = this.selected.items[i].item.discounted_price_dph > 0?(this.selected.items[i].item.discounted_price + this.selected.items[i].size.price/1.21):(this.selected.items[i].item.price + this.selected.items[i].size.price/1.21);
                total_price += price* this.selected.items[i].count;
            }
            for (let i = 0; i < this.selected.giftcards.length; i++) {
                total_price += (this.selected.giftcards[i].giftcard.price / 1.21) * this.selected.giftcards[i].count;
            }
            this.selected.shipping != null ? total_price += this.selected.shipping.price / 1.21 : 0
            this.selected.payment != null ? total_price += this.selected.payment.price / 1.21 : 0
            total_price += this.selected.gift_address.price / 1.21
            return Math.max(0,total_price - this.selected.discount / 1.21)
        },
        country() {
            return this.countries.filter(c => c.id == this.form.country_id)[0] ?? this.countries.filter(c => c.id == 1)[0];
        }
    },
    methods: {
        setPayment(value) {
            if (this.selected.payment != null) {
                if (this.selected.payment.id != value.id) {
                    this.checkout.price -= this.selected.payment.price / 1.21
                    this.checkout.price_dph -= this.selected.payment.price
                    this.selected.payment = value;
                    this.checkout.price += this.selected.payment.price / 1.21
                    this.checkout.price_dph += this.selected.payment.price
                }
            } else {
                this.selected.payment = value;
                this.checkout.price += this.selected.payment.price / 1.21
                this.checkout.price_dph += this.selected.payment.price
            }
        },
        setShipping(value) {

            if (this.selected.shipping != null) {
                if (this.selected.shipping.id != value.id) {
                    this.checkout.price -= this.selected.shipping.price / 1.21
                    this.checkout.price_dph -= this.selected.shipping.price
                    this.selected.shipping = value;
                    this.checkout.price += this.selected.shipping.price / 1.21
                    this.checkout.price_dph += this.selected.shipping.price
                }
            } else {
                this.selected.shipping = value;
                this.checkout.price += this.selected.shipping.price / 1.21
                this.checkout.price_dph += this.selected.shipping.price
            }
        },
        delItem(index) {
            this.checkout.price_dph -= (this.selected.items[index].count * this.selected.items[index].item.price_dph);
            this.checkout.price -= (this.selected.items[index].count * this.selected.items[index].item.price);
            this.selected.items.splice(index, 1);
        },
        openIfPacketa(e) {
            console.log(e)
            if (e.id == 1) {
                this.packeta()
            }
            else if(e.id == 3){
                this.balikovna()
            }
            else {
                this.selected.shipping = e
            }
        },
        balikovna(){
            Balikovna.Widget.pick(this.balikovnaSelected)
        },
        balikovnaSelected(data){
            console.log(data)
            if(data.hasOwnProperty('id')){
                this.selected.balikovna.address = data.address
                this.selected.balikovna.id = data.id
                this.selected.balikovna.name = data.name
                this.selected.shipping = this.shipping.filter(s => s.id == 3)[0]
            }else {
                this.selected.shipping = null
            }
        },
        packeta() {
            Packeta.Widget.pick("ddc9f81afdd98678", this.packetSelected)
        },

        packetSelected(data) {
            if (data != null) {
                this.selected.zasilkovna = {};
                this.selected.zasilkovna.place = data.place
                this.selected.zasilkovna.zip = data.zip;
                this.selected.zasilkovna.street = data.street;
                this.selected.zasilkovna.city = data.city;
                this.selected.zasilkovna.id = data.id;
                this.selected.shipping = this.shipping.filter(s => s.id == 1)[0]
                console.log(data);
            } else {
                this.selected.shipping = null
            }
        },
        setUserAddress(e) {
            for (const i in this.form) {
                if (this.selected.user[i] !== undefined) {
                    this.form[i] = this.selected.user[i]

                }
            }
        },
        selectGift(gift) {
            if (this.selected.giftcards.filter(g => g.giftcard.id == gift.id).length > 0) {
                this.selected.giftcards[this.selected.giftcards.findIndex(g => g.giftcard.id == gift.id)].count++
            } else {
                let giftcard = {
                    count: 1,
                    giftcard: gift
                }
                this.selected.giftcards.push(giftcard);
            }
            this.giftcard = {};
        },
        deselectGift(gift) {
            this.selected.giftcards.splice(this.selected.giftcards.findIndex(g => g.giftcard.id == gift.id), 1);
        },
        addItem() {
            if (this.selected.items.filter(i => i.size.id == this.params.selected.id && i.item.id == this.params.item.id).length > 0) {
                this.selected.items[this.selected.items.findIndex(i => i.size.id == this.params.selected.id && i.item.id == this.params.item.id)].count++
            } else {
                let item = {
                    count: 1,
                    item: this.params.item,
                    size: this.params.selected
                }
                this.selected.items.push(item);
            }
            this.params.params = [];
            this.params.selected = this.params.item = {}
            this.params.open = false
        },
        openItem(e) {
            this.params.open = true
            this.params.params = e.params
        },
        closeItem(e) {
            this.params.open = false
            this.params.selected = {};
            this.params.params = [];
            this.params.item = {};
        },
        async checkAndSend() {
            if (!await this.v$.$validate()) {
                console.log(this.v$.$errors)
                return
            }
            this.ordering = true;
            await axios.post('/admin/orders/create', {
                form: this.form,
                selected: this.selected
            }).catch(err => {
                Swal.fire({
                    title: err.response.data.message,
                    icon: "error",
                    confirmButtonColor: "red",
                    confirmButtonText: "OK"
                })
                this.ordering = false;
            }).then(res => {
                if (res.status === 200) {
                    Swal.fire({
                        title: 'Objednávka vytvořena',
                        icon: "success",
                        confirmButtonColor: "green",
                        confirmButtonText: "OK"
                    }).then(result => {
                        if (result.isConfirmed || result.isDismissed) {
                            location.href = res.data.location
                        }
                    })
                }

            })
        }
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.orderItemImg {
    object-fit: cover;
    object-position: top;
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 121px;
    z-index: -2;
}

.orderItemImgFilter {
    object-fit: cover;
    object-position: top;
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 121px;
    z-index: -1;
    background: rgba(255, 250, 205, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.close {
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 2px;
}
</style>
