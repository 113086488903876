<template>
    <div class="container">
        <div class="justify-content-center">
            <div class="">
                <div class="card log-box auth">
                    <div class="card-header">
                        <h3 class="m-0">{{ _material ? "Upravit materiál" : "Nový materiál" }}</h3>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="save">

                          <div class="form-group">
                            <label for="name">
                              Název
                            </label>
                            <input id="name" type="text" v-model="material.name" class="form-control" required/>
                          </div>
                          <div class="form-group">
                            <label for="name">
                              Název anglicky
                            </label>
                            <input id="name" type="text" v-model="material.en_name" class="form-control" required/>
                          </div>
                          <div class="form-group">
                            <label for="name">
                              URL Slug
                            </label>
                            <input id="name" type="text" v-model="material.slug" class="form-control" required/>
                          </div>
                          <div class="form-group">
                            <label>Kategorie</label>
                            <select class="custom-select" v-model="material.material_category_id" required>
                              <option v-for="category in _categories" :value="category.id">{{category.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="img">Fotografie</label>
                                    <input type="file" class="form-control-file" @change="getImg($event.target.files[0])" accept="image/*"/>
                                    <img :src="material.photo_path" class="img-fluid"/>
                                </div>

                                <div class="form-group">
                                    <label for="description">Popis</label>
                                    <vue-editor v-model="material.description" style="height: 500px; border-color: black;" class="pb-md-5">
                                    </vue-editor>
                                </div>
                                <div class="form-group">
                                    <label for="description">Popis anglicky</label>
                                    <vue-editor v-model="material.en_description" style="height: 500px" class="pb-md-5">
                                    </vue-editor>
                                </div>
                            <input type="submit" value="Uložit" class="btn btn-primary">


                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import {VueEditor} from "vue3-editor";
import Swal from "sweetalert2";
export default {
    name: "Material",
    components: {
        VueEditor
    },
    props: {
        _categories: [],
        _material: {
          id: Number,
          name: String,
          en_name: String,
          description: String,
          en_description: String,
          photo_path: String,
          material_category_id: Number,
          slug: String,
          created_at: String,
          updated_at: String,
        }
    },
    data: () => {
        return {
            material: {
              name: "",
              en_name: "",
              description: "",
              material_category_id: 1,
              en_description: "",
              photo_path: null,
              slug: "",
            }
        }
    },
    computed: {
        new(){
            return this._material === undefined;
        }
    },
    methods: {
        getImg(img) {
            const file = img;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.material.photo_path = e.target.result
            }
        },
        save() {
            if(this.new){
                axios.post("/admin/materials/create", this.material).then(res => {
                    Swal.fire("Materiál vytvořen", "", "success");
                    if(res.data.success || res.status == 200){
                        window.location.href = res.data.location
                    }
                }).catch(err => {
                    Swal.fire("Materiál se nepodařilo vytvořit", err.response.data.message, "error");
                })
            }else {
                axios.put('/admin/materials/'+this.material.id, this.material).then(res => {
                    Swal.fire("Materiál upraven", "", "success");
                    if(res.data.success || res.status == 200){
                        window.location.href = res.data.location
                    }
                }).catch(err => {
                    Swal.fire("Materiál se nepodařilo upravit", err.response.data.message, "error");
                })
            }
        }
    },
    mounted() {
        if (this._material) {
            this.material = this._material
        }
    }
}
</script>

<style scoped>

</style>
