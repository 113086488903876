<template>
    <div class="container">
        <h1 class="text-center mb-5">{{ __('Nákupní košík') }}</h1>
        <div class="row">
            <div class="col-md-8 col-12">
                <template v-if="user">
                    <div class="bg-light p-4 mb-3 container">
                        <div class="col-12">
                            <button class="btn btn-detail float-right m-0" @click="edit">{{ __('Upravit') }}</button>
                            <h5 class="mb-3"><b>{{ __('Moje údaje') }}</b></h5>
                            <div v-if="editor">
                                <div class="row">
                                    <div class="form-group col-sm pl-0">
                                        <label>{{ __('Křestní jméno') }}</label>
                                        <input type="text" class="form-control" v-model="form.user.first_name"
                                               @blur="v$.form.user.first_name.$touch"/>
                                        <div v-if="v$.form.user.first_name.$error" class="is-invalid">
                                            {{ __('Křestní jméno musí být vyplněno') }}
                                        </div>
                                    </div>
                                    <div class="form-group col-sm pl-0">
                                        <label>{{ __('Příjmení') }}</label>
                                        <input type="text" class="form-control" v-model="form.user.last_name"
                                               @blur="v$.form.user.last_name.$touch"/>
                                        <div v-if="v$.form.user.last_name.$error" class="is-invalid">
                                            {{ __('Příjmení musí být vyplněno') }}
                                        </div>
                                    </div>


                                </div>

                                <div class="row">
                                    <div class="form-group col pl-0">
                                        <label>E-mail</label>
                                        <input type="text" class="form-control" v-model="form.user.email"
                                               @blur="v$.form.user.email.$touch"/>
                                        <div v-if="v$.form.user.email.$error" class="is-invalid">
                                            {{ __('Email není ve správném tvaru') }}
                                        </div>
                                    </div>
                                    <div class="form-group col pl-0">
                                        <label>{{ __('Telefonní číslo') }}</label>
                                        <input type="text" class="form-control" v-model="form.user.telephone"
                                               @blur="v$.form.user.telephone.$touch"/>
                                        <div v-if="v$.form.user.telephone.$error" class="is-invalid">
                                            {{ __('Telefonní číslo není ve správném tvaru') }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div v-else class="container p-0">
                                <p>{{ user.full_name }}<br>
                                    {{ user.email }}<br>
                                    {{ user.telephone }}<br>
                                </p>
                            </div>

                        </div>
                    </div>


                    <div class="bg-light p-4 mb-3">
                        <div v-if="editor">
                            <div class="row">
                                <div class="col">

                                    <h5><b>{{ __('Fakturační adresa') }}</b></h5>
                                    <div class="form-group">
                                        <div class="form-check p-0">
                                            <input type="radio" class="custom-radio" v-model="form.user.type" :value="0"/>
                                            <label class="form-check-label pl-2">{{__('Fyzická osoba nepodnikající')}}</label><br/>
                                        </div>
                                        <div class="form-check p-0">

                                            <input type="radio" class="custom-radio" v-model="form.user.type" :value="1"/>
                                            <label class="form-check-label pl-2">{{__('Fyzická osoba podnikající')}}</label><br/>
                                        </div>
                                        <div class="form-check p-0">

                                            <input type="radio" class="custom-radio" v-model="form.user.type" :value="2"/>
                                            <label class="form-check-label pl-2">{{__('Firma')}}</label><br/>
                                        </div>
                                    </div>

                                    <div class="form-group" v-if="form.user.type > 0">
                                        <div class="form-group pl-0 pr-sm-0 pr-0" v-if="form.user.type == 2">
                                            <label for="company" style="overflow: visible; white-space: nowrap">
                                                {{ __('Název firmy (nepovinné)') }}
                                            </label>
                                            <input id="company" type="text" class="form-control"
                                                   v-model="form.user.company"/>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col pl-0">
                                                <label for="company">
                                                    {{ __('IČO') }}
                                                </label>
                                                <input id="company" type="text" class="form-control" v-model="form.user.ico" @blur="v$.form.user.ico.$touch">
                                                <div v-if="v$.form.user.ico.$error" class="is-invalid">
                                                    {{ __('IČO není ve správném tvaru') }}
                                                </div>
                                            </div>
                                            <div class="form-group col pr-0">
                                                <label for="company">
                                                    {{ __('DIČ') }}
                                                </label>
                                                    <input id="dic" type="text" class="form-control" v-model="form.user.dic"   aria-label="dic" aria-describedby="basic-addon2" :disabled="dic.checked" />
                                                    <button class="btn btn-primary" v-if="!dic.checked" type="button" @click="checkDic" :disabled="dic.checked" v-html="dic.btn"></button>
                                                    <button class="btn btn-warning" v-else type="button" @click="dicChange">{{ __('Vymazat DIČ') }}</button>
                                                <div v-if="dic.error || v$.form.user.dic.$error" class="is-invalid">
                                                    {{ __('DIČ není validní') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ __('Adresa') }}</label>
                                        <input type="text" class="form-control" v-model="form.user.address"
                                               @blur="v$.form.user.address.$touch">
                                        <div v-if="v$.form.user.address.$error" class="is-invalid">
                                            {{ __('Adresa není ve správném tvaru') }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ __('Město') }}</label>
                                        <input type="text" class="form-control" v-model="form.user.city"
                                               @blur="v$.form.user.city.$touch">
                                        <div v-if="v$.form.user.city.$error" class="is-invalid">
                                            {{ __('Město není ve správném tvaru') }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ __('PSČ') }}</label>
                                        <input type="text" class="form-control" v-model="form.user.zip"
                                               @blur="v$.form.user.zip.$touch">
                                        <div v-if="v$.form.user.zip.$error" class="is-invalid">
                                            {{ __('PSČ není ve správném tvaru') }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ __('Stát') }}</label>
                                        <select class="custom-select" v-model="form.user.country_id">
                                            <option v-for="country in countries" :value="country.id">{{ country.name }}
                                            </option>
                                        </select>
                                    </div>

                                </div>
                                <div class="col"
                                     v-if="form.user.has_same_address">
                                    <h5><b>{{ __('Doručovací adresa') }}</b></h5>
                                    <div class="form-group">
                                        <label>{{ __('Adresa') }}</label>
                                        <input type="text" class="form-control" v-model="form.user.fa_address"
                                               @blur="v$.form.user.fa_address.$touch">
                                        <div v-if="v$.form.user.fa_address.$error" class="is-invalid">
                                            {{ __('Adresa není ve správném tvaru') }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ __('Město') }}</label>
                                        <input type="text" class="form-control" v-model="form.user.fa_city"
                                               @blur="v$.form.user.fa_city.$touch">
                                        <div v-if="v$.form.user.fa_city.$error" class="is-invalid">
                                            {{ __('Město není ve správném tvaru') }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ __('PSČ') }}</label>
                                        <input type="text" class="form-control" v-model="form.user.fa_zip"
                                               @blur="v$.form.user.fa_zip.$touch">
                                        <div v-if="v$.form.user.fa_zip.$error" class="is-invalid">
                                            {{ __('PSČ není ve správném tvaru') }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ __('Stát') }}</label>
                                        <select class="custom-select" v-model="form.user.fa_country_id">
                                            <option v-for="country in countries" :value="country.id">{{ country.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-12">
                                <label
                                    class="form-check-label">{{ __('Fakturační adresa se liší od dodací') }}</label>
                                <input type="checkbox" class="custom-checkbox ml-2"
                                       v-model="form.user.has_same_address"/>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col">
                                <h5><b>{{ __('Fakturační adresa') }}</b></h5>
                                <p>{{ user.full_name }}<br>

                                    <p v-if="user.type > 0">
                                {{ __('Firma') }}: {{ user.company }}<br>
                                {{ __('IČO') }}: {{ user.ico }}<br>
                                {{ __('DIČ') }}: {{ user.dic }}<br/>
                            </p>
                                    {{ user.address }}<br>
                                    {{ user.city }}<br>
                                    {{ user.zip }}<br/>
                                    {{
                                        user.country?.name
                                    }}
                                </p>
                            </div>
                            <div class="col" v-if="!Boolean(user.has_same_address)">
                                <h5><b>{{ __('Doručovací adresa') }}</b></h5>
                                <p>{{ user.full_name }}<br>
                                    {{ user.fa_address }}<br>
                                    {{ user.fa_city }}<br>
                                    {{ user.fa_zip }}<br/>
                                    {{
                                        user.fa_country?.name
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="bg-light p-4 mb-3">
                        <h5><b>{{ __('Moje údaje') }}</b></h5>
                        <div class="row">
                            <div class="form-group col-sm pl-0">
                                <label>{{ __('Křestní jméno') }}</label>
                                <input type="text" class="form-control" v-model="form.user.first_name"
                                       @blur="v$.form.user.first_name.$touch"/>
                                <div v-if="v$.form.user.first_name.$error" class="is-invalid">
                                    {{ __('Křestní jméno musí být vyplněno') }}
                                </div>
                            </div>
                            <div class="form-group col-sm pl-0">
                                <label>{{ __('Příjmení') }}</label>
                                <input type="text" class="form-control" v-model="form.user.last_name"
                                       @blur="v$.form.user.last_name.$touch"/>
                                <div v-if="v$.form.user.last_name.$error" class="is-invalid">
                                    {{ __('Příjmení musí být vyplněno') }}
                                </div>
                            </div>


                        </div>

                        <div class="row">
                            <div class="form-group col pl-0">
                                <label>E-mail</label>
                                <input type="text" class="form-control" v-model="form.user.email"
                                       @blur="v$.form.user.email.$touch"/>
                                <div v-if="v$.form.user.email.$error" class="is-invalid">
                                    {{ __('Email není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group col pl-0">
                                <label>{{ __('Telefonní číslo') }}</label>
                                <input type="text" class="form-control" v-model="form.user.telephone"
                                       @blur="v$.form.user.telephone.$touch"/>
                                <div v-if="v$.form.user.telephone.$error" class="is-invalid">
                                    {{ __('Telefonní číslo není ve správném tvaru') }}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="bg-light p-4 mb-3">
                        <div class="row">
                            <div class="col">

                                <h5><b>{{ __('Fakturační adresa') }}</b></h5>
                                <div class="form-group">
                                    <div class="form-check p-0">
                                        <input type="radio" class="custom-radio" name="type" v-model="form.user.type" :value="0"/>
                                        <label class="form-check-label pl-2">{{__('Fyzická osoba nepodnikající')}}</label><br/>
                                    </div>
                                    <div class="form-check p-0">
                                        <input type="radio" class="custom-radio" name="type" v-model="form.user.type" :value="1"/>
                                        <label class="form-check-label pl-2">{{__('Fyzická osoba podnikající')}}</label><br/>
                                    </div>
                                    <div class="form-check p-0">

                                        <input type="radio" class="custom-radio" name="type"  v-model="form.user.type" :value="2"/>
                                        <label class="form-check-label pl-2">{{__('Firma')}}</label><br/>
                                    </div>
                                </div>
                                <div v-if="form.user.type > 0" class="form-group">
                                    <div class="form-group col p-0" v-if="form.user.type == 2">
                                        <label for="company">
                                            {{ __('Název firmy') }}
                                        </label>
                                        <input id="company" type="text" class="form-control" v-model="form.user.company" @blur="v$.form.user.company.$touch">
                                        <div v-if="v$.form.user.company.$error" class="is-invalid">
                                            {{ __('Název firmy není ve správném tvaru') }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col pl-0">
                                            <label for="ico">
                                                {{ __('IČO') }}
                                            </label>
                                            <input id="ico" type="text" class="form-control" v-model="form.user.ico" @blur="v$.form.user.ico.$touch">
                                            <div v-if="v$.form.user.ico.$error" class="is-invalid">
                                                {{ __('IČO není ve správném tvaru') }}
                                            </div>
                                        </div>
                                        <div class="form-group col pr-0">
                                            <label for="company">
                                                {{ __('DIČ') }}
                                            </label>
                                            <input id="dic" type="text" class="form-control" v-model="form.user.dic"   aria-label="dic" aria-describedby="basic-addon2" :disabled="dic.checked" />
                                            <button class="btn btn-primary" v-if="!dic.checked" type="button" @click="checkDic" :disabled="dic.checked" v-html="dic.btn"></button>
                                            <button class="btn btn-warning" v-else type="button" @click="dicChange">{{ __('Vymazat DIČ') }}</button>
                                            <div v-if="dic.error || v$.form.user.dic.$error" class="is-invalid">
                                                {{ __('DIČ není validní') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>{{ __('Adresa') }}</label>
                                    <input type="text" class="form-control" v-model="form.user.address"
                                           @blur="v$.form.user.address.$touch">
                                    <div v-if="v$.form.user.address.$error" class="is-invalid">
                                        {{ __('Adresa není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>{{ __('Město') }}</label>
                                    <input type="text" class="form-control" v-model="form.user.city"
                                           @blur="v$.form.user.city.$touch">
                                    <div v-if="v$.form.user.city.$error" class="is-invalid">
                                        {{ __('Město není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>{{ __('PSČ') }}</label>
                                    <input type="text" class="form-control" v-model="form.user.zip"
                                           @blur="v$.form.user.zip.$touch">
                                    <div v-if="v$.form.user.zip.$error" class="is-invalid">
                                        {{ __('PSČ není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>{{ __('Stát') }}</label>
                                    <select class="custom-select" v-model="form.user.country_id" @change="changeVat">
                                        <option v-for="country in countries" :value="country.id">{{ country.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label
                                        class="form-check-label">{{ __('Fakturační adresa se liší od dodací') }}</label>
                                    <input type="checkbox" class="custom-checkbox ml-2"
                                           v-model="form.user.has_same_address"/>
                                </div>
                            </div>
                            <div class="col"
                                 v-if="form.user.has_same_address">
                                <h5><b>{{ __('Doručovací adresa') }}</b></h5>
                                <div class="form-group">
                                    <label>{{ __('Adresa') }}</label>
                                    <input type="text" class="form-control" v-model="form.user.fa_address"
                                           @blur="v$.form.user.fa_address.$touch">
                                    <div v-if="v$.form.user.fa_address.$error" class="is-invalid">
                                        {{ __('Adresa není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>{{ __('Město') }}</label>
                                    <input type="text" class="form-control" v-model="form.user.fa_city"
                                           @blur="v$.form.user.fa_city.$touch">
                                    <div v-if="v$.form.user.fa_city.$error" class="is-invalid">
                                        {{ __('Město není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>{{ __('PSČ') }}</label>
                                    <input type="text" class="form-control" v-model="form.user.fa_zip"
                                           @blur="v$.form.user.fa_zip.$touch">
                                    <div v-if="v$.form.user.fa_zip.$error" class="is-invalid">
                                        {{ __('PSČ není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>{{ __('Stát') }}</label>
                                    <select class="custom-select" v-model="form.user.fa_country_id">
                                        <option v-for="country in countries" :value="country.id">{{ country.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="container" v-if="new_customer">
                            <h5><b>{{ __('Nový zákazník') }}</b></h5>
                            <div class="form-group">
                                <label>{{ __('Heslo') }}</label>
                                <input type="password" class="form-control" v-model="form.user.password"
                                       @blur="v$.form.user.password.$touch">
                                <div v-if="v$.form.user.password.$error" class="is-invalid">
                                    {{
                                        __('Heslo musí být aspoň 8 charakterů dlouhé, obsahovat velké a malé písmeno a číslici.')
                                    }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ __('Heslo znovu') }}</label>
                                <input type="password" class="form-control" v-model="form.user.password_again"
                                       @blur="v$.form.user.password_again.$touch">
                                <div v-if="v$.form.user.password_again.$error" class="is-invalid">
                                    {{ __('Hesla se musí shodovat') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-if="cart?.giftcards.length > 0" class="bg-light p-4 mb-3 row">
                    <div class="col-md-8">
                        <h5 class="mb-3"><b>{{ __('Možnosti zaslání dárkových poukazů') }}</b></h5>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" v-model="form.gift.id"
                                   :value="0">
                            <label class="form-check-label">
                                {{ __('Poslat přes email') }}
                            </label>
                        </div>
                        <hr>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" v-model="form.gift.id"
                                   :value="1">
                            <label class="form-check-label">
                                {{ __('Poslat přes dopravce') }}
                            </label>
                        </div>
                        <div class="form-check pl-0" v-if="form.gift.id == 1">
                            <hr/>
                            <div class="form-group">
                                <label>{{ __('Jméno') }}</label>
                                <input type="text" class="form-control" v-model="form.gift.name"
                                       @blur="v$.form.gift.name?.$touch">
                                <div v-if="v$.form.gift.name?.$error" class="is-invalid">
                                    {{ __('Jméno není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ __('Email') }}</label>
                                <input type="text" class="form-control" v-model="form.gift.email"
                                       @blur="v$.form.gift.email?.$touch">
                                <div v-if="v$.form.gift.email?.$error" class="is-invalid">
                                    {{ __('Email není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ __('Telefonní číslo') }}</label>
                                <input type="text" class="form-control" v-model="form.gift.telephone"
                                       @blur="v$.form.gift.telephone?.$touch">
                                <div v-if="v$.form.gift.telephone?.$error" class="is-invalid">
                                    {{ __('Telefonní číslo není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ __('Adresa') }}</label>
                                <input type="text" class="form-control" v-model="form.gift.address"
                                       @blur="v$.form.gift.address?.$touch">
                                <div v-if="v$.form.gift.address?.$error" class="is-invalid">
                                    {{ __('Adresa není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ __('Město') }}</label>
                                <input type="text" class="form-control" v-model="form.gift.city"
                                       @blur="v$.form.gift.city?.$touch">
                                <div v-if="v$.form.gift.city?.$error" class="is-invalid">
                                    {{ __('Město není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ __('PSČ') }}</label>
                                <input type="text" class="form-control" v-model="form.gift.zip"
                                       @blur="v$.form.gift.zip?.$touch">
                                <div v-if="v$.form.gift.zip?.$error" class="is-invalid">
                                    {{ __('PSČ není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ __('Stát') }}</label>
                                <select class="custom-select" v-model="form.gift.country_id">
                                    <option v-for="country in countries" :value="country.id">{{ country.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-light p-4 mb-3 row">
                    <div class="col-sm">
                        <h5 class="mb-3"><b>{{ __('Platba') }}</b></h5>
                        <div class="form-check p-0">
                            <label class="form-check-label bi-type-underline" @click="gift = !gift">
                                <u>{{ __('Máte dárkový poukaz? Klikněte ZDE') }}</u>
                            </label>

                        </div>
                        <div class="input-group" v-if="gift">
                            <input type="text" v-model="giftcard.code" @keyup.enter="checkGift" class="form-control"
                                   :disabled="giftcard.price > 0"/>
                            <div class="input-group-append">
                                <button class="btn btn-secondary m-0 text-transform-none" type="button"
                                        @click="checkGift" v-if="giftcard.price < 1">{{ __('Vložit') }}
                                </button>
                                <button class="btn btn-secondary m-0 text-transform-none" type="button"
                                        @click="deleteGift" v-else>
                                    {{ __('Odebrat') }}
                                </button>
                            </div>
                        </div>
                        <hr>
                        <div v-for="payment in payments" v-if="need_to_pay">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="form.payment" :value="payment">
                                <label class="form-check-label">
                                    {{ payment.name }}
                                </label>
                            </div>
                            <hr>
                        </div>
                        <div v-if="v$.form.payment.$error" class="is-invalid">
                            {{ __('Musíte se vybrat možnost platby.') }}
                        </div>
                    </div>
                    <div class="col-sm">
                        <h5 class="mb-3"><b>{{ __('Doprava') }}</b></h5>
                        <div v-for="shipping in shippings" v-if="!only_gift">
                            <div v-if="shipping.id == 3" class="form-check">
                                <input class="form-check-input" type="radio" v-model="form.shipping" :value="shipping"
                                       @click="balikovna">
                                <label class="form-check-label">
                                    {{ shipping.name }}
                                </label>
                                <div v-if="form.shipping && form.shipping.id == 3">
                                    {{form.balikovna?.name}} - {{form.balikovna?.address}}
                                </div>
                            </div>
                            <div v-else-if="shipping.id == 1" class="form-check">
                                <input class="form-check-input" type="radio" v-model="form.shipping" :value="shipping"
                                       @click="packeta">
                                <label class="form-check-label">
                                    {{ shipping.name }}
                                </label>
                                <div v-if="form.shipping && form.shipping.id == 1">
                                    {{form.zasilkovna.place}} - {{form.zasilkovna.street}}, {{form.zasilkovna.city}}
                                </div>
                            </div>

                            <div class="form-check" v-else>
                                <input class="form-check-input" type="radio" v-model="form.shipping" :value="shipping">
                                <label class="form-check-label">
                                    {{ shipping.name }}
                                </label>
                            </div>
                            <hr>
                        </div>
                        <div v-if="v$.form.shipping.$error" class="is-invalid">
                            {{ __('Musíte se vybrat možnost dopravy.') }}
                        </div>
                    </div>

                </div>
                <div class="bg-light p-4 mb-3 row">
                    <h5 class="mb-3"><b>{{ __('Poznámka') }}</b></h5><br/>
                    <textarea v-model="form.note" class="form-control"></textarea>
                </div>
            </div>

            <div class="col-md-4 col-12">
                <div class="bg-light p-4">
                    <table width="100%">
                        <tr>
                            <td class="py-2">{{ __('Hodnota objednávky') }}:</td>
                            <td class="py-2 text-right">{{ calculatePrice(cart.price, currency) }}
                                {{ currency.symbol }}
                            </td>
                        </tr>
                        <tr v-if="form.shipping">
                            <td class="pt-2">{{ __('Doprava') }}:</td>
                            <td class="pt-2 text-right" v-if="cart?.price_discounted_dph < 3000">{{ calculatePrice(shipping_price / 1.21, currency)}}
                                {{ currency.symbol }}
                            </td>
                            <td class="pt-2 text-right" v-else>
                                {{__('ZDARMA')}}
                            </td>
                        </tr>
                        <tr v-if="form.gift.id == 1">
                            <td class="pt-2">{{__('Doprava poukazu')}}: </td>
                            <td class="pt-2 text-right">{{calculatePrice(form.gift.price/1.21, currency)}} {{currency.symbol}}</td>
                        </tr>
                        <tr v-if="form.payment">
                            <td class="pt-2 pb-3">{{ __('Platba') }}:</td>
                            <td class="pt-2 pb-3 text-right">{{ calculatePrice(payment_price / 1.21, currency) }}
                                {{ currency.symbol }}
                            </td>
                        </tr>
                        <tr v-if="cart.coupon?.discount >0 ">
                            <td class="pt-2 pb-3">{{ __('Sleva') }}:</td>
                            <td class="pt-2 pb-3 text-right">{{ cart.coupon.discount * 100 }} %</td>
                        </tr>
                        <tr v-if="giftcard.price > 0">
                            <td class="pt-2 pb-3">{{ __('Sleva') }}:</td>
                            <td class="pt-2 pb-3 text-right"> -{{ calculatePrice(giftcard.price / 1.21, currency) }}
                                {{ currency.symbol }}
                            </td>
                        </tr>
                        <tr v-if="need_to_pay && giftcard.price > 0 ">
                            <td class="pt-2 pb-3">{{ __('Doplatek') }}:</td>
                            <td class="pt-2 pb-3 text-right">{{ calculatePrice(to_pay, currency) }}
                                {{ currency.symbol }}
                            </td>
                        </tr>
                        <tr class="border-top ">
                            <td class="pt-3"><h5><b>{{ __('Celkem') }}:</b></h5></td>
                            <td class="pt-3 text-right" v-if="giftcard.price > 0"><h5>
                                <b>{{ calculatePrice(total_price_with_gift, currency) }}
                                    {{ currency.symbol }}</b></h5></td>
                            <td class="pt-3 text-right" v-else><h5><b>{{ calculatePrice(total_price, currency) }}
                                {{ currency.symbol }}</b></h5></td>
                        </tr>
                        <tr>
                            <td class="pt-3"><b><strong>{{__('Souhlasím s')}} <a href="/obchodni-podminky" target="_blank" style="color:black"> {{" "+__('VOP')}}</a>/<a target="_blank" href="/gdpr" style="color: black">GDPR</a> </strong></b></td>
                            <td class="pt-3 text-right"><input type="checkbox" class="custom-checkbox" v-model="vop"/></td>
                        </tr>
                    </table>
                    <div class="add-to-cart">
                        <button @click="checkAndSend" :disabled="ordering || !vop" class="btn btn-primary">{{ __('Odeslat objednávku') }}</button>
                    </div>
                    <p class="small text-dark">
                        {{
                            __('Vaše osobní údaje zpracujeme v souladu s Pravidly ochrany osobních údajů společnosti SKR.')
                        }}</p>
                    <p class="small text-dark">
                        {{ __('Pokračováním souhlasíte se Všeobecnými obchodními podmínkami.') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import useVuelidate from '@vuelidate/core'
import {required, email, helpers, sameAs, requiredIf, maxLength, minLength} from '@vuelidate/validators'

import {useToast} from "vue-toastification";

export default {
    name: "step_three",
    props: {
        payments: Array,
        shippings: Array,
        countries: Array
    },
    setup: () => {
        const toast = useToast();
        return {toast, v$: useVuelidate()}
    },
    validations() {
        const dicChecked = ()=>{
            return this.dic.checked
        }
        const params = {
            email: {
                required, email, maxLength: maxLength(70)
            },
            name: {
                required, maxLength: maxLength(70)
            },
            first_name: {
                required, maxLength: maxLength(70)
            },
            last_name: {
                required, maxLength: maxLength(70)
            },
            zip: {
                zip(value) {
                    return value.match(/\b\d{5}\b/g)
                }, required, maxLength: maxLength(10)
            },
            telephone: {
                telephone(value) {
                    let number = value.match(/([+]?\d{1,3}[. \s]?)?(\d{9}?)/g)[0];
                    return number.length >= 9 && number.length <= 14;
                }, required, maxLength: maxLength(12)
            },
            city: {
                city(value) {
                    return value.length >= 2 && typeof value === "string"
                }, required, maxLength: maxLength(50)
            },
            address: {
                address(value) {
                    let regex = new RegExp("[1-9]");
                    return regex.test(value);
                }, required, maxLength: maxLength(50), minLength: minLength(2)
            },
            password: {
                password(value) {
                    return value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$/)
                }, required, maxLength: maxLength(32)
            },
            password_again: {
                sameAs: sameAs(this.form.user.password), required
            },
            dic: {
                requiredIfDic: requiredIf(this.form.user.type == 2),
                maxLength: maxLength(15),
                checked: dicChecked
            },
            ico: {
                requiredIfIco: requiredIf(this.form.user.type > 0)
            },
            company: {
                requiredIfCompany: requiredIf(this.form.user.type == 2)
            }
        }
        let form = {}
        let gift = {};
        if (!this.user) {
            ["first_name", "last_name", "email",  "city", "address", "company"].forEach(i => {
                form[i] = params[i]
            })
            if(this.form.user.country_id == 1){
                form.zip = params.zip
                form.telephone = params.telephone
            }
            else {
                form.zip = form.telephone = {}
            }
            if (this.form.user.has_same_address) {
                ["city", "address"].forEach(i => {
                    form["fa_" + i] = params[i]
                })
                if(this.form.user.fa_country_id == 1)form.fa_zip = params.zip
                else form.fa_zip = {}
            }
            if(this.form.user.type > 0){
                form.ico = params.ico
                form.dic = params.dic
            }
            if (this.new_customer) {
                ["password", "password_again"].forEach(i => {
                    form[i] = params[i]
                })
            }
        }
        else if (this.editor) {
            ["first_name", "last_name", "email",  "city", "address", "telephone",  "company"].forEach(i => {
                form[i] = params[i]
            })
            if(this.form.user.country_id == 1){
                form.zip = params.zip
                form.telephone = params.telephone
            }
            else {
                form.zip = form.telephone = {}
            }
            if (this.form.user.has_same_address) {
                ["city", "address"].forEach(i => {
                    form["fa_" + i] = params[i]
                })
                if(this.form.user.fa_country_id == 1)form.fa_zip = params.zip
                else form.fa_zip = {}
            }
            if(this.form.user.type > 0){
                form.ico = params.ico
                form.dic = params.dic
            }
        }
        if(this.cart.giftcards.length > 0 && this.form.gift.id == 1){
            ["name",  "city", "email","address", "telephone"].forEach(i => {
                gift[i] = params[i]
            })
            if(this.form.gift.country_id == 1){
                gift.zip = params.zip
                gift.telephone = params.telephone
            }else {
                gift.zip = {}
                gift.telephone = {}
            }

        }
        return {
            form: {
                user: form,
                payment: {requiredIf: requiredIf(this.need_to_pay)},
                shipping: {requiredIf: requiredIf(!this.only_gift && this.cart?.price_discounted_dph <= 3000), haveTo: (id)=>{
                        console.log(id)
                    if(id == 1)return this.form.zasilkovna.id > 0;
                    if(id == 3)return this.form.balikovna.id?.length > 0;
                    return true
                    }},
                gift: gift
            }
        }
    },
    data: () => {
        return {
            vop:false,
            gift: false,
            giftcard: {price: 0, code: null},
            ordering: false,
            form: {
                payment: null,
                shipping: null,
                only_gift: false,
                note: null,
                balikovna: {},
                zasilkovna: {},
                gift: {
                    id: 0,
                    price: 30,
                    name: null,
                    email: null,
                    address: null,
                    city: null,
                    zip: null,
                    country_id: 1,
                    telephone: null
                },
                user: {
                    first_name: null,
                    last_name: null,
                    company: null,
                    email: null,
                    address: null,
                    city: null,
                    zip: null,
                    type: 0,
                    country_id: 1,
                    telephone: null,
                    fa_address: null,
                    fa_city: null,
                    fa_country_id: 1,
                    fa_telephone: null,
                    fa_zip: null,
                    has_same_address: false,
                    password: null,
                    password_again: null,
                    ico: null,
                    dic: null
                }
            },
            editor: false,
            dic: {
                checked: false,
                error: null,
                btn: null,

            }
        }
    },
    methods: {
        balikovna(){
            Balikovna.Widget.pick(this.balikovnaSelected)
        },
        balikovnaSelected(data){
            console.log(data)
            if(data.hasOwnProperty('id')){
                this.form.balikovna.address = data.address
                this.form.balikovna.id = data.id
                this.form.balikovna.name = data.name

            }else {
                if (this.shippings.length > 0) this.form.shipping = null
            }
        },
        dicChange(){
            if(this.dic.checked){
                this.dic.error = null;
                this.form.user.dic = null;
                this.dic.checked = false;
            }
        },
        async checkDic(){
            let iso = this.form.user.dic.substring(0,2);
            if(iso != this.countries.find(c => c.id == this.form.user.country_id).iso_code){
                this.dic.error = this.__('Dič není validní');
                return;
            }
            this.dic.btn = '<div class="spinner-border" role="status">\n' +
                '  <span class="sr-only">Loading...</span>\n' +
                '</div>'
            const res = await axios.get('/eshop/checkout/company_check/' + this.form.user.dic)
            if(res.data.valid){
                this.dic.checked = true;
                this.dic.error = null;
            }
            else if(res.data?.note){
                this.dic.error = res.data.note;
            }else {
                this.dic.error = this.__('Dič není validní');
            }
            this.dic.btn = this.__('Ověřit')
        },
        changeVat() {
            const country = this.countries.filter(c => c.id == this.form.user.country_id)[0];
            if (window._country.id != country.id) {
                this.form.user.dic = null;
                this.dic.checked = false;
                this.toast.info(this.__('DPH se změnilo!'))
                window._country = country
            }
        },
        checkGift() {
            if (this.giftcard.price == 0 || this.cart.giftcard.price === undefined) {
                axios.get(`/eshop/giftcards/${this.giftcard.code}/check`).then(res => {
                    if (res.data.success && res.data.giftcard != null) this.giftcard.price = res.data.giftcard.giftcard.price
                })
            }
        },
        deleteGift() {
            if (this.giftcard.price > 0) {
                axios.post(`/eshop/giftcards/uncheck`).then(res => {
                    if (res.data.success) {
                        this.giftcard = {
                            code: null, price: 0
                        }
                    }
                })
            }
        },
        edit() {
            this.editor = !this.editor;
            this.form.user.has_same_address = !this.user.has_same_address;
            if(this.user){
                this.dic.checked = true;
            }else {
                this.dic.btn = this.__('Ověřit')
            }
        },
        packeta() {
            Packeta.Widget.pick("ddc9f81afdd98678", this.packetSelected)
        },
        packetSelected(data) {
            console.log(data)
            if (data.hasOwnProperty('place')) {
                this.form.zasilkovna.place = data.place
                this.form.zasilkovna.zip = data.zip;
                this.form.zasilkovna.street = data.street;
                this.form.zasilkovna.city = data.city;
                this.form.zasilkovna.id = data.id;
                this.form.shipping = this.shippings.filter(s => s.id === 1)[0]
            } else {
                if (this.shippings.length > 0) {
                    this.form.zasilkovna = {}
                    this.form.shipping = null
                }
            }
        },
        async checkAndSend() {
            if(this.user){
                this.form.user = this.user
            }
            if (!await this.v$.$validate()) {
                Swal.fire({
                    title: this.__('Chyba'),
                    text: this.__('Některé pole nejsou vyplněny správně'),
                    icon: 'error',
                    confirmButtonColor: 'red',
                    confirmButtonText: 'OK'
                })
                return
            } else {
                const vm = this;
                Swal.fire({
                    title: this.__("Chcete vytvořit objednávku?"),
                    icon: 'question',
                    confirmButtonText: this.__('Ano'),
                    confirmButtonColor: 'green',
                    cancelButtonText: this.__('Ne'),
                    cancelButtonColor: 'red',
                    showCancelButton: () => !Swal.isLoading(),
                    allowEnterKey: true,
                    allowEscapeKey: true,
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading(),
                    preConfirm(confirmed) {
                        if(confirmed){
                            vm.ordering = true;
                            return axios.post('/eshop/checkout/order', {
                                _token: document.getElementsByName('csrf-token')[0].content,
                                form: {
                                    ...vm.form.user,
                                    shipping_id: vm.form.shipping?.id ?? 0,
                                    payment_id: vm.form.payment?.id ?? 0,
                                    zasilkovna_id: vm.form.zasilkovna.id,
                                    gift: vm.form.gift,
                                    balikovna: vm.form.balikovna,
                                    note: vm.form.note
                                }
                            }).then(res => {
                                return res.data;
                            }).catch(err => {
                                vm.ordering = false;
                                Swal.fire({
                                            title: vm.__('Objednávka nebyla vytvořena.'),
                                            text: err.response.data.message,
                                            icon: 'error',
                                        })
                            })
                        }
                    }
                }).then(result => {
                    if (result.isConfirmed) {
                        localStorage.removeItem('form')
                                Swal.fire({
                                    title: vm.__('Objednávka vytvořena!'),
                                    icon: 'success',
                                    confirmButtonColor: 'green',
                                    confirmButtonText: 'OK',
                                })
                                    .then(result2 => {

                                        if (result2.isConfirmed) {
                                            location.href = result.value.location
                                        }
                                    })
                            }
                    })
            }
        }
    },
    mounted() {
        if(this.user){
            this.dic.checked = true;
        }else {
            this.dic.btn = this.__('Ověřit')
        }
        window.addEventListener('beforeunload', e => {
            if (!this.editor && !this.ordering) {
                delete this.form.user.password;
                delete this.form.user.password_again;
                this.user ? delete this.form.user : null;
                this.form.only_gift = this.only_gift
                localStorage['form'] = JSON.stringify(this.form)
            }

        });
        let orig_form = this.form
        try {
            let saved_form = JSON.parse(localStorage.form);
            this.form.zasilkovna = saved_form?.zasilkovna
            this.form.balikovna = saved_form?.balikovna
            this.user == null ? this.form.user = saved_form?.user : setTimeout(this.form.user = this.user, 3000);
            this.form.shipping = saved_form.shipping;
            this.form.payment = saved_form.payment;
            this.form.gift = saved_form.gift
            this.form.note = saved_form.note
        } catch (e) {
            this.form = orig_form;

        }


    },
    computed: {
        only_gift() {
            return this.cart.giftcards.length > 0 && this.cart.items.length < 1
        },
        user() {
            return window.auth_user;
        },
        cart() {
            return this.$store.state.cart;
        },
        to_pay() {
            return this.total_price - this.giftcard.price / 1.21
        },
        need_to_pay() {
            return this.total_price > this.giftcard.price / 1.21
        },
        openedGiftInput() {
            return this.form.payment?.id == 0;
        },
        total_price() {
            let total_price = this.cart.price;
            this.form.shipping != null && this.cart?.price_discounted_dph < 3000? total_price += this.shipping_price / 1.21 : 0
            this.form.payment != null ? total_price += this.payment_price / 1.21 : 0
            this.form.gift.id == 1? total_price +=this.form.gift.price/1.21:0
            if (this.cart.coupon?.discount > 0) return total_price * (1 - this.cart.coupon.discount)
            else return total_price
        },
        total_price_with_gift() {
            let total_price = this.cart.price;
            this.form.shipping != null && this.cart?.price_discounted_dph < 3000? total_price += this.shipping_price / 1.21 : 0
            this.form.payment != null ? total_price += this.payment_price / 1.21 : 0
            total_price += this.form.gift.price /1.21
            if (this.cart.coupon?.discount > 0) total_price *= (1 - this.cart.coupon.discount)
            this.giftcard.price > 0 ? total_price -= this.giftcard.price / 1.21 : 0
            return Math.max(0, total_price)

        },
        new_customer() {
            return this.$route.query?.action == 'new_customer'
        },
        currency() {
            return window._currency;
        },
        payment_price() {
            return this.form.payment?.price??0
        },
        shipping_price() {
            return this.form.shipping?.price??0
        }
    },
    watch: {
        'form.payment'(a, b) {
            this.cart.payment = a;
        },
        'form.shipping'(a, b) {
            this.cart.shipping = a;
            if (a.id != 1) this.form.zasilkovna = {}
            if (a.id != 3)this.form.balikovna = {}
        },
        'form.user.has_same_address'(a) {
            if (!this.user) if (!a) this.form.user.fa_address = this.form.user.fa_city = this.form.user.fa_telephone = this.form.user.fa_zip = null;
        },
        'cart'(a) {
            if (this.giftcard.code == null) {
                this.giftcard = a.giftcard
                if (this.giftcard.code != null) this.gift = true
            }
        },
        'user'(a) {
            this.form.user = a
        },
        'editor'(a) {
            if (a) this.form.user = this.user;
        },
        'form.gift.id'(a){
            if(a == 1)this.form.gift.price = 30;
            else this.form.gift.price = 0;
        }
    }
}
</script>

<style scoped>

</style>
