<template>
    <div class="wrapper-page materials-page">
        <div class="row">
            <div class="col-sm-4 ">
                <div class="sidenav">
                    <div v-for="category in categories">
                        <button class="dropdown-btn">{{ $root.locale == 'en'?category.en_name:category.name }}
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-container">
                            <a v-for="m in category.materials" :href="'/materialy/'+m.slug">{{$root.locale == 'en'?m.en_name:m.name}}</a>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-sm-3 ">
                <img :src="material.photo_path" :alt="material.slug">
            </div>
            <div class="col-sm-5">
                <h1>{{$root.locale == 'en'?material.en_name:material.name}}</h1>
                <hr/>
                <div class="text-left mt-sm-0 mt-3"  v-if="$root.locale =='en'" v-html="material.en_description">
                </div>
                <div class="text-left mt-sm-0 mt-3"  v-else v-html="material.description">
                </div>
            </div>



        </div>

    </div>
</template>

<script>
export default {
    name: "Materialy",
    props: {
        categories: [],
        material: Object
    },
    mounted() {
        window.addEventListener('load', ev => {
            var dropdown = document.getElementsByClassName("dropdown-btn");
            var i;

            for (i = 0; i < dropdown.length; i++) {
                dropdown[i].addEventListener("click", function () {
                    this.classList.toggle("active");
                    var dropdownContent = this.nextElementSibling;
                    if (dropdownContent.style.display === "block") {
                        dropdownContent.style.display = "none";
                    } else {
                        dropdownContent.style.display = "block";
                    }
                });
            }
        })
        window.addEventListener('load', ev => {
            document.getElementsByClassName('dropdown-toggle')[0].onmouseover = function () {
                document.getElementById('1').style.display = 'block';
                document.getElementsByClassName('dropdown-toggle')[0].onmouseleave = function () {
                    function hide() {
                        document.getElementById('1').style.display = 'none';
                    }

                    var timeout = setTimeout(hide, 20);
                    document.getElementById('1').onmouseover = function () {
                        window.clearTimeout(timeout);
                        document.getElementById('1').style.display = 'block';

                        document.getElementsByClassName('dropdown-menu')[0].onmouseleave = function () {
                            function hide() {
                                document.getElementById('1').style.display = 'none';
                            }

                            var timeout = setTimeout(hide, 20);
                            document.getElementById('1').onmouseover = function () {
                                window.clearTimeout(timeout);
                                document.getElementById('1').style.display = 'block';

                            }
                        }


                    }
                }
            };
            document.getElementsByClassName('dropdown-toggle')[1].onmouseover = function () {
                document.getElementById('2').style.display = 'block';
                document.getElementsByClassName('dropdown-toggle')[1].onmouseleave = function () {
                    function hide() {
                        document.getElementById('2').style.display = 'none';
                    }

                    var timeout = setTimeout(hide, 20);
                    document.getElementById('2').onmouseover = function () {
                        window.clearTimeout(timeout);
                        document.getElementById('2').style.display = 'block';

                        document.getElementsByClassName('dropdown-menu')[1].onmouseleave = function () {
                            function hide() {
                                document.getElementById('2').style.display = 'none';
                            }

                            var timeout = setTimeout(hide, 20);
                            document.getElementById('2').onmouseover = function () {
                                window.clearTimeout(timeout);
                                document.getElementById('2').style.display = 'block';

                            }
                        }
                    }


                }
            }
        })
    }

}
</script>

<style scoped>

</style>
