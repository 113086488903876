<template>
    <div class="container">
        <h2 class="text-center">Objednávka číslo <b>{{ order.id }}</b></h2>
        <div>
            <h4 class="pl-3">Stav: <b>{{ order.status }}</b></h4>
            <div class="row">
                <div class="col-md-8" >
                    <div class="card shadow mb-2" v-if="items.length > 0" v-for="item in items">
                        <div class="card-header">
                            <strong class="float-left">{{ item.item.name }}</strong>
                            <strong class="float-right">Počet: {{ item.count }}</strong>
                        </div>
                        <div class="card-body row">
                            <img :src="item.item.photo.thumb_path"
                                 class="card-img shadow w-25 float-left"/>
                            <div class="ml-auto my-auto">
                                <h4>
                                    <a :href="'/eshop/product/'+item.item.slug"><strong>{{
                                            item.item.name
                                        }}</strong></a>
                                </h4>
                                <label>Cena: {{
                                        new Intl.NumberFormat('cs-CS', {
                                            style: 'currency',
                                            currency: order.user_id ? order.user.country_id == 1?'CZK':"EUR":order.country_id == 1?'CZK':"EUR"
                                        }).format(item.price_dph*item.count)
                                    }}</label><br/>
                                <label class="text-info">Cena bez DPH: {{
                                        new Intl.NumberFormat('cs-CS', {
                                            style: 'currency',
                                            currency: order.user_id ? order.user.country_id == 1?'CZK':"EUR":order.country_id == 1?'CZK':"EUR"
                                        }).format(item.price*item.count)
                                    }}</label><br/>
                                <strong>Velikost: {{ item.size }}</strong><br/>
                            </div>

                        </div>
                    </div>
                    <div class="card shadow mb-2" v-if="giftcards.length > 0" v-for="giftcard in giftcards">
                        <div class="card-header">
                            <strong class="float-left">{{ giftcard.giftcard.name }}</strong>
                        </div>
                        <div class="card-body row">
                            <div class="float-right">
                                <h4>
                                    <a :href="'/eshop/giftcards/'+giftcard.giftcard.slug"><strong>{{
                                            giftcard.giftcard.name
                                        }}</strong></a>
                                </h4>
                                <label>Cena: {{ giftcard.giftcard.price }} Kč</label><br/>
                                <div class="form-group" v-if="giftcard.address != null">
                                    <strong>Poslat na: </strong> <br/>
                                    <label>Jméno: </label> {{giftcard.name}}<br/>
                                    <label>Adresa: </label> {{giftcard.address}}<br/>
                                    <label>Město: </label> {{giftcard.city}}<br/>
                                    <label>PSČ: </label> {{giftcard.zip}}<br/>
                                    <label>Země: </label> {{giftcard.country}}
                                    <a class="btn btn-primary" :href="'/admin/orders/giftcard/'+giftcard.id">Stáhnout dárkový poukaz</a>
                                </div>
                                <div v-else>
                                    <strong>{{giftcard.sent?"Poukaz odeslán":"Poslat emailem"}}</strong><br/>
                                    <div class="btn-group">
                                        <button class="btn btn-danger" @click="sendGift(giftcard)" :disabled="giftcard.sent">Odeslat dárkový poukaz</button>
                                        <a class="btn btn-primary" :href="'/admin/orders/giftcard/'+giftcard.id">Stáhnout dárkový poukaz</a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="card shadow mb-2" v-if="order.note != null">
                        <div class="card-header">
                            Poznámka
                        </div>
                        <div class="card-body float-right" >
                            {{order.note}}
                        </div>
                    </div>

                    <div class="card shadow">
                        <div class="card-header">
                            Stav - {{ order.status }}

                        </div>
                        <div class="card-body float-right">
                            <div v-if="order.voucher">Sleva: {{ order.voucher.discount * 100 }} %</div>
                            <div v-else-if="order.discounted > 0">Sleva: {{
                                    new Intl.NumberFormat('cs-CS', {
                                        style: 'currency',
                                        currency: order.user_id ? order.user.country_id == 1?'CZK':"EUR":order.country_id == 1?'CZK':"EUR"
                                    }).format(order.discounted)
                                }}
                            </div>
                            <div v-else-if="giftcard">Sleva dárkovým poukazem: {{
                                    new Intl.NumberFormat('cs-CS', {
                                        style: 'currency',
                                        currency: order.user_id ? order.user.country_id == 1?'CZK':"EUR":order.country_id == 1?'CZK':"EUR"
                                    }).format(giftcard.giftcard.price) }}</div>
                            Celková cena: <strong>{{
                                new Intl.NumberFormat('cs-CS', {
                                    style: 'currency',
                                    currency: order.user_id ? order.user.country_id == 1?'CZK':"EUR":order.country_id == 1?'CZK':"EUR"
                                }).format(order.price_dph)
                            }}</strong><br/>
                            <p class="text-info">Cena bez DPH: {{
                                    new Intl.NumberFormat('cs-CS', {
                                        style: 'currency',
                                        currency: order.user_id ? order.user.country_id == 1?'CZK':"EUR":order.country_id == 1?'CZK':"EUR"
                                    }).format(order.price)
                                }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <a :href="'https://erp.bohemia-cloud.cz/orders/'+order.erp_order_id" target="_blank" class="btn btn-primary m-0 col">Zobrazit v ERP</a>
                        <a :href="'/admin/orders/'+this.order.id+'/csv'" download class="btn btn-secondary m-0 col">Stáhnout CSV</a>
                        <a :href="'/admin/orders/'+this.order.id+'/zip'" download class="btn btn-warning m-0 col">Stáhnout ZIP</a>
                    </div><br/>
                    <div class="row">
                        <a v-if="order.paid" :href="'/eshop/orders/'+this.order.id+'/invoice'" download class="btn btn-secondary m-0 col">Stáhnout fakturu</a>
                        <a :href="'/eshop/orders/'+this.order.id+'/proform'" download class="btn btn-warning m-0 col">Stáhnout výzvu k platbě</a>
                    </div>
                    <div class="d-none d-md-inline">
                        <div class="btn-group" v-if="!order.canceled && !order.done">
                            <button v-if=" !order.paid" class="btn btn-primary" @click="pay">
                                Zaplatit
                            </button>
                            <button v-if="!order.delivering" class="btn btn-warning" @click="deliver">Expedovat</button>
                            <button v-if="!order.done && !order.canceled" class="btn btn-success" @click="finish">Dokončit
                            </button>
                            <button v-if="!order.canceled" class="btn btn-danger" @click="cancel">Zrušit</button>
                        </div>
                        <div class="btn-group" v-else>
                            <button class="btn btn-warning" @click="restore">Obnovit</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mb-2">
                        <div class="card-header d-inline-block"><strong>Doručení a platba</strong>
<!--                            <button v-if="!form.editing" class="btn btn-info float-right" @click="edit"><i-->
<!--                                class="fas fa-cog mr-1"></i>Upravit-->
<!--                            </button>-->
<!--                            <button v-else class="btn btn-danger float-right" @click="close"><i class="fas fa-times-circle mr-1"></i> Zavřít-->
<!--                            </button>-->
                        </div>
                        <div class="card-body">
                            <label><b>Doručení:</b></label><br/>
                            <div id="shipping" v-if="!form.editing">
                                <strong>{{ shipping.name }}</strong>
                                <p v-if="zasilkovna">
                                <hr/>
                                <strong>{{ zasilkovna.name }}</strong><br/>
                                <strong>{{ zasilkovna.address.name }}</strong>
                                </p>
                                <p class="text-info" v-if="order.free_shipping">Doprava zdarma</p>
                                <a v-if="shipping.id == 1" :href="'/admin/orders/'+order.id+'/zasilkovna'"
                                   class="btn btn-link" target="_blank">Stáhnout štítek</a>
                                <a v-else-if="shipping.id == 2" :href="'/admin/orders/'+order.id+'/gls'"
                                   class="btn btn-link" target="_blank">Stáhnout štítek</a>
                                <hr/>
                            </div>
                            <div v-if="order.shipping_id == 3">
                                <p>
                                <strong>{{ order.balikovna_name }}</strong><br/>
                                <strong>{{ order.balikovna_address }}</strong>
                                </p>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Číslo zásilky" v-model="post_id" aria-describedby="button-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="addPost">Potvrdit</button>
                                    </div>
                                </div>
                                <button v-if="post.states != undefined" type="button" class="btn btn-primary" data-toggle="modal" data-target="#states">
                                    Stav zásilky
                                </button>

                                <!-- Modal -->
                                <div v-if="post.states != undefined" class="modal fade" id="states" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Zásilka {{post.id}}</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <p v-for="s in post.states.state">
                                                    {{s.date}} - {{s.text}}
                                                </p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Zavřít</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
<!--                            <div v-else>-->
<!--                                <select v-model="form.shipping" class="custom-select" @change="checkIfZasilkovna">-->
<!--                                    <option v-for="s in shippings" :value="s">{{ s.name }}</option>-->
<!--                                </select>-->
<!--                            </div>-->
                            <label><b>Platba:</b></label><br/>
                            <div v-if="!form.editing">
                                <div v-if="payment?.gw_url !== undefined"><a :href="payment.gw_url" target="_blank">
                                    GoPay  - STAV:
                                    {{ states[payment.state] }}
                                </a>
                                </div>
                                <div v-else>{{ payment?.name }}</div>
                            </div>
<!--                            <div v-else>-->
<!--                                <select v-model="form.payment" class="custom-select">-->
<!--                                    <option v-for="s in payments" :value="s" selected>{{ s.name }}</option>-->
<!--                                </select>-->
<!--                            </div>-->
<!--                            <button v-if="form.editing" class="btn btn-success" @click="save">Uložit</button>-->
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-header">
                            <strong>Kontaktní údaje</strong>
                        </div>
                        <div class="card-body">
                            <div v-if="order.user_id !== null">
                                <strong>{{ order.user.full_name }}</strong><br/>
                                <label>E-mail: </label> {{ order.user.email }}<br/>
                                <label>Tel.: </label> {{ order.user.telephone }}
                            </div>
                            <div v-else>
                                <strong>{{ order.first_name + " " + order.last_name }}</strong><br/>
                                <label>E-mail: </label> {{ order.email }}<br/>
                                <label>Tel.: </label> {{ order.telephone }}
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-header">
                            <strong>Fakutrační adresa</strong>
                        </div>
                        <div class="card-body">
                            <strong>{{
                                    order.user_id ? order.user.full_name : order.first_name + " " + order.last_name
                                }}</strong><br/>
                            <strong>{{ order.user_id ? order.user.address : order.address }}</strong><br/>
                            <strong>{{ order.user_id ? order.user.zip : order.zip }}
                                {{ order.user_id ? order.user.city : order.city }}</strong><br/>
                            <strong>{{
                                    order.user_id ? countries.filter(i => i.id == order.user.country_id)[0]?.name : countries.filter(i => i.id == order.country_id)[0]?.name
                                }}</strong><br/>
                        </div>
                    </div>
                    <div class="card mb-2" v-if="!order.has_same_address || !order.user?.has_same_address">
                        <div class="card-header">
                            <strong>Doručovací adresa</strong>
                        </div>
                        <div class="card-body">
                            <strong>{{
                                    order.user_id ? order.user.full_name : order.first_name + " " + order.last_name
                                }}</strong><br/>
                            <strong>{{ order.user_id ? order.user.fa_address : order.fa_address }}</strong><br/>
                            <strong>{{ order.user_id ? order.user.fa_zip : order.fa_zip }}
                                {{ order.user_id ? order.user.fa_city : order.fa_city }}</strong><br/>
                            <strong>{{
                                    order.user_id ? countries.filter(i => i.id == order.user.fa_country_id)[0]?.name : countries.filter(i => i.id == order.fa_country_id)[0]?.name
                                }}</strong><br/>
                        </div>
                    </div>
                    <div class=" d-inline d-md-none">
                        <div class="btn-group" v-if="!order.canceled && !order.done">
                            <button v-if=" !order.paid && order.erp_order_id != null && order.erp_invoice_id != null" class="btn btn-primary" @click="pay">
                                Zaplatit
                            </button>
                            <button v-if="!order.delivering" class="btn btn-warning" @click="deliver">Expedovat</button>
                            <button v-if="!order.done && !order.canceled" class="btn btn-success" @click="finish">Dokončit
                            </button>
                            <button v-if="!order.canceled" class="btn btn-danger" @click="cancel">Zrušit</button>
                        </div>
                        <div class="btn-group" v-else>
                            <button class="btn btn-warning" @click="restore">Obnovit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "OrderShow",
    props: {
        order: Object,
        items: Array,
        giftcards: Array,
        shipping: Object,
        post: Object,
        payment: Object,
        countries: Array,
        giftcard: Object,
        zasilkovna: Object
    },
    data: () => {
        return {
            states: {
              'PAID': 'Zaplaceno',
              'CANCELED': 'Zrušeno',
              'CREATED': 'Vytvořeno',
                'TIMEOUTED':'Expirováno',
                'REFUNDED': 'Vráceno',
                'PAYMENT_METHOD_CHOSEN':'Nevybrána možnost platby',
                'AUTHORIZED': 'Autorizováno',
                'PARITALLY_REFUNDED': 'Částečně vráceno'
            },
            post_id: "",
            form: {
                shipping: null,
                editing: false,
                zasilkovna: {}
            },
            shippings: [],
            payments: []
        }
    },
    mounted() {
        this.post_id = this.order.post_id||""
    },
    methods: {
        addPost(){
          axios.put('/admin/orders/'+this.order.id+'/post', {
              post_id: this.post_id
          }).then(res => {
              Swal.fire(res.data.message, "", "success").then(res => (res.isConfirmed || res.isDismissed) ? location.reload() : null)

          }).catch(err => Swal.fire(err.response.data.message, "", "error"))
        },
        sendGift(giftcard){
            Swal.fire({
                title: "Zadejte email",
                input: "email",
                preConfirm(value) {
                    axios.post("/admin/orders/giftcard/"+giftcard.id, {email: value}).then(res => {
                        if(res.data.success){
                            Swal.fire("Dárkový poukaz byl odeslán.", "","success")
                            location.reload()
                        }
                    }).catch(err => {
                        Swal.fire("Dárkový poukaz se nepodařilo odeslat.", err.response.data.message,"error")
                    })
                }
            })
        },
        async edit() {
            this.shippings = (await axios.get('/admin/shipping/list')).data
            this.payments = (await axios.get('/admin/payments/list')).data
            this.form.shipping = this.shipping
            this.form.payment = this.payment
            this.form.editing = true
        },
        checkIfZasilkovna(e) {
            if (this.form.shipping.id == 1) {
                this.packeta();
            }
        },
        packeta() {
            Packeta.Widget.pick("ddc9f81afdd98678", this.packetSelected)
        },
        close() {
            this.form.shipping = this.form.payment = null;
            this.form.editing = false;
        },
        save() {
            axios.post('/admin/orders/' + this.order.id + "/update", this.form).then(res => {
                Swal.fire(res.data.message, "", "success").then(res => (res.isConfirmed || res.isDismissed) ? location.reload() : null)

            }).catch(err => Swal.fire(err.response.data.message, "", "error"))
        },
        packetSelected(data) {
            if (data != null) {
                this.form.zasilkovna = {};
                this.form.zasilkovna.place = data.place
                this.form.zasilkovna.zip = data.zip;
                this.form.zasilkovna.street = data.street;
                this.form.zasilkovna.city = data.city;
                this.form.zasilkovna.id = data.id;
            } else {
                this.form.shipping = null
            }
        },
        pay() {
            if (!this.order.paid && this.order.erp_order_id != null && this.order.erp_invoice_id != null) {
                let vm = this
                Swal.fire({
                    title: "Zadejte datum převzetí peněz",
                    icon: "question",
                    html: '<input type="date" id="money_date" value="'+this.order.created_at.split('T')[0]+'" class="swal2-input" name="value"/>',
                    preConfirm(value) {
                        let date = document.getElementById("money_date").value
                        axios.post('/admin/orders/' + vm.order.id + "/confirm", {
                            duzp: date
                        }).then(res => {
                            Swal.fire(res.data.message, "", "success")
                            location.reload()
                        }).catch(err => Swal.fire(err.response.data.message, "", "warning"))
                    }
                })
            }else {
                Swal.fire("Objednávku nelze zaplatit", "Neexistuje objednávka v ERP nebo už byla zaplacena", "error")
            }
        },
        deliver() {
            if (!this.order.delivering) {
                axios.post('/admin/orders/' + this.order.id + "/deliver").then(res => {
                    Swal.fire(res.data.message, "", "success")
                    location.reload()
                }).catch(err => Swal.fire(err.response.data.message, "", "warning"))
            }
        },
        finish() {
            if (!this.order.done && !this.order.canceled) {
                axios.post('/admin/orders/' + this.order.id + "/finish").then(res => {
                    Swal.fire(res.data.message, "", "success")
                    location.reload()
                }).catch(err => Swal.fire(err.response.data.message, "", "warning"))
            }
        },
        cancel() {
            if (!this.order.done) {
                axios.post('/admin/orders/' + this.order.id + "/cancel").then(res => {
                    Swal.fire(res.data.message, "", "success")
                    location.reload()
                }).catch(err => Swal.fire(err.response.data.message, "", "warning"))
            }
        },
        restore() {
            if (this.order.canceled) {
                axios.post('/admin/orders/' + this.order.id + "/restore").then(res => {
                    Swal.fire(res.data.message, "", "success")
                    location.reload()
                }).catch(err => Swal.fire(err.response.data.message, "", "warning"))
            }
        }
    }
}
</script>

<style scoped>

</style>
