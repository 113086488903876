<template>
    <span>{{calculatePrice(price, currency, vat)}} {{currency.symbol}}</span>
</template>

<script>
export default {
    name: "PriceFormat",
    props: {
        price: Number,
        vat: Boolean
    },
    mounted() {
    },
    computed: {
        currency(){
            return window._currency
        }
    }
}
</script>

<style scoped>

</style>
