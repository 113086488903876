<template>
    <div class="container justify-content-center">
        <h2>Hromadný export objednávek</h2>
       <div class="form-group">
           <label>Od</label><br/>
           <input type="date" v-model="from"  class="bi-calendar-date"/>
       </div>
        <div class="form-group">
            <label>Do</label><br/>
            <input type="date" v-model="to"  class="bi-calendar-date"/>
        </div>
        <a :href="'/admin/orders/invoices/zip/'+url" class="btn btn-lg btn-primary">Exportovat zip soubor s fakturami</a>
        <a :href="'/admin/orders/invoices/xlsx/'+url" class="btn btn-lg btn-primary">Exportovat knihu vydaných faktur</a>
    </div>
</template>

<script>
export default {
    name: "Invoices",
    data: ()=>{
        return {
            from: (new Date()).toISOString().split("T")[0],
            to: (new Date((new Date()).setMonth(new Date().getMonth()+1))).toISOString().split("T")[0]
        }
    },
    computed: {
        url(){
            return this.from +"/"+this.to
        }
    }
}
</script>

<style scoped>

</style>
