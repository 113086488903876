
Balikovna = window.Balikovna || {};
Balikovna.Viewport = {
    element: null,
    originalValue: null,
    set: function() {
        if(!Balikovna.Viewport.element) {
            Balikovna.Viewport.element = document.querySelector("meta[name=viewport]");
            if(Balikovna.Viewport.element) {
                Balikovna.Viewport.originalValue = Balikovna.Viewport.element.getAttribute("content");
            }
            else {
                Balikovna.Viewport.originalValue = 'user-scalable=yes';
                Balikovna.Viewport.element = document.createElement('meta');
                Balikovna.Viewport.element.setAttribute("name", "viewport");
                (document.head || document.getElementsByTagName('head')[0]).appendChild(Balikovna.Viewport.element);
            }
        }
        Balikovna.Viewport.element.setAttribute('content', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=yes');
    },
    restore: function() {
        if(Balikovna.Viewport.originalValue !== null) {
            Balikovna.Viewport.element.setAttribute('content', Balikovna.Viewport.originalValue);
        }
    }
};
Balikovna.Widget = {
    baseUrl: 'https://b2c.cpost.cz/locations',
    close: function() {},
    pick: function(callback, opts, inElement) {
        Balikovna.Widget.close();

        if(opts === undefined) {
            opts = {};
        }
        if(!('version' in opts)) {
            opts.version = 3;
        }

        opts.usePreProdWidgetVersion = true;

        var url = Balikovna.Widget.baseUrl+"?type=BALIKOVNY";


        var inline = (typeof(inElement) != "undefined" && inElement !== null);
        var wrapper;
        var cancel;
        if(inline) {
            wrapper = inElement;
        }
        else {
            Balikovna.Viewport.set();
            wrapper = document.createElement("div");
            wrapper.setAttribute("style", "z-index: 999999; position: fixed; -webkit-backface-visibility: hidden; left: 0; top: 0; width: 100%; height: 100%; background: rgba(256, 256, 256, 0.4) ");
            wrapper.addEventListener("click", function() {
                Balikovna.Widget.close();
            });

            cancel = document.createElement("div");
            cancel.setAttribute("style", "z-index: 999999; position: fixed; border-radius: 4px; color: darkred; font-size: 1.7em; font-weight: bold; padding: 5px; cursor:pointer; line-height: 20px; padding 5px; background:white; border: 1px solid gray;");
            cancel.innerHTML = "&#10006;"
            cancel.id = "cancel";
            cancel.addEventListener("click", function() {
                Balikovna.Widget.close();
            });

            // fix for some older browsers which fail to do 100% width of position:absolute inside position:fixed element
            setTimeout(
                function() {
                    var rect = iframe.getBoundingClientRect();
                    var width = ('width' in rect ? rect.width : rect.right - rect.left);
                    if(Math.round(width) < window.innerWidth - 10) { // 10px = side padding sum, just as a safety measure
                        iframe.style.width = window.innerWidth + "px";
                        iframe.style.height = window.innerHeight + "px";
                    }
                },
                0
            );
        }

        // always support Escape key immediatelly after the widget is displayed, even for inline
        wrapper.addEventListener("keyup", function(e) {
            if(e.keyCode == 27) {
                Balikovna.Widget.close();
            }
        });

        var iframe = document.createElement("iframe");
        if(inline) {
            iframe.setAttribute("style", "border: hidden; width: 100%; height: 100%; ");
        }
        else {
            iframe.setAttribute("style", "border: hidden; position: absolute; left: 0; top: 0; width: 100%; height: 100%; padding: 10px 5px; box-sizing: border-box; ");
        }
        iframe.setAttribute('id', "packeta-widget");
        iframe.setAttribute('sandbox', "allow-scripts allow-same-origin allow-forms");
        iframe.setAttribute('allow', "geolocation");
        iframe.setAttribute('src', url);

        cancel.appendChild(iframe);
        wrapper.appendChild(iframe);
        if(!inline) {
            document.body.appendChild(wrapper);
            document.body.appendChild(cancel);
        }

        if(wrapper.getAttribute("tabindex") === null) {
            wrapper.setAttribute("tabindex", "-1"); // make it focusable
        }
        wrapper.focus();

        if(cancel.getAttribute("tabindex") === null) {
            cancel.setAttribute("tabindex", "-1"); // make it focusable
        }
        cancel.focus();

        var receiver = function(e) {
            // check if this is message from the Balikovna Widget
            try {
                var message = e.data
                if(message.message != "pickerResult") return;

            }
            catch(err) {
                return;
            }

            Balikovna.Widget.close(message.point);
        };
        window.addEventListener('message', receiver);

        Balikovna.Widget.close = function(point) {
            window.removeEventListener('message', receiver);
            if(inline) {
                try {
                    iframe.parentNode.removeChild(iframe);
                }
                catch(err) {
                    // ignore
                }
            }
            else {
                document.body.removeChild(wrapper);
                document.body.removeChild(cancel);
                Balikovna.Viewport.restore();
            }
            callback(point || null);
            Balikovna.Widget.close = function() {};
        };
    }
};

