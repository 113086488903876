<template>
    <div class="container">
        <div class="justify-content-center">
            <div class="">
                <div class="card log-box auth">
                    <div class="card-header">
                        <h3 class="m-0">{{ _banner ? "Upravit banner" : "Nový banner" }}</h3>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="save">
                            <div class="form-group">
                                <label for="description">Obsah</label>
                                <input id="name" type="text" v-model="banner.name" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="description">Obsah anglicky</label>
                                <input id="name" type="text" v-model="banner.en_name" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="name">
                                    Obsah tlačítka
                                </label>
                                <input id="name" type="text" v-model="banner.description" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="name">
                                    Obsah tlačítka anglicky
                                </label>
                                <input id="name" type="text" v-model="banner.en_description" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="name">
                                    Odkaz po zmáčknutí tlačítka <span class="text-info">(např. /eshop)</span>
                                </label>
                                <input id="name" type="text" v-model="banner.url" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="img">Fotografie</label>
                                <input type="file" class="form-control-file" @change="getImg($event.target.files[0])"
                                       accept="image/*"/>
                                <img :src="banner.photo_path" class="img-fluid"/>
                            </div>


                            <input type="submit" value="Uložit" class="btn btn-primary">


                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import {VueEditor} from "vue3-editor";
import Swal from "sweetalert2";

export default {
    name: "Banner",
    components: {
        VueEditor
    },
    props: {
        _banner: {
            id: Number,
            name: String,
            en_name: String,
            description: String,
            en_description: String,
            photo_path: String,
            url: String,
            created_at: String,
            updated_at: String,
        }
    },
    data: () => {
        return {
            banner: {
                name: "",
                en_name: "",
                url: "",
                description: "",
                en_description: "",
                photo_path: null,
            }
        }
    },
    computed: {
        new() {
            return this._banner === undefined;
        }
    },
    methods: {
        getImg(img) {
            const file = img;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.banner.photo_path = e.target.result
            }
        },
        save() {
            if (this.new) {
                axios.post("/admin/banners/create", this.banner).then(res => {
                    Swal.fire("Banner vytvořen", "", "success");
                    if (res.data.success || res.status == 200) {
                        window.location.href = res.data.location
                    }
                }).catch(err => {
                    Swal.fire("Banner se nepodařilo vytvořit", err.response.data.message, "error");
                })
            } else {
                axios.put('/admin/banners/' + this.banner.id, this.banner).then(res => {
                    Swal.fire("Banner upraven", "", "success");
                    if (res.data.success || res.status == 200) {
                        window.location.href = res.data.location
                    }
                }).catch(err => {
                    Swal.fire("Banner se nepodařilo upravit", err.response.data.message, "error");
                })
            }
        }
    },
    mounted() {
        if (this._banner) {
            this.banner = this._banner
        }
    }
}
</script>

<style scoped>

</style>
