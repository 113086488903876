<template>
    <div class="container">
        <h1 class="text-center mb-5">{{__('Pokračovat na pokladnu')}}</h1>
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="bg-light p-4 mb-3">
                    <form @submit.prevent="login">
                        <h5 class="mb-3"><b>{{__('Existující zákazník')}}</b></h5>
                        <label>E-mail</label>
                        <input id="email" type="email" class="form-control mb-3"
                               name="email" v-model="form.email" required @blur="v$.form.email.$touch"/>
                        <div v-if="v$.form.email.$error" class="is-invalid">{{__('Email není ve správném tvaru.')}}</div>
                        <label>{{__('Heslo')}}</label>
                        <input id="password" type="password" v-model="form.password" class="form-control"
                               name="password" required >
                        <div class="add-to-cart mt-4">
                            <button class="btn btn-primary btn-black" :disabled="form.email.length < 8 || form.password.length < 6">{{__('Pokračovat na pokladnu')}}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="bg-light p-4 mb-3">
                    <h5 class="mb-3"><b>{{__('Nový zákazník')}}</b></h5>
                    <p>{{__('Vytvořte si u nás účet a získejte spoustu výhod!')}}</p>
                    <p><i class="fas fa-check pr-2"></i> {{__('Akční nabídky a slevy')}}<br>
                        <i class="fas fa-check pr-2"></i> {{__('Ukládání oblíbených produktů')}}<br>
                        <i class="fas fa-check pr-2"></i> {{__('Vrácení zdarma')}}</p>
                    <div class="add-to-cart">
                        <button @click="newCustomer" class="btn btn-outline-primary">{{__('Pokračovat na pokladnu')}}</button>
                    </div>
                </div>
                <div class="bg-light p-4 mb-3">
                    <h5 class="mb-3"><b>{{__('Dokončit jako host')}}</b></h5>
                    <p>{{__('Nechcete si u nás vytvářet účet?')}}</p>
                    <div class="add-to-cart">
                        <button @click="guest" class="btn btn-outline-primary">{{__('Pokračovat na pokladnu')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from '@vuelidate/core'
import {email} from '@vuelidate/validators'
export default {
    name: "step_two",
    data: ()=>{
        return {
            form: {
                email: "",
                password: "",
                error: null
            }
        }
    },
    setup: () => ({
        v$: useVuelidate()
    }),
    validations(){
        return {
            form: {
                email: {email}
            }
        }
    },
    methods: {
        async login(){
            if(!await this.v$.$validate()){
                Swal.fire(this.__('Email není ve správném tvaru.'), "", "error")
                return
            }
            axios.post('/api/eshop/login', {
                email: this.form.email,
                password: this.form.password,
                _token: document.getElementsByName('csrf-token')[0].content
            }).then(res => {
                if (res.status === 204 || res.data.success) window.location.href = '/eshop/checkout/step-3';
                else Swal.fire(res.data.message, "", "error");
            }).catch(err => Swal.fire(err.response.data.message, "", "error"))

        },
        guest(){
            window.location.href = '/eshop/checkout/step-3?continue=true';
        },
        newCustomer(){
            window.location.href = '/eshop/checkout/step-3?action=new_customer&continue=true'
        }
    }
}
</script>

<style scoped>

</style>
