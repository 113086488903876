<template>
    <div class="container mt-4" style="max-width: 1400px !important;">
        <div class="row" style="min-height: 600px">
                <a class="d-block d-lg-none w-100 border-bottom text-uppercase cat-1 mt-3" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                    {{__('Kategorie')}} <i class="fa fa-caret-down ml-2 p-0"></i>
                </a>
                <div class="col-xl-2 col-lg-3 p-0 sidebar collapse show" id="collapseExample">
                    <ul class="nav flex-column mt-lg-4">

                        <div class="nav-item" v-for="(category, index) in categories">
                            <div class="">
                                <a :href="'/eshop/'+category.slug" class="nav-link cat">{{ locale == 'en'?category.en_name:category.name }}</a>
                            </div>
                        </div>

                        <div class="nav-item">
                            <div class="">
                                <a :href="'/eshop/giftcards'" class="nav-link cat">{{__('Dárkové poukazy')}}</a>
                            </div>
                        </div>
                    </ul>
                </div>
                <div class="col-xl-10 col-lg-9 ml-auto p-0">
            <div class="card m-2" v-for="item in giftcards" style="width: 18rem;">
                <a :href="'/eshop/giftcards/'+item.slug">
                    <img class="card-img-top pb-0 item" style="padding: 1.25rem"
                         src="/images/giftCard.png" :alt="item.name"/>
                </a>
                <div class="card-body text-center d-flex flex-grow-0">
                    <div class="mt-auto mx-auto">
                        <h4>{{ locale == 'en'?item.en_name:item.name }}</h4>
                    </div>
                </div>
            </div>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GiftCards",
    props: {
        giftcards: Array,
        categories: Array,
    },
    methods: {

    },
    computed:{
        locale(){
            return window._locale;
        }
    }
}
</script>

<style scoped>

</style>
