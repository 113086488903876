<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-6">
                <div class="card auth log-box">
                    <div class="card-header">
                        <h4 class="mb-0">Nová varianta položky {{ item.name }}</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="send">
                            <div class="form-group">
                                <label for="name">
                                    Název
                                </label>
                                <input id="name" type="text" v-model="form.name" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="en_name">
                                    Název anglicky
                                </label>
                                <input id="en_name" type="text" v-model="form.en_name" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="category">Kategorie</label>
                                <select id="category" v-model="form.category_id" class="custom-select" name="category"
                                        disabled>
                                    <option :value="category.id">{{ category.name }}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="price">
                                    Cena s DPH
                                </label>
                                <input id="price" type="text" v-model="form.price_dph" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="desc">
                                    Český popis
                                </label>
                                <textarea id="desc" type="text" v-model="form.description" class="form-control" required></textarea>
                            </div>

                            <div class="form-group">
                                <label for="desc_en">
                                    Anglický popis
                                </label>
                                <textarea id="desc_en" type="text" v-model="form.description_en" class="form-control" required></textarea>
                            </div>
                            <div class="form-group d-flex">
                                <div class="flex-row d-flex mr-auto">
                                <label for="highlight" class="mb-0">
                                    Zobrazit na první stránce
                                </label>
                                <input id="highlight" type="checkbox" class="ml-2 form-check" v-model="form.is_highlighted"/>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    Barva
                                </label>
                                <input type="color" class="form-control form-control-color" v-model="form.color"/>
                            </div>
                            <input type="submit" value="Uložit" class="btn btn-primary">
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateItem",
    props: {
        item: Object,
        category: Object
    },
    components: {
    },
    data: () => {
        return {
            form: {
                name: null,
                en_name: null,
                category_id: Number,
                price_dph: null,
                description: null,
                description_en: null,
                is_highlighted: false,
                color: '#000000'
            }

        }
    },
    mounted() {
        this.form.name = this.item.name;
        this.form.price_dph = this.item.price_dph;
        this.form.description = this.item.description;
        this.form.category_id = this.category.id;
        this.form.description_en = this.item.description_en
    },
    methods: {
        idk(e){console.log(e)},
        send() {
            axios.post('/admin/items/'+this.item.id+"/variants/create", this.form).catch(err => {
                console.log(err)
            }).then(res => {
                if(res.data.success){
                    location.href = res.data.location
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
