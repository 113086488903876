<template>
    <div class="container">
        <div class=" justify-content-center">
                <div class="card log-box auth">
                    <div class="card-header">
                        <h3 class="m-0">{{this._collection?'Upravit kolekci':'Vytvořit kolekci'}}</h3>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="save()" method="POST"  enctype="multipart/form-data">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="name">
                                            Název
                                        </label>
                                        <input id="name" type="text" v-model="collection.name" class="form-control" required/>
                                    </div>
                                    <div class="form-group">
                                        <label for="name">
                                            Název anglicky
                                        </label>
                                        <input id="name_en" type="text" v-model="collection.name_en" class="form-control" required/>
                                    </div>
                                    <div class="form-check mb-3" v-if="collection.id > 0">
                                        <input type="checkbox" v-model="collection.visible" class="form-check-input"/>
                                        <label class="form-check-label">Viditelná</label>
                                    </div>
                                    <div class="form-group">
                                        <label>Popis</label>
                                        <vue-editor v-model="collection.description" style="height: 500px; border-color: black;" class="pb-md-5 border" :editorToolbar="toolbar">
                                        </vue-editor>
                                    </div>
                                    <div class="form-group">
                                        <label>Popis anglicky</label>
                                        <vue-editor v-model="collection.description_en" style="height: 500px; border-color: black;" class="pb-md-5 border" :editorToolbar="toolbar">
                                        </vue-editor>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <h5>Banner</h5>
                                        <input type="file" class="form-control-file" @change="getImg($event.target.files[0])" accept="image/*"/>
                                        <img :src="collection.photo_path" class="img-fluid"/>
                                    </div>
                                    <div class="form-group">
                                        <Multiselect :options="items" :label="'name'" :searchable="true"
                                                     :valueProp="'id'" :trackBy="'id'" :placeholder="'Vyberte položky'"
                                                     :object="true"
                                                     :noOptionsText="'Seznam produktů je prázdný'"
                                                     :noResultsText="'Žádný odpovídající produkt nebyl nalezen.'"
                                                     :option-height="104" @select="selectItem"
                                                     class="mb-3"
                                        ></Multiselect>
                                        <div class="row">
                                            <div class="media col-md-6 pl-0 border-right border-bottom pr-5" v-for="item in collection.items">
                                                <div class="media-body p-1">
                                                    <h5 class="mt-0">
                                                        {{item.name}}
                                                    </h5>
                                                    <button class="btn m-0" style="position: absolute; top: 3px; right: 3px; color: red; font-weight: 700; font-size: 1rem" @click="delItem(item)">X</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <input type="submit" value="Uložit" class="btn btn-primary">
                        </form>
                    </div>
                </div>

        </div>
    </div>
</template>

<script>
import {VueEditor} from "vue3-editor";
import Multiselect from '@vueform/multiselect'

import Swal from "sweetalert2";
export default {
    name: "Collection",
    components: {
        VueEditor,
        Multiselect
    },
    props: {
        _collection: Object
    },
    data: ()=>({
        toolbar: [
            [{'header': [false, 1,2,3,4,5]}],
            ["bold", "italic", "underline"],
            [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" }
            ],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
        collection : {
            id: 0,
            name: "",
            name_en: "",
            description: "",
            description_en: "",
            photo_path: "",
            visible: false,
            items: [],
            deleted: []
        },
        items: []
    }),
    computed: {

    },
    methods: {
        delItem(item){
            if(this._collection?.items.filter(i => i.id == item.id).length > 0){
                this.collection.deleted.push(item)
            }
            this.collection.items.splice(this.collection.items.findIndex(i => i.id == item.id), 1)
        },
        selectItem(item){
            if(!this.collection.items.includes(item)){
                this.collection.items.push(item)
            }
        },
        getImg(img){
            const file = img;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.collection.photo_path = e.target.result
            }
        },
        setCollection(){
            for(const i in this.collection)this.collection[i] = this._collection[i]
            this.collection.visible = Boolean(this.collection.visible)
            this.collection.deleted = [];
        },
        save(){
            if(this.collection.id > 0){
                axios.put('/admin/collections/'+this.collection.id, {collection: this.collection}).then(res => {
                    if(res.data.success){
                        Swal.fire("Kolekce byla upravena", "", "success").then(result => {
                            if (result.isConfirmed || result.isDismissed) location.reload();
                        })
                    }
                }).catch(err => {
                    Swal.fire("Kolekci se nepodařilo upravit", "", "error")
                })
            }else {
                axios.post('/admin/collections/create', {
                    collection: this.collection
                }).then(res => {
                    if(res.data.success){
                        Swal.fire("Kolekce byla vytvořena", "", "success").then(result => {
                            if (result.isConfirmed || result.isDismissed) window.location.href = res.data.location
                        })
                    }
                }).catch(err => {
                    Swal.fire("Kolekci se nepodařilo vytvořit", "", "error")
                })
            }
        },
        async getItems(){
            this.items = (await axios.get('/api/items')).data
        }
    },
    mounted() {
        if(this._collection){
            this.setCollection()
        }
        this.getItems();
    }
}
</script>

<style scoped>

</style>
