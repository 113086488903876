<template>
    <div class="container p-0">
        <div >

            <form @submit.prevent="save" class="row">
                <div class="col-md-4">
                    <div class="card log-box auth" style="height: auto">
                        <div class="card-body">
                            <img :src="table.photo_path" class="card-img" alt="Obrázek">
                            <input type="file" accept="image/*" class="form-control-file" @change="setPhoto"/>
                        </div>
                    </div>
                    <a v-if="_item && table.is_manual" class="btn btn-primary btn-sm" :href="route">Zobrazit produkt</a>
                </div>
                <div class="col-md-8 mt-md-0 mt-4">
                    <div class="card log-box auth">
                        <div class="card-header">
                            <h4 class="mb-0">{{ _table.name }}</h4>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label>Název</label>
                                <input type="text" class="form-control" v-model="table.name" required/>
                            </div>
                            <div class="form-group">
                                <label for="content">
                                    Obsah
                                </label>
                                <vue-editor id="content" :editorToolbar="toolbar" v-model="table.content"
                                            style="max-height: 500px">
                                </vue-editor>
                            </div>
                            <input type="submit" class="btn btn-primary mt-3 mb-0" value="Uložit"/>


                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
import {VueEditor} from "vue3-editor";
import Swal from "sweetalert2";

export default {
    name: "EditSizeTable",
    props: {
        _table: Object,
        _item: Object
    },
    components: {VueEditor},
    data: () => {
        return {
            table: {
                name: null,
                content: null,
                photo_path: null
            },
            toolbar: [
                [{header: [false, 1, 2, 3, 4, 5, 6]}],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    {align: ""},
                    {align: "center"},
                    {align: "right"},
                    {align: "justify"}
                ],
                ["blockquote", "code-block"],
                [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
                [{indent: "-1"}, {indent: "+1"}], // outdent/indent
                [{color: []}, {background: []}]
            ],
        }
    },
    computed: {
        manual(){
            return Boolean(this.$route.query?.manual);
        },
        route(){
            if(this._item != null){
                if(this._item.mother_item_id != null){
                    return '/admin/items/'+this._item.mother_item_id+"/variants/"+this._item.id
                }else {
                    return '/admin/items/'+this._item.id
                }
            }
        }
    },
    mounted() {
        this.table = this._table
    },
    methods: {
        setPhoto(e) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.table.photo_path = e.target.result
            }
        },
        save() {
            if(this.table.name.length < 1){
                Swal.fire("Název musí být delší", "", "error")
                return
            }
            axios.post('/admin/sizes/tables/'+this._table.id+"/update", {
                table: this.table
            }, {
                headers: {
                    Accept: 'application/json'
                }
            }).catch(err => {
                Swal.fire({
                    title: 'Tabulku se nepodařilo upravit, zkuste to prosím znovu.',
                    icon: 'error',
                    text: err.response.data.message,
                    confirmButtonColor: 'red',
                    confirmButtonText: 'OK'
                })

            }).then(res => {
                if (res.data.success) {
                    Swal.fire({
                        title: 'Tabulka byla upravena.',
                        icon: 'success',
                        confirmButtonColor: 'green',
                        confirmButtonText: 'OK'
                    }).then(result => {
                        location.reload()
                    })
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
