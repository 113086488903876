<template>
    <div class="">
        <div class="row">
            <div class="card col-md-8">
                <div class="card-header">
                    Upravit mail - {{ mail.name }}
                </div>
                <div class="card-body">
                    <form @submit.prevent="send">
                        <div class="form-group">
                            <label for="name">
                                Název
                            </label>
                            <input id="name" type="text" v-model="mail.name" name="name"
                                   class="form-control" required/>
                        </div>
                        <div class="form-group">
                            <label for="subject">
                                Předmět
                            </label>
                            <input id="subject" type="text" v-model="mail.subject" name="name"
                                   class="form-control" required/>
                        </div>
                        <div class="form-group">
                            <label for="content">
                                Obsah
                            </label>
                            <vue-editor id="content" v-model="mail.content" style="height: 500px" class="pb-md-5">
                            </vue-editor>
                        </div>

                        <input type="submit" value="Uložit" class="btn btn-primary">
                    </form>
                </div>
            </div>
            <div class="card col-md-4">
                <div class="card-header">Proměnné</div>
                <div class="card-body">
                    <table class="table table-hover table-borderless table-responsive">
                        <thead>
                        <tr>
                            <td>Název</td>
                            <td>Hodnota</td>
                            <td>Povinná</td>
                            <td>V textu</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v, i) in variables" >
                            <td>{{ v.alias }}</td>
                            <td><button class="btn btn-info"><b @click="add(i)">{{ '{'+i+'}' }}</b></button></td>
                            <td>
                                <span v-if="v.required" class="badge badge-pill badge-warning">ANO</span>
                                <span v-else class="badge badge-pill">NE</span>
                            </td>
                            <td>
                                <span v-if="mail.content.includes('{'+i+'}')" class="badge badge-pill badge-success">ANO</span>
                                <span v-else class="badge badge-pill">NE</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {VueEditor} from "vue3-editor";
import Swal from "sweetalert2";

export default {
    name: "EditMail",
    components: {
        VueEditor
    },
    props: {
        _mail: Object,
        _variables: {}
    },
    data: () => {
        return {
            mail: {},
            variables: {}
        }
    },
    mounted() {
        this.mail = this._mail
        this.variables = this._variables
    },
    methods: {
        async add(value){
          if(!await this.mail.content.includes(value)){
              this.mail.content += "{"+value+"}";
          }else {
              let split = this.mail.content.split("{"+value+"}")
              this.mail.content = split[0] + split[1]
          }
        },
        send() {
            if(this.mail.content == null || this.mail.content.length < 2){
                Swal.fire({
                    title: "Chyba",
                    text: 'Mail nesmí být prázdný',
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: "red"
                })
                return
            }
            axios.post('/admin/mails/' + this._mail.key, this.mail).catch(err => {
                Swal.fire({
                    title: "Chyba",
                    text: err.response.data.message,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: "red"
                })
            }).then(res => {
                Swal.fire({
                    title: "Hotovo",
                    text: res.data.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "green"
                }).then(result => (result.isConfirmed) ? location.reload() : null)
            })
        }
    }
}
</script>

<style scoped>

</style>
