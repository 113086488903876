<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-6">
                <div class="card shadow">
                    <div class="card-header">
                        {{ __('Upravit adresu') }}
                    </div>
                    <div class="card-body">
                        <form action="#" method="POST" @submit.prevent="checkAndSave">
                            <h4>{{ __('Fakturační adresa') }}</h4>
                            <div class="form-group">
                                <div class="form-check p-0">
                                    <input type="radio" class="custom-radio" name="type" v-model="form.type"
                                           :value="0"/>
                                    <label class="form-check-label pl-2">{{ __('Fyzická osoba nepodnikající') }}</label><br/>
                                </div>
                                <div class="form-check p-0">
                                    <input type="radio" class="custom-radio" name="type" v-model="form.type"
                                           :value="1"/>
                                    <label
                                        class="form-check-label pl-2">{{ __('Fyzická osoba podnikající') }}</label><br/>
                                </div>
                                <div class="form-check p-0">

                                    <input type="radio" class="custom-radio" name="type" v-model="form.type"
                                           :value="2"/>
                                    <label class="form-check-label pl-2">{{ __('Firma') }}</label><br/>
                                </div>
                            </div>
                            <div class="form-group col p-0" v-if="form.type > 0">
                                <label for="company">
                                    {{ __('Název firmy') }}
                                </label>
                                <input id="company" type="text" class="form-control" v-model="form.company"
                                       @blur="v$.form.company.$touch">
                                <div v-if="v$.form.company.$error" class="is-invalid">
                                    {{ __('Název firmy není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="row" v-if="form.type > 0">
                                <div class="form-group col pl-0">
                                    <label for="ico">
                                        {{ __('IČO') }}
                                    </label>
                                    <input id="ico" type="text" class="form-control" v-model="form.ico"
                                           @blur="v$.form.ico.$touch">
                                    <div v-if="v$.form.ico.$error" class="is-invalid">
                                        {{ __('IČO není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group col pr-0">
                                    <label for="company">
                                        {{ __('DIČ') }}
                                    </label>
                                    <input id="dic" type="text" class="form-control" v-model="form.dic" aria-label="dic"
                                           aria-describedby="basic-addon2" :disabled="dic.checked"/>
                                    <button class="btn btn-primary" v-if="!dic.checked" type="button" @click="checkDic"
                                            :disabled="dic.checked" v-html="dic.btn"></button>
                                    <button class="btn btn-warning" v-else type="button" @click="dicChange">
                                        {{ __('Vymazat DIČ') }}
                                    </button>
                                    <div v-if="dic.error || v$.form.dic.$error" class="is-invalid">
                                        {{ __('DIČ není validní') }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="address">{{ __('Ulice, č.p.') }}</label>
                                <input id="address" type="text" class="form-control" v-model="form.address"
                                       @blur="v$.form.address.$touch" required/>
                                <div v-if="v$.form.address.$error" class="is-invalid">
                                    {{ __('Adresa není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="city">{{ __('Město') }}</label>
                                <input id="city" type="text" class="form-control" v-model="form.city" required
                                       @blur="v$.form.city.$touch"/>
                                <div v-if="v$.form.city.$error" class="is-invalid">
                                    {{ __('Město není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="zip">{{ __('PSČ') }}</label>
                                <input id="zip" type="text" class="form-control" v-model="form.zip" required
                                       @blur="v$.form.zip.$touch"/>
                                <div v-if="v$.form.zip.$error" class="is-invalid">
                                    {{ __('PSČ není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ __('Stát') }}</label>
                                <select class="custom-select" v-model="form.country_id">
                                    <option v-for="country in _countries" :value="country.id">{{country.name}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="telephone">{{ __('Telefonní číslo') }}</label>
                                <input id="telephone" type="text" class="form-control" v-model="form.telephone"
                                       @blur="v$.form.telephone.$touch"
                                       required/>
                                <div v-if="v$.form.telephone.$error" class="is-invalid">
                                    {{ __('Telefonní číslo není ve správném tvaru') }}
                                </div>
                            </div>
                            <div class="form-group form-check">
                                <input id="check" type="checkbox" class="form-check-input" v-model="checked"/>
                                <label for="check"
                                       class="form-check-label">{{ __('Fakturační adresa se liší od dodací') }}</label>
                            </div>
                            <div v-if="checked">
                                <h5>{{ __('Dodací adresa') }}</h5>
                                <div class="form-group">
                                    <label for="fa_address">{{ __('Ulice, č.p.') }}</label>
                                    <input id="fa_address" type="text" class="form-control"
                                           v-model="form.fa_address" required @blur="v$.form.fa_address.$touch"/>
                                    <div v-if="v$.form.fa_address.$error" class="is-invalid">
                                        {{ __('Adresa není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="city">{{__('Město')}}</label>
                                    <input id="fa_city" type="text" class="form-control" v-model="form.fa_city"
                                           required @blur="v$.form.fa_city.$touch"/>
                                    <div v-if="v$.form.fa_city.$error" class="is-invalid">
                                        {{ __('Město není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="zip">{{ __('PSČ') }}</label>
                                    <input id="fa_zip" type="text" class="form-control" v-model="form.fa_zip"
                                           required @blur="v$.form.fa_zip.$touch"/>
                                    <div v-if="v$.form.fa_zip.$error" class="is-invalid">
                                        {{ __('PSČ není ve správném tvaru') }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>{{ __('Stát') }}</label>
                                    <select class="custom-select" v-model="form.fa_country_id">
                                        <option v-for="country in _countries" :value="country.id">{{country.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="fa_telephone">{{ __('Telefonní číslo') }}</label>
                                    <input id="fa_telephone" type="text" class="form-control"
                                           v-model="form.fa_telephone"
                                           @blur="v$.form.fa_telephone.$touch" required/>
                                    <div v-if="v$.form.fa_telephone.$error" class="is-invalid">
                                        {{ __('Telefonní číslo není ve správném tvaru') }}
                                    </div>
                                </div>

                            </div>
                            <input type="submit" class="btn btn-primary" :value="__('Uložit')"/>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, email, helpers, maxLength, minLength, requiredIf} from '@vuelidate/validators'
import Swal from 'sweetalert2'

export default {
    name: "CustomerAddressEdit",
    props: ["_user", "_countries"],
    mounted() {
        this.form = this._user
        if (!this.same_address()) {
            this.checked = true;
        }
        this.dic.btn = this.__('Oveřit')
    },
    setup: () => ({
        v$: useVuelidate()
    }),
    validations() {
        const dicChecked = () => {
            return this.dic.checked
        }
        const params = {
            zip: {
                zip(value) {
                    return value.length === 5 && Number.isInteger(parseInt(value))
                }, required
            },
            telephone: {
                telephone(value) {
                    return value.match(/\d/g).length === 9;
                }, required
            },
            city: {
                city(value) {
                    return value.length >= 2 && typeof value === 'string'
                }, required
            },
            address: {
                address(value) {
                    let regex = new RegExp("[1-9]");
                    return regex.test(value);
                }, required, maxLength: maxLength(50), minLength: minLength(2)
            },
            dic: {
                requiredIfDic: requiredIf(this.form.type == 2),
                maxLength: maxLength(15),
                checked: dicChecked
            },
            ico: {
                requiredIfIco: requiredIf(this.form.type > 0)
            },
            company: {
                requiredIfCompany: requiredIf(this.form.type == 2)
            }

        }
        let form = {
            zip: params.zip,
            telephone: params.zip,
            city: params.city,
            address: params.address,

        }
        if (this.form.type > 0) {
            form.dic = params.dic
            form.ico = params.ico
            form.company = params.company

        }
        if(this.form.country_id == 1){
            form.zip = params.zip
            form.telephone = params.telephone
        } else {
            form.zip = form.telephone = {required: required, minLength: minLength(3)}
        }
        if (this.checked) {
            form.fa_city = params.city
            form.fa_address = params.address
            if(this.form.fa_country_id == 1){
                form.fa_zip = params.zip
                form.fa_telephone = params.telephone
            } else {
                form.fa_zip = form.fa_telephone = {required: required, minLength: minLength(3)}
            }
        }
        return {form};


    },
    data: () => {
        return {
            form: {},
            checked: false,
            dic: {
                checked: false,
                error: null,
                btn: null,
            }
        }
    },

    methods: {
        same_address(){
            return this.form.address == this.form.fa_address && this.form.city == this.form.fa_city
            && this.form.zip == this.form.fa_zip && this.form.country_id == this.form.fa_country_id
        },
        dicChange() {
            if (this.dic.checked) {
                this.dic.error = null;
                this.form.dic = null;
                this.dic.checked = false;
            }
        },
        async checkDic() {
            let iso = this.form.dic.substring(0, 2);
            if (iso != this._countries.find(c => c.id == this.form.country_id).iso_code) {
                this.dic.error = this.__('Kód země nesouhlasí s fakturační zemí');
                return;
            }
            this.dic.btn = '<div class="spinner-border" role="status">\n' +
                '  <span class="sr-only">Loading...</span>\n' +
                '</div>'
            const res = await axios.get('/customer/address/company_check/' + this.form.dic)
            if (res.data.valid) {
                this.dic.checked = true;
                this.dic.error = null;
            } else if (res.data?.note) {
                this.dic.error = res.data.note;
            } else {
                this.dic.error = this.__('Dič není validní');
            }
            this.dic.btn = this.__('Ověřit')
        },
        async checkAndSave() {
            if (await this.v$.$validate()) {
                axios.put('/customer/address/edit', this.form).then(res => {
                    if (res.data.success) {
                        Swal.fire({
                            text: res.data.message,
                            confirmButtonText: 'OK',
                            icon: 'success',
                        }).then(result => {
                            if (result.isConfirmed) {
                                window.location.href = res.data.location
                            }
                        })
                    }
                }).catch(err => {
                    Swal.fire({
                        text: err.response.data.message,
                        confirmButtonText: 'OK',
                        icon: 'error'
                    })
                })
            }
        }
    },
    watch: {
        checked(value) {
            if (!value) {
                this.form.fa_address = null
                this.form.fa_city = null
                this.form.fa_country = null
                this.form.fa_telephone = null
                this.form.fa_zip = null
            }

        },
        'form.country_id'(a, b) {
            if(a != b){
                this.dicChange()
            }
        }
    }


}
</script>

<style scoped>

</style>
