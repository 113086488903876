<template>
    <div class="container pb-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb my-5">
                <li class="breadcrumb-item" aria-current="page"><a
                    href="/eshop/giftcards">{{ __('Dárkové poukazy') }}</a></li>
            </ol>
        </nav>

        <div class="row">
            <div class="col-12 col-md-1 d-block d-md-none">
                <h1 class="item-name mb-0">{{ locale == 'en'?giftcard.en_name:giftcard.name }}</h1>
                <div class="item-prices mb-0">
                    <h5 class="item-price d-inline-block">{{ calculatePrice(giftcard.price/1.21, currency) }}
                        {{ currency.symbol }}</h5>
                </div>
            </div>
            <div class="col-12 col-md-1 d-block d-md-none">
                <div class="item-stars">
<!--                    <a href="/">-->
<!--                        <star-rating v-model:rating="item.rating" :read-only="true" :star-size="16" :show-rating="false"/>-->
<!--                    </a>-->
                    <h6 class="item-price d-inline-block float-right">{{ calculatePrice(giftcard.price/1.21, currency, false) }}
                        {{ currency.symbol }} {{ __('bez DPH') }}</h6>
                </div>

            </div>

            <div class="col-md-7 pl-0">
                <img style="width: 100%; height: auto" src="/images/giftCard.png" alt="dárkový poukaz"/>
            </div>

            <div class="col-md-5 d-none d-md-block">
                <h1 class="item-name">{{ locale == 'en'?giftcard.en_name:giftcard.name }}</h1>
                <div class="item-prices">
                    <h4 class="item-price">{{ calculatePrice(giftcard.price/1.21, currency) }}
                        {{ currency.symbol }}</h4>
                </div>
                <div class="item-prices d-none d-md-block">
                    <h6 class="item-price d-inline-block">{{ calculatePrice(giftcard.price/1.21, currency, false) }}
                        {{ currency.symbol }} {{ __('bez DPH') }}</h6>
                </div>

                <div class="item-short-desc my-2 d-none d-md-block">
                    <p>{{ giftcard.description }}</p>
                </div>

                <div class="add-to-cart">

                    <button class="btn btn-primary mt-3" @click="addItem">
                        {{ __('Přidat do košíku') }}
                    </button>
                </div>
            </div>

                <div class="col-12 col-md-5 ml-auto d-block d-md-none">

                    <div class="item-short-desc my-2">
                        <p>{{ giftcard.description }}</p>
                    </div>

                    <div class="add-to-cart">

                        <button class="btn btn-primary mt-3" @click="addItem">
                            {{ __('Přidat do košíku') }}
                        </button>
                    </div>
                </div>
        </div>

<!--            <div class="col-md-12 p-0">-->
<!--                <h1 class="productName">{{ giftcard.name }}</h1>-->
<!--                <img class="" style="padding: 1.25rem" src="/images/giftCard.png" alt="dárkový poukaz"/>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                    <div class="row">-->
<!--                        <div class="col-md-12">-->
<!--                            <div class="price"><span style="bottom: 0; position: absolute">{{ __('Cena') }}</span> <span-->
<!--                                class="priceNum">{{ giftcard.price }}</span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-md-12">-->
<!--                        <button class="btn btn-success mt-3" style="max-height: 37px" @click="addItem">{{__('Přidat do košíku')}}-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
    </div>


</template>

<script>
export default {
    name: "GiftCard",
    props: {
        giftcard: Object
    },
    computed: {
        currency() {
            return window._currency;
        },
        locale(){
               return window._locale;
           }
    },
    methods: {
        async addItem() {
            const res = await axios.post("/api/eshop/cart/giftcards/" + this.giftcard.id)
            if(!res.data.success){
                this.toast.error(this.__('Produkt se nepodařilo přidat do košíku.'))
            }else {
                this.toast.success(this.__('Produkt byl přidán do košíku.'));
                this.$store.state.cart = res.data.cart
            }

        }
    }
}
</script>

<style scoped>
.productName {
    magin-top: 2rem;
    margin-bottom: 5rem;
}

.price {
    font-size: 16px;
    display: flex;
    position: relative;
}

.DPHprice {
    color: gray;
    display: flex;
}

.DPHpriceNum {
    color: gray;
    margin-left: auto;
}

.priceNum {
    color: red;
    font-size: 24px;
    text-align: right;
    margin-left: auto;
}
</style>
