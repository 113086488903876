<template>
    <div class="container">
        <h1 class="text-center mb-5">{{ __('Nákupní košík') }}</h1>
        <div class="row">
            <div class="col-md-8 col-12" v-if="cart.giftcards.length > 0 || cart.items.length > 0">
                <div class="row pb-3 mb-5 border-bottom" v-for="(item, index) in cart.items">
                    <div class="col-3">
                        <img :src="item.photo" class="w-100" :alt="item.name"/>
                    </div>
                    <div class="col-9">
                        <span class="float-right pr-md-5 pr-0">
                            <i class="far fa-trash-alt" @click="deleteItem(item, index)"
                               style="cursor: pointer"></i>
                        </span>
                        <h6>{{ locale == 'en'?item.en_name:item.name }}</h6>
                        <h6 class="mb-3">{{ calculatePrice(item.price, currency) }} {{ currency.symbol }}</h6>
                        <p class="small mb-0">{{__('Velikost')}}: {{ item.size.name }}</p>
                        <p class="small">{{ __('Množství') }}: <span class="input-number-decrement" v-if="!updating"
                                                                     id="sub" @click="subCount(item)">–</span><input
                            class="input-number"
                            type="text"
                            min="0" :value="item.count" id="change" v-if="!updating"
                            @change="changeCount(item, $event.target.value)"
                            max="10"><span class="input-number-increment" v-if="!updating" id="add"
                                           @click="addCount(item, 1)">+</span></p>
                    </div>
                </div>
                <div class="row pb-3 mb-5 border-bottom" v-for="(item, index) in cart.giftcards">
                    <div class="col-3">
                        <img src="/images/giftCard.png" class="w-100" :alt="item.name"/>
                    </div>
                    <div class="col-9">
                                        <span class="float-right pr-md-5 pr-0">
                                            <i class="far fa-trash-alt" @click="deleteGift(item)"
                                               style="cursor: pointer"></i>
                                        </span>
                        <h6>{{ locale == 'en'?item.en_name:item.name}}</h6>
                        <h6 class="mb-3">{{ calculatePrice(item.price, currency) }} {{
                                currency.symbol
                            }}</h6>
                        <p class="small">{{ __('Množství') }}: <span class="input-number-decrement" v-if="!updating"
                                                                     id="sub"
                                                                     @click="subGiftCount(item, 1)">–</span><input
                            class="input-number"
                            type="text"
                            min="0" :value="item.count" id="change" v-if="!updating"
                            @change="changeGiftCount(item, $event.target.value)"
                            max="10"><span class="input-number-increment" v-if="!updating" id="add"
                                           @click="addGiftCount(item, 1)">+</span></p>
                    </div>
                </div>
                <div class="pb-3 mb-5 border-bottom">
                    <b class="col-md-6">{{ __('Slevový kupón') }}</b>
                    <div class="input-group mb-3 col-md-6 mt-2" v-if="cart.coupon.dis_code == null">
                        <input type="text" class="form-control" aria-describedby="button-addon2"
                               v-model="coupon.dis_code">
                        <div class="input-group-append">
                            <button class="btn btn-secondary m-0" type="button" id="button-addon2"
                                    @click="checkCoupon">{{ __('Přidat') }}
                            </button>
                        </div>
                        <p class="red" v-if="coupon.msg">{{coupon.msg}}</p>
                    </div>
                    <div class="input-group mb-3 col-md-6 mt-2" v-else>
                        <input type="text" class="form-control" aria-describedby="button-addon2"
                               :value="cart.coupon.dis_code" disabled>
                        <div class="input-group-append">
                            <button class="btn btn-secondary m-0" type="button" id="button-addon2"
                                    @click="delCoupon">{{ __('Odebrat') }}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-8 col-12 mb-4" v-if="cart.giftcards.length <1 && cart.items.length < 1">
                <div class="bg-light p-4">
                    <h2 class="text-uppercase mb-4 d-md-block d-none">{{ __('Váš nákupní košík je prázdný.') }}</h2>
                    <h4 class="text-uppercase mb-4 d-md-none d-block">{{ __('Váš nákupní košík je prázdný.') }}</h4>
                    <p>
                        {{
                            __('Přihlaste se, abyste mohli ukládat položky v nákupním košíku nebo se dostat k těm, které jste již uložili.')
                        }}</p>
                </div>

            </div>
            <div class="col-md-4 col-12">
                <div class="bg-light p-4">
                    <table width="100%">
                        <tr>
                            <td class="py-2">{{ __('Hodnota objednávky') }}:</td>
                            <td class="py-2 text-right">{{ calculatePrice(cart.price, currency) }}
                                {{ currency.symbol }}
                            </td>
                        </tr>
                        <tr v-if="cart.price_discounted_dph > 3000">
                            <td class="pt-2 pb-3">{{ __('Doprava') }}:</td>
                            <td class="pt-2 pb-3 text-right">{{ __('ZDARMA') }}</td>
                        </tr>
                        <tr v-if="cart.coupon?.discount >0 ">
                            <td class="pt-2 pb-3">{{ __('Sleva') }}:</td>
                            <td class="pt-2 pb-3 text-right">{{ cart.coupon.discount * 100 }} %</td>
                        </tr>
                        <tr class="border-top ">
                            <td class="pt-3"><h5><b>{{ __('Celkem') }}:</b></h5></td>
                            <td class="pt-3 text-right"><h5><b>{{ calculatePrice(cart.price_discounted, currency) }}
                                {{ currency.symbol }}</b></h5></td>
                        </tr>
                    </table>
                    <div class="add-to-cart">
                        <button class="btn btn-primary" @click="checkAndContinue" :disabled="cart.count < 1">
                            {{ __('Pokračovat na pokladnu') }}
                        </button>
                    </div>
                    <p class="small text-dark">
                        {{ __('Ceny a náklady na dopravu budou potvrzeny, až dojdete do sekce pokladna.') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "step_one",
    data: () => {
        return {
            coupon: {
                dis_code: null,
                msg: null,
                used: false
            }
        }
    },
    computed: {
        currency() {
            return window._currency;
        },
        locale(){
            return window._locale;
        },
        cart() {
            return this.$store.state.cart;
        },
        updating() {
            return this.$store.state.updating
        }
    },
    mounted(){

    },
    methods: {
        async deleteGift(item) {
            const res = await axios.post("/api/eshop/cart/giftcards/" + item.cart_item_id + "/delete")
            if (!res.data.success) {
                this.toast.error(this.__('Produkt se nepodařilo odebrat košíku.'))
            } else {
                this.$store.state.cart = res.data.cart
            }
            this.$store.state.updating = false
        },
        async subGiftCount(item, val = 1){
            this.$store.state.updating = true
            const res = await axios.post("/api/eshop/cart/giftcards/" + item.cart_item_id + "/sub/" + val);
            if (!res.data.success) {
                this.toast.error(this.__("Nepodařilo se změnit počet produktů."));
            }else {
                this.$store.state.cart = res.data.cart
            }
            this.$store.state.updating = false
        },
        async addGiftCount(item, val = 1){
            this.$store.state.updating = true
            const res = await axios.post("/api/eshop/cart/giftcards/" + item.cart_item_id + "/add/" + val);
            if (!res.data.success) {
                this.toast.error(this.__("Nepodařilo se změnit počet produktů."));
            }else {
                this.$store.state.cart = res.data.cart
            }
            this.$store.state.updating = false
        },
        async changeGiftCount(item, val){
            if (val > item.count) {
                this.addGiftCount(item, val - item.count)
            } else if (val < item.count) {
                this.subGiftCount(item, item.count - val)
            }
        },
        async addCount(item, val = 1) {
            this.$store.state.updating = true
            const res = await axios.post("/api/eshop/cart/" + item.cart_item_id + "/add/" + val)
            if (!res.data.success) {
                this.toast.error(this.__("Nepodařilo se změnit počet produktů."));
            }else {
                this.$store.state.cart = res.data.cart
            }

            this.$store.state.updating = false
        },
        async subCount(item, val = 1) {
            this.$store.state.updating = true
            const res = await axios.post("/api/eshop/cart/" + item.cart_item_id + "/sub/" + val)
            if (!res.data.success) {
                this.toast.error(this.__("Nepodařilo se změnit počet produktů."));
            }else {
                this.$store.state.cart = res.data.cart
            }
            this.$store.state.updating = false
        },
        async changeCount(item, val) {
            if (val > item.count) {
                this.addCount(item, val - item.count)
            } else if (val < item.count) {
                this.subCount(item, item.count - val)
            }
        },

        async deleteItem(item) {
            const res = await axios.post("/api/eshop/cart/" + item.cart_item_id + "/delete")
            if (!res.data.success) {
                this.toast.error(this.__('Produkt se nepodařilo odebrat košíku.'))
            } else {
                this.$store.state.cart = res.data.cart
            }
            this.$store.state.updating = false
        },
        checkAndContinue() {
            window.location.href = '/eshop/checkout/step-2'
        },
        delCoupon() {
            if (this.cart.coupon.dis_code != null) {
                axios.post('/api/eshop/cart/voucher/delete').then(res => {
                    this.coupon = {dis_code: null, used: null, msg: null}
                })
            }
        },
        async checkCoupon() {
            if (this.cart.coupon.dis_code == null && this.coupon.msg == null) {
                axios.post('/api/eshop/cart/voucher/' + this.coupon.dis_code + '/check').then(res => {
                    if (res.data.success) {
                        this.coupon.dis_code = res.data.voucher.code
                        this.coupon.used = true
                    } else {
                        this.coupon.dis_code = null;
                    }
                }).catch(err => {
                    if(err.response.status == 429)this.coupon.msg = this.__('Příliš mnoho pokusů, zkuste to později.')
                    else {
                        this.coupon.dis_code = null;
                        this.coupon.msg = err.response.data.message;
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
