<template>
    <div id="collections">
        <div class="container">
            <h1 class="title border-bottom">{{__('Kolekce')}}</h1>
            <div class="col-12 p-0">
                <div class="group-container" v-for="collection in _collections">
                    <a :href="'/kolekce/' + collection.slug" style="position: absolute; height: 100%; width: 100%"></a>
                    <div class="position-absolute">
                        <h2 class="group-title" v-if="locale == 'en'">{{collection.name_en}}</h2>
                        <h2 class="group-title" v-else>{{collection.name}}</h2>
                        <div class="group-desc" v-if="locale == 'en'">{{ stripHtml(collection.description_en)}}</div>
                        <div class="group-desc" v-else>{{ stripHtml(collection.description)}}</div>
                    </div>
                    <a class="collection-btn" :href="'/kolekce/' + collection.slug">{{__('Přejít na kolekci')}}</a>
                    <img class="group-img" :src="collection.photo_path" :alt="collection.slug">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CollectionPage",
    props: ["_collections"],
    methods: {
        stripHtml(html)
        {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        }
    }
}
</script>

<style scoped>

</style>
