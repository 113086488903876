<template>
    <country-modal/>
    <div class="container-fluid" id="categoryPage">
        <div class="row">
                <a class="d-block d-lg-none w-100 border-bottom text-uppercase cat-1 mt-3" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                    {{__('Kategorie')}} <i class="fa fa-caret-down ml-2 p-0"></i>
                </a>
            <div class="col-xl-2 col-lg-3 p-0 sidebar collapse show" id="collapseExample">
                                <ul class="nav flex-column mt-lg-4">
                                    <div class="nav-item" v-for="(category, index) in categories">
                                        <div class="">
                                            <a :href="'/eshop/'+category.slug" class="nav-link cat" :class="dropped.includes(index) ? 'cat-bold' : ''">{{ locale == 'en'?category.en_name:category.name }}</a>
                                        </div>
                                    </div>

                                    <div class="nav-item">
                                        <div class="">
                                            <a :href="'/eshop/giftcards'" class="nav-link cat" :class="dropped.includes(index) ? 'cat-bold' : ''">{{__('Dárkové poukazy')}}</a>
                                        </div>
                                    </div>
                                </ul>
            </div>
            <div class="col-xl-10 col-lg-9 ml-auto p-0">

                    <div class="category-title-pictureless ml-3">
                        <h1 class="m-0">{{__('Novinky v eshopu')}}</h1>
                    </div>
                    <div class="d-flex filter-bar mt-5 border-bottom">
                    </div>
                    <div class="row mt-4">
                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12" v-for="item in items">
                            <div class="col-12 item-card my-2 card-padding">
                            <div class="card itmImg border-0 d-flex w-100">
                                <a :href="'/eshop/product/'+item.slug" class="d-flex item-link position-relative m-auto" style="position: relative" :class="item.photos[1] != null ? 'item-card-link' : ''"><img class="card-img-top pb-0 item primary-photo"
                                                                                                                                                                         v-lazy="item.photos[0].thumb_path"/>
                                    <img class="card-img-top pb-0 item secondary-photo" v-if="item.photos[1] != null"
                                         v-lazy="item.photos[1].thumb_path"/>
                                    <!--                                <img class="card-img-top pb-0 item" v-if=""-->
                                    <!--                                     v-lazy="item.photo.thumb_path"/>-->

                                </a>
                            <!--                    TODO: přidat BE tagů-->
                            <!--                    <span class="tag-wrapper"><span class="tag">Doprava zdarma</span></span>-->
                            <div class="d-flex" v-if="user">
                                <svg xmlns="http://www.w3.org/2000/svg" v-if="favorites[item.id] === undefined"
                                     width="16"
                                     height="16"
                                     fill="currentColor"
                                     class="bi bi-heart ml-auto mb-auto"
                                     viewBox="0 0 16 16"
                                     @click="setFavourite(item)">
                                    <path
                                        d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" v-else
                                     width="16"
                                     height="16"
                                     fill="red"
                                     class="bi bi-heart bi-heart-fill ml-auto mb-auto"
                                     viewBox="0 0 16 16"
                                     @click="setFavourite(item)">
                                    <path
                                        d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                </svg>
                            </div>
                            <div class="card-body text-center d-flex flex-grow-0 mt-auto p-0">
                                <div class="mt-auto mx-auto col p-0">
                                    <div class="item-card-name">{{ locale == 'en'?item.en_name:item.name }}</div>
                                    <div class="row mb-2 align-items-end split">
                                        <div class="col text-left p-0">
                                            <div v-if="item.discounted_price_dph > 0" class="split" style="line-height: 26px">
                                                <span class="obsolete">{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</span><br/>
                                                <span class="discounted">{{ calculatePrice(item.discounted_price, currency) }} {{currency.symbol}}</span>
                                            </div>
                                            <div v-else class="d-flex split">
                                                <span class="regular mt-auto">{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</span>
                                            </div>
                                        </div>
                                        <a :href="'/eshop/product/'+item.slug" class="btn btn-primary btn-detail m-0 h-100">Detail</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!--<template>-->
<!--    <country-modal/>-->
<!--    <div class="container" style="max-width: 1400px !important;">-->
<!--        <div class="d-lg-none d-md-flex d-none">-->
<!--            <h1 class="pb-3" style="margin-left: 3%">{{__('Novinky v eshopu')}}</h1>-->
<!--            <div class="ml-auto" style="margin-right: 3%"><search-component></search-component></div>-->
<!--        </div>-->
<!--        <h1 class="pb-3 text-center d-md-none d-block">{{__('Novinky v eshopu')}}</h1>-->
<!--        <div class="row">-->
<!--            <div class="px-3 col-lg-3 sidebar bg-light">-->
<!--                <div :class="(scrollPosition > 170) ? 'sidebar-scrolled-main' : ''">-->
<!--                <ul class="nav flex-column">-->

<!--                    <div class="nav-item" v-for="(category, index) in categories">-->
<!--                        <div class="">-->
<!--                            <a :href="'/eshop/'+category.slug" class="nav-link cat-1" :class="dropped.includes(index) ? 'cat-bold' : ''">{{ category.name }}</a>-->
<!--                        </div>-->
<!--                        <ul class="cat-list" v-if="dropped.includes(index)">-->
<!--                            <div class="nav-item" v-for="(subcategory, j) in category.children">-->
<!--                                <div class="flex-row d-flex">-->
<!--&lt;!&ndash;                                    <i v-if="subcategory.children[0]" @click="categoryUpdate(j + 1000)" class="fas fa-caret-down my-auto"></i>&ndash;&gt;-->
<!--                                    <a :href="'/eshop/'+subcategory.slug" class="nav-link cat-2">{{ subcategory.name }} </a>-->
<!--                                </div>-->
<!--&lt;!&ndash;                                <div v-if="dropped.includes(j+1000)">&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="nav-item ml-2" v-for="(subsubcategory, j) in subcategory.children">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="flex-row d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <a :href="'/eshop/'+subsubcategory.slug" class="nav-link">{{ subsubcategory.name }}</a>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->

<!--                            </div>-->
<!--                        </ul>-->

<!--                        </div>-->
<!--                </ul>-->
<!--                </div>-->
<!--            </div>-->


<!--            <div class="col-lg-9 float-right">-->
<!--                <div class="d-lg-flex d-none">-->
<!--                    <h1 class="pb-3">{{__('Novinky v eshopu')}}</h1>-->
<!--                    <div class="ml-auto"><search-component></search-component></div>-->
<!--                </div>-->
<!--                <div class="row mt-2">-->
<!--                <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 item-card" v-for="item in items">-->
<!--                    <div class="card itmImg border-0">-->
<!--                        <a :href="'/eshop/product/'+item.slug" class="d-flex" style="height: 320px"><img class="card-img-top pb-0 item mx-auto"-->
<!--                                                                    v-lazy="item.photo.thumb_path"/></a>-->
<!--                        &lt;!&ndash;                    TODO: přidat BE tagů&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <span class="tag-wrapper"><span class="tag">Doprava zdarma</span></span>&ndash;&gt;-->
<!--                        <div class="d-flex" v-if="user">-->
<!--                            <svg xmlns="http://www.w3.org/2000/svg" v-if="favorites[item.id] === undefined"-->
<!--                                 width="16"-->
<!--                                 height="16"-->
<!--                                 fill="currentColor"-->
<!--                                 class="bi bi-heart ml-auto mb-auto"-->
<!--                                 viewBox="0 0 16 16"-->
<!--                                 @click="setFavourite(item)">-->
<!--                                <path-->
<!--                                    d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>-->
<!--                            </svg>-->
<!--                            <svg xmlns="http://www.w3.org/2000/svg" v-else-->
<!--                                 width="16"-->
<!--                                 height="16"-->
<!--                                 fill="red"-->
<!--                                 class="bi bi-heart bi-heart-fill ml-auto mb-auto"-->
<!--                                 viewBox="0 0 16 16"-->
<!--                                 @click="setFavourite(item)">-->
<!--                                <path-->
<!--                                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                        <div class="card-body text-center d-flex flex-grow-0 mt-auto p-0">-->
<!--                            <div class="mt-auto mx-auto">-->
<!--                                <h5><b>{{ item.name }}</b></h5>-->
<!--                                <h6>{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</h6>-->
<!--                                <a :href="'/eshop/product/'+item.slug" class="btn btn-primary btn-detail mx-auto">Detail</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->

<script>
import {useToast} from "vue-toastification";

export default {
    name: "EshopIndexComponent",
    components: {},
    props: {
        categories: Array,
        items: Array,
        _favorites: Array,
    },
    data: () => {
        return {
            scrollPosition: null,
            dropped: [],
            favorites: []
        }
    },
    computed: {
        user() {
            return window.auth_user !== null;
        },
        currency(){
            return window._currency;
        },
        locale(){
            return window._locale;
        }
    },
    mounted() {
        this.favorites = this._favorites;
        window.addEventListener('scroll', this.updateScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.updateScroll)
    },
    setup() {
        const toast = useToast();
        return {toast}
    },
    methods: {

        setFavourite(item) {
            axios.post('/customer/favourites/' + item.id + "/set").catch(err => {
                this.toast.error(err.response.data.message)
            }).then(res => {
                if (res.data.success) {
                    this.toast.success(res.data.message)
                    this.favorites = res.data.favorites;
                }
            })
        },
        updateScroll() {
            this.scrollPosition = window.scrollY
        },
        categoryUpdate(index) {
            if (this.dropped.includes(index)) {
                this.dropped = this.dropped.filter(function (ele) {
                    return ele != index;
                });
            } else {
                this.dropped.push(index);
            }
        },
    }
}
</script>

<style scoped>
.bi-heart {
    position: absolute;
    top: 7.5%;
    right: 10%;
    cursor: pointer;
}
</style>
