<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card log-box auth">
                    <div class="card-header">
                        <h3 class="m-0">{{ this._collection ? 'Upravit popup' : 'Vytvořit popup' }}</h3>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="save()" method="POST" enctype="multipart/form-data">
                            <div class="form-group">
                                <label for="name">
                                    Název
                                </label>
                                <input id="name" type="text" v-model="popup.name" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="name">
                                    Název anglicky
                                </label>
                                <input id="name_en" type="text" v-model="popup.en_name" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label>Popis</label>
                                <vue-editor v-model="popup.description" style="height: 500px; border-color: black;"
                                            class="pb-md-5 border" :editorToolbar="toolbar">
                                </vue-editor>
                            </div>
                            <div class="form-group">
                                <label>Popis anglicky</label>
                                <vue-editor v-model="popup.en_description" style="height: 500px; border-color: black;"
                                            class="pb-md-5 border"  :editorToolbar="toolbar">
                                </vue-editor>
                            </div>
                            <input type="submit" value="Uložit" class="btn btn-primary">
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {VueEditor} from "vue3-editor";
import Swal from "sweetalert2";
export default {
    name: "Popup",
    components: {
        VueEditor
    },
    props: {
        _popup: Object
    },
    data: () => ({
        toolbar: [
            [{'header': [false, 1,2,3,4,5]}],
            ["bold", "italic", "underline"],
            [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" }
            ],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
        popup: {
            id: 0,
            name: "",
            en_name: "",
            description: "",
            en_description: "",
        }
    }),
    mounted() {
        if (this._popup) this.setPopup()
    },
    methods: {
        setPopup() {
            for (const i in this.popup) this.popup[i] = this._popup[i]
        },
        save() {
            if (this.popup.id > 0) {
                axios.put("/admin/popups/" + this.popup.id, {
                    popup: this.popup
                }).then(res => {
                    Swal.fire("Popup upraven", "", "success").then(result => {
                        if(result.isDismissed || result.isConfirmed)location.reload()
                    })
                }).catch(err => {
                    Swal.fire("Popup se nepodařilo upravit", "", "error")
                })
            } else {
                axios.post('/admin/popups/create', {
                    popup: this.popup
                }).then(res => {
                    Swal.fire("Popup vytvořen", "", "success").then(result => {
                        if(result.isDismissed || result.isConfirmed) window.location.href = res.data.location
                    })
                }).catch(err => {
                    Swal.fire("Popup se nepodařilo vytvořit", "", "error")
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
