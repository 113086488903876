<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-6">
                <div class="card log-box auth">
                    <div class="card-header">
                        <h3 class="mb-0">{{ param.name }}</h3>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="save">
                            <div class="form-group">
                                <label for="name">
                                    Název
                                </label>
                                <input id="name" type="text" v-model="param.name" class="form-control" required/>
                                <button @click.prevent="addValue"
                                    class="btn btn-info my-2 p-2"
                                    style="text-transform: none; width: 30%">
                                    Přidat hodnotu</button>
                            </div>
                            <div class="form-group mb-0">
                                <div class="list-group" v-for="(v, index) in param.values">
                                    <div class="input-group mb-3 param-value">
                                        <div class="input-group-prepend param-value" :id="'btn'+index">
                                            <button class="btn btn-outline-secondary m-0 border-black" @click="move(-1, index)" type="button" v-html="up" v-if="index > 0"></button>
                                            <button class="btn btn-outline-secondary m-0 border-black" @click="move(1, index)" type="button" v-html="down" v-if="index != param.values.length - 1"></button>
                                        </div>
                                        <input type="text" :id="index" class="form-control" v-model="param.values[index].value" :aria-describedby="'btn'+index"/>
                                        <div class="input-group-append">
                                            <button class="btn btn-danger input-group-append param-value my-0" @click="del(index)" type="button">Odstranit</button>
                                            <span class="input-group-text param-value">Hodnota {{index + 1}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input type="submit" value="Uložit" class="btn btn-primary m-0">
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    name: "ParameterEdit",
    props: {
        parameter: Object
    },
    data: ()=>{
        return {
            param: Object,
            up: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">\n' +
                '  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>\n' +
                '</svg>',
            down: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">\n' +
                '  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>\n' +
                '</svg>'
        }
    },
    mounted() {
        this.param = this.parameter;
    },
    methods: {
        async del(index){
            if(this.param.values.length == 1){
                Swal.fire({
                    icon: "error",
                    title: "Parametr musí obsahovat nějakého hodnoty",
                })
                return
            } else {
                axios.post('/admin/parameters/'+this.param.slug+"/delete/"+this.param.values[index].id).then(res => {
                    this.param.values = res.data.values
                })
            }
        },
        async move(v, index){
            if(v == -1){
                this.param.values[index - 1].position += 1;
                this.param.values[index].position -= 1;
            } else {
                this.param.values[index].position += 1;
                this.param.values[index + 1]. position -= 1;
            }
            await this.param.values.sort((a, b)=>{
                return a.position - b.position;
            })
        },
        addValue(){
            let position = 1;
            if(this.param.values.length > 0){
                position += this.param.values[this.param.values.length - 1].position
            }

            let value = {value: null, position: position}
            this.param.values.push(value)
        },
        save(){
            axios.put('/admin/parameters/'+this.param.slug+'/edit', this.param).catch(err => {
                Swal.fire({
                    title: 'Parametr se nepodařilo upravit.',
                    text: err.response.data.message,
                    icon: 'error'
                })
            }).then(res => {
                Swal.fire({
                    title: 'Parametr byl upraven',
                    icon: 'success',
                    timer: 5000,
                    timerProgressBar: true
                }).then(result => {
                    if(result.isConfirmed || result.isDismissed){
                        console.log(res)
                        location.reload()
                    }
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
