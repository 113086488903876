/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import __ from "./trans";
import calculatePrice from "./currency";

require('./bootstrap');
const {createApp} = require("vue");
import Notifications from '@kyvg/vue3-notification'
import axios from "axios";
import {createStore} from "vuex";
import VueCookies from 'vue3-cookies';
import Packeta from './packeta';
import Balikovna from "./balikovna"
import Toast, {useToast} from "vue-toastification";
import "vue-toastification/dist/index.css";
import {createWebHistory, createRouter} from "vue-router";
import VueLazyLoad from 'vue3-lazyload'
window.Vue = require('vue');

const app = createApp({

    async beforeCreate() {
        this.$store.state.updating = true
        this.$store.state.cart = (await axios.get('/api/eshop/cart')).data
        this.$store.state.updating = false
    },
    mounted(){
        if(location.pathname.includes("/eshop") || location.pathname.includes("/customer")){
            setInterval(async ()=>{
               if(!document.hidden){ //Just prevent spamming api when tab is hidden
                   this.$store.state.cart = (await axios.get('/api/eshop/cart')).data
               }
            }, 4000);
        }
    },
    computed: {
        locale(){
            return window._locale
        }
    }
})
const store = createStore({
    state: {
        cart: {items: [], giftcards: [], id: null},
        updating: false
    },
})
app.mixin(__);
app.use(store)
app.mixin(calculatePrice);
app.use(createRouter({history: createWebHistory(), routes: []}))
app.use(Notifications)
app.use(VueCookies, {
    expiresTimes: '7d',
    path: "/",
    domain: "",
    secure: true
})
app.use(Packeta);
app.use(Balikovna);
app.use(Toast)
app.use(VueLazyLoad, {
    loading: '../../img/Rolling-1s-200px.svg',
    error: '',
})
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => app.component(key.split('/').pop().split('.')[0], files(key).default))

app.mount("#app");




