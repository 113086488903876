<template>
    <div class="nav-item my-auto" v-if="cart">
        <a href="/eshop/checkout"
           class="nav-link btn btn-default btn-sm my-0">
                <span class="glyphicon
                    glyphicon-shopping-cart">
                </span>
            <div class="cart cart-popup" @mouseover="displayOn()">
                <i class="fas fa-shopping-cart"></i>
                <span class="count">{{ cart.count }}</span>
                <div class="items-list" :class="hovering ? 'd-block' : 'cart-hide'" style="z-index: 9999" v-if="wWidth > 992">
                    <div class="item" v-if="cart.items.length > 0" v-for="i in cart.items">
                        <div class="row" >
                            <div class="col-4 pl-0">
                                <div class="item-pic">
                                    <img :src="i.photo" class="img-fluid" alt="product">
                                </div>
                            </div>
                            <div class="col-8 pl-0 pr-0 text-left" >
                                <p class="item-name">{{ locale == 'en'?i.en_name:i.name }}</p>
                                <h6 class="item-price text-green">{{calculatePrice(i.price, currency)}} {{currency.symbol}}</h6>
                                <p class="small item-desc">{{__('Velikost')}}: {{i.size.name}}<br>
                                    {{__('Množství')}}: {{ i.count }} {{__('ks')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="item" v-if="cart.giftcards.length > 0" v-for="i in cart.giftcards">
                        <div class="row" >
                            <div class="col-4 pl-0">
                                <div class="item-pic">
                                    <img src="/images/giftCard.png" class="img-fluid" alt="product">
                                </div>
                            </div>
                            <div class="col-8 pl-0 pr-0 text-left" >
                                <p class="item-name">{{ locale == 'en'?i.en_name:i.name }}</p>
                                <h6 class="item-price text-green">{{calculatePrice(i.price, currency)}} {{currency.symbol}}</h6>
                                <p class="small item-desc">
                                    {{__('Množství')}}: {{ i.count }} {{__('ks')}}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="cart.giftcards.length <1 && cart.items.length < 1" class="item">
                        <strong>{{ __('Váš nákupní košík je prázdný.') }}</strong>
                    </div>
                    <div class="add-to-cart">
                        <a class="btn btn-primary" style="font-size: 60%">{{__('Pokračovat na pokladnu')}}</a>
                    </div>

                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "BasketComponent",
    data: () => {
        return {
            hovering: false,
            wWidth: null
        }
    },
    computed:{
        currency() {
            return window._currency;
        },
        cart(){
            return this.$store.state.cart;
        },
        locale(){
            return window._locale;
        }
    },
    methods: {
        displayOn(){
            this.hovering = true;
            setTimeout(() => this.hovering = false, 2000);
        }
    },
    mounted() {
        this.wWidth = window.innerWidth;
    }

}
</script>

<style scoped>

</style>
