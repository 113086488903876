<template>
    <div class="container">
        <div class=" justify-content-center">
            <div class="card log-box auth">
                <div class="catHead card-header">
                    <h3 class="m-0">{{this._event?'Upravit akci':'Vytvořit akci'}}</h3>
                    <button v-if="_event" class="btn btn-sm btn-primary" @click="addToBanners()">Přidat jako banner na hlavní stránku</button>
                </div>
                <div class="card-body">
                    <form @submit.prevent="save()" method="POST"  enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name">
                                        Název
                                    </label>
                                    <input id="name" type="text" v-model="event.name" class="form-control" required/>
                                </div>
                                <div class="form-group">
                                    <label for="name">
                                        Název anglicky
                                    </label>
                                    <input id="name_en" type="text" v-model="event.en_name" class="form-control" required/>
                                </div>
                                <div class="form-check mb-3" v-if="event.id > 0">
                                    <input type="checkbox" v-model="event.visible" class="form-check-input"/>
                                    <label class="form-check-label">Viditelná</label>
                                </div>
                                <div class="form-group">
                                    <label>Popis</label>
                                    <vue-editor v-model="event.description" style="height: 500px; border-color: black;" class="pb-md-5 border" :editorToolbar="toolbar">
                                    </vue-editor>
                                </div>
                                <div class="form-group">
                                    <label>Popis anglicky</label>
                                    <vue-editor v-model="event.en_description" style="height: 500px; border-color: black;" class="pb-md-5 border" :editorToolbar="toolbar">
                                    </vue-editor>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <h5>Banner</h5>
                                    <input type="file" class="form-control-file" @change="getImg($event.target.files[0])" accept="image/*"/>
                                    <img :src="event.photo_path" class="img-fluid"/>
                                </div>
                                <div class="form-group">
                                    <h5>Produkty</h5>
                                    <Multiselect :options="items" :label="'name'" :searchable="true"
                                                 :valueProp="'id'" :trackBy="'id'" :placeholder="'Vyberte položky'"
                                                 :object="true"
                                                 :noOptionsText="'Seznam produktů je prázdný'"
                                                 :noResultsText="'Žádný odpovídající produkt nebyl nalezen.'"
                                                 :option-height="104" @select="selectItem"
                                                 class="mb-3"
                                    ></Multiselect>
                                    <div class="media" v-for="item in event.items">
                                        <div class="media-body">
                                            <h5 class="mt-0">
                                                {{item.name}}
                                            </h5>
                                            <div class="input-group">
                                                <input type="number" min="1" :max="item.price" class="form-control" v-model="item.discounted_price_dph" placeholder="Akční cena" aria-describedby="button-addon2">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">max {{item.price_dph}} Kč</span>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger" @click="deleteItem(item)">Odstranit</button>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <input type="submit" value="Uložit" class="btn btn-primary">
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {VueEditor} from "vue3-editor"
import Multiselect from '@vueform/multiselect'
import Swal from "sweetalert2";
export default {
    name: "Event",
    components: {
        VueEditor,
        Multiselect
    },
    props: {
        _event: Object
    },
    data: () => ({
        event: {
            id: 0,
            en_name: "",
            name: "",
            description: "",
            en_description: "",
            photo_path: "",
            visible: false,
            items: [],
            deleted: []
        },
        items: [],
        toolbar: [
            [{'header': [false, 1,2,3,4,5]}],
            ["bold", "italic", "underline"],
            [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" }
            ],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
    }),
    mounted() {
        this.getItems()
        if(this._event)this.setEvent()
    },
    methods: {
        addToBanners(){
            axios.post('/admin/events/'+this.event.id+'/banner').then(res => {
                if(res.data.success)Swal.fire("Akce byla přidána mezi bannery", "", "success").then(result => {
                    if(result.isDismissed || result.isConfirmed)location.reload()
                })
            }).catch(err => Swal.fire(err.response.data.message, "", "error"))
        },
        setEvent() {
            for(const i in this.event)this.event[i] = this._event[i];
            this.event.visible = Boolean(this._event.visible)
            this.event.deleted = [];
        },
        save() {
            if(this.event.id > 0){
                axios.put('/admin/events/'+this.event.id, {
                    event: this.event
                }).then(res => {
                    if(res.data.success)Swal.fire("Akce upravena", "", "success").then(result => {
                        if(result.isDismissed || result.isConfirmed)location.reload()
                    })
                }).catch(err => Swal.fire("Akci se nepodařilo upravit", "", "error"))
            }else {
                axios.post('/admin/events/create', {
                    event: this.event
                }).then(res => {
                    if(res.data.success)Swal.fire("Akce vytvořena", "", "success").then(result => {
                        if(result.isDismissed || result.isConfirmed)window.location.href = res.data.location
                    })
                }).catch(err => Swal.fire("Akci se nepodařilo vytvořit", "", "error"))
            }
        },
        getImg(img) {
            const file = img;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.event.photo_path = e.target.result
            }
        },
        selectItem(item) {
            if(!this.event.items.filter(e => e.id == item.id).length > 0){
                this.event.items.push(item)
            }
        },
        deleteItem(item) {
            if(this._event?.items.filter(e => e.id == item.id).length > 0){
                this.event.deleted.push(item)
            }
            this.event.items.splice(this.event.items.findIndex(i => i.id == item.id), 1)
        },
        async getItems() {
            this.items = (await axios.get('/api/items')).data
        }
    }
}
</script>

<style scoped>

</style>
