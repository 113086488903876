<template>
    <div class="modal fade" id="popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" v-if="loaded">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <div class="modal-title" id="exampleModalLongTitle">
                        {{ locale == 'en'?_popup.en_name:_popup.name }}
                    </div>
                    <button type="button" class="close" @click="closeModal" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" v-if="locale == 'en'" v-html="_popup.en_description">
                </div>
                <div class="modal-body" v-else v-html="_popup.description">
                </div>
                <div class="modal-footer border-0">
                    <button type="button"  @click="closeModal" class="btn m-0" style="background-color: #262626; color: white; font-family: 'Barlow Condensed', sans-serif" data-dismiss="modal">{{ __('Pokračovat') }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show" id="backdrop" style="display: none;"></div>

</template>

<script>
export default {
    name: "PopupModal",
    props: ["_popup"],
    data: ()=>({
        loaded: false
    }),
    mounted() {
        window.addEventListener("load", ev=> {
            if(this._popup){
                this.loaded = true
                setTimeout(()=>{this.clickModal()}, 200);
            }
        })
    },
    computed:{
        locale() {
            return window._locale
        }
    },
    methods: {
        clickModal(){
            let modal = document.getElementById("popup")
            document.getElementById("backdrop").style.display = "block"
            modal.style.display = "block";
            modal.classList.add("show")
        },
        closeModal(){
            let modal = document.getElementById("popup")
            document.getElementById("backdrop").style.display = "none"
            modal.style.display = "none"
            modal.classList.remove("show")
        }
    }
}
</script>

<style scoped>

</style>
