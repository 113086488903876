<template>
    <div id="collections">
        <div class="container">
            <h1 class="title border-bottom">{{__('Slevy')}}</h1>
            <div class="col-12 p-0">
                <div class="group-container" v-for="sale in _sales">
                    <a :href="'/slevy/' + sale.slug" style="position: absolute; height: 100%; width: 100%"></a>
                    <div class="position-absolute">
                        <h2 class="group-title" v-if="locale == 'en'">{{sale.en_name}}</h2>
                        <h2 class="group-title" v-else>{{sale.name}}</h2>
                        <div class="group-desc" v-if="locale == 'en'">{{ stripHtml(sale.en_description)}}</div>
                        <div class="group-desc" v-else>{{ stripHtml(sale.description)}}</div>
                    </div>
                    <a class="collection-btn" :href="'/slevy/' + sale.slug">{{__('Zobrazit slevy')}}</a>
                    <img class="group-img" :src="sale.photo_path" :alt="sale.slug">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SalesPage",
    props: ["_sales"],
    methods: {
        stripHtml(html)
        {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        }
    },
    computed: {
        locale(){
            return window._locale
        }
    }
}
</script>

<style scoped>

</style>
