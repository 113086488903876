module.exports = {
    methods: {
        /**
         *
         * @param {number} price
         * @param {id: number, name: string, rate: number} currency
         * @param {boolean }vat
         * @param {id: number, name: string, vat_rate: number} country
         * @returns {string}
         */
        calculatePrice(price, currency, vat = true, country = null) {
            country == null? country = window._country: null
            let total_price = 0
            if(vat) total_price = price*(1+(country.vat_rate/100));
            else total_price = price
            return Math.fround(parseFloat( total_price/ currency.rate)).toFixed(2);
        }
    }
}
