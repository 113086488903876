<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card log-box auth">
                    <div class="card-header row">
                        <h5 class="mb-0 mr-auto">Upravit položku</h5>
                        <a :href="'/eshop/product/'+item.slug" class="btn ml-auto" style="color: #38c172">Zobrazit v
                            eshopu</a>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="save">
                            <div class="form-group">
                                <label for="name">
                                    Název
                                </label>
                                <input id="name" v-model="item.name" type="text" name="name" class="form-control"
                                       required/>
                            </div>
                            <div class="form-group">
                                <label for="en_name">
                                    Název anglicky
                                </label>
                                <input id="en_name" type="text" v-model="item.en_name" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="category">Kategorie</label>
                                <select id="category" class="custom-select" name="category" v-model="item.category_id"
                                        v-if="_item.mother_item_id == null">
                                    <option v-for="category in _categories" :value="category.id">
                                        {{ category.name }}
                                    </option>
                                </select>
                                <select id="category" disabled v-else class="custom-select" v-model="item.category_id">
                                    <option :value="_category.id">
                                        {{ _category.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="price">
                                    Cena s DPH
                                </label>
                                <input id="price" v-model="item.price_dph" type="text" name="price" class="form-control"
                                       required/>
                            </div>
                            <div class="form-group">
                                <label for="price">
                                    Akční cena s DPH
                                </label>
                                <input id="price" type="number" v-model="item.discounted_price_dph" class="form-control" required/>
                            </div>
                            <div class="form-group">
                                <label for="description">
                                    Český popis
                                </label>
                                <textarea id="description" v-model="item.description" type="text" class="form-control"
                                          name="description"
                                          spellcheck="false"
                                          required></textarea>
                            </div>
                            <div class="form-group">
                                <label for="description">
                                    Anglický popis
                                </label>
                                <textarea id="description_en" v-model="item.description_en" type="text"
                                          class="form-control"
                                          name="description"
                                          required></textarea>
                            </div>
                            <div class="form-group">
                                <div class="float-right flex-row d-flex">
                                    <label for="highlight" class="mb-0">
                                        Zobrazit na první stránce
                                    </label>

                                    <input id="highlight" v-model="item.is_highlighted" type="checkbox"
                                           class="ml-2 my-auto form-check"
                                           name="is_highlighted"/>
                                </div>

                            </div>
                            <div class="form-group">
                                <div class="float-right flex-row d-flex">
                                    <label for="stock" class="mb-0">
                                        Je skladem
                                    </label>

                                    <input id="stock" v-model="item.is_stocked" type="checkbox"
                                           class="ml-2 my-auto form-check"
                                           name="is_stocked"/>
                                </div>
                            </div>

                            <div class="form-group" v-if="item.mother_item_id">
                                <label>
                                    Barva
                                </label>
                                <input type="color" class="form-control form-control-color" v-model="item.color"/>
                            </div>
                            <div class="form-group">
                                <strong>Velikosti</strong>
                                <Multiselect v-model="selected_sizes" :options="_sizes" :label="'name'"
                                             :searchable="true"
                                             :mode="'tags'"
                                             :valueProp="'id'" :trackBy="'name'" :placeholder="'Vyberte velikost'"
                                             :object="true"
                                             :noOptionsText="'Seznam velikostí je prázdný'"
                                             :noResultsText="'Žádná odpovídající velikost nebyla nalezena.'"
                                             @deselect="deselectSize"
                                ></Multiselect>
                            </div>
                            <div class="form-group">
                                <strong>Velikostní tabulka</strong>
                                <Multiselect v-model="selected_table" :options="_tables" :label="'name'"
                                             :searchable="true"
                                             :valueProp="'id'" :trackBy="'name'"
                                             :placeholder="'Vyberte velikostní tabulka'"
                                             :object="true"
                                             :noOptionsText="'Seznam velikostních tabulek je prázdný'"
                                             :noResultsText="'Žádná odpovídající velikostní tabulka nebyla nalezena.'"
                                             @clear="deselectTable"
                                ></Multiselect>
                            </div>
                            <a v-if="_selected_table == null" class="btn btn-primary btn-sm m-0 d-inline-block mb-3 text-center" style="margin-top: -0.5rem !important;" @click.prevent="addManualTable">Vytvořit tabulku pouze pro produkt</a>


                            <div class="form-group">
                                <strong>Štítky</strong>
                                <Multiselect v-model="selected_tags" :options="_tags" :label="'name'" :searchable="true"
                                             :mode="'tags'"
                                             :valueProp="'id'" :trackBy="'name'" :placeholder="'Vyberte štítek'"
                                             :object="true"
                                             :noOptionsText="'Seznam štítků je prázdný'"
                                             :noResultsText="'Žádný odpovídající štítek nebyl nalezen.'"
                                             @select="select" @deselect="deselect"
                                ></Multiselect>
                            </div>
                            <strong v-if="_parameters.length > 0">Parametry</strong>
                            <div class="form-group" v-for="p in _parameters">
                                <label>{{ p.name }}</label>
                                <Multiselect v-model="selected_parameters[p.name]" :options="p.values" :label="'value'"
                                             :searchable="true"
                                             :mode="'tags'"
                                             :valueProp="'id'" :trackBy="'value'" :placeholder="'Vyberte parametr'"
                                             :object="true"
                                             :noOptionsText="'Seznam parametrů je prázdný'"
                                             :noResultsText="'Žádný odpovídající parametr nebyl nalezen.'"
                                             @deselect="deselectParam"
                                ></Multiselect>
                            </div>
                            <strong>Mohlo by se hodit</strong>
                            <div class="form-group">
                                <Multiselect v-model="recommended" :options="_items" :label="'name'"
                                             :searchable="true"
                                             :mode="'tags'"
                                             :valueProp="'id'" :trackBy="'name'"
                                             :placeholder="'Vyberte doporučený produkt'"
                                             :object="true"
                                             :noOptionsText="'Seznam doporučených produktů je prázdný'"
                                             :noResultsText="'Žádný odpovídající produkt nebyl nalezen.'"
                                             @deselect="unsetRecommended"
                                ></Multiselect>
                            </div>
                            <input type="submit" value="Uložit" class="btn btn-primary m-0">
                        </form>
                    </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class="card log-box auth">
                    <div class="card-header">
                        <h5 class="mb-0">Fotografie</h5>
                    </div>
                    <div class="card-body row">
                        <div v-for="(photo, index) in photos" :class="[index === 0 ? 'col-sm-12' : 'col-sm-4']">
                            <div v-if="index == 0" class="col-12 d-flex flex-column">
                                <img :src="photo.path" class="img-fluid mx-auto" v-if="photo.path">
                            </div>
                            <div v-else style="height: 180px; width: 150px; display: flex">
                                <img :src="photo.path" class="productImageSm" v-if="photo.path"/>
                            </div>
                            <div class="d-flex" :class="[index == 0 ? 'border-bottom pb-1 my-2' : 'my-2' ]">
                                <div v-if="index == 0 && index == photos.length - 1"
                                     style="width: 34px; margin-right: auto"></div>
                                <button class="btn btn-sm" v-html="left" v-if="index != 0"
                                        @click="move(index,-1)"></button>
                                <button class="btn btn-sm mr-auto" v-html="right" v-if="index != photos.length - 1"
                                        @click="move(index, 1)"></button>
                                <div v-if="index == 0" class="mx-auto my-auto">Hlavní fotografie</div>
                                <button class="btn btn-danger btn-sm ml-auto" v-html="del"
                                        @click="deletePhoto(index)"></button>
                            </div>
                        </div>
                        <input v-if="photos.length < 11" type="file" class="form-control-file ml-4 mt-4"
                               accept="image/*" multiple @change="setPhoto($event.target.files)"/>
                    </div>
                </div>
                <div class="card mt-4 log-box auth">
                    <div class="card-header">
                        Fotografie s popisky
                    </div>
                    <div class="card-body">
                        <div v-for="(photo, index) in description_photos" class="d-flex flex-column">
                            <div class="col-sm-12 p-0 d-flex" v-if="photo.path.includes('/storage/photos')">
                                <img :src="photo.path" class="img-fluid mx-auto" v-if="photo.path">

                            </div>
                            <div v-else-if="photo.path.length > 0" class="col-sm-12 p-0 d-flex">
                                <img v-if="photo.url" :src="photo.url" class="img-fluid mx-auto"/>

                            </div>
                            <div class="row">
                                <input type="file" accept="image/*" @click="selectDescPhotoId(index)"
                                       @change="selectDescPhoto"
                                       :name="'photo'+index" class="form-control-file w-75 pl-3" required>
                                <button v-if="photo.path" class="btn btn-danger btn-sm ml-auto" v-html="del"
                                        @click="deselectDescPhoto(index)"></button>
                            </div>

                            <label>Český popis:</label>
                            <div class="col-sm-12 p-0 mb-3">
                                <vue-editor v-model="description_photos[index].description"
                                            :editorToolbar="toolbar"></vue-editor>
                            </div>
                            <label>Anglický popis:</label>
                            <div class="col-sm-12 p-0 mb-3">
                                <vue-editor v-model="description_photos[index].description_en"
                                            :editorToolbar="toolbar_en"></vue-editor>
                            </div>
                            <button class="btn btn-danger btn-sm ml-auto mt-2" v-html="del"
                                    @click="deleteDescPhoto(index)"></button>

                        </div>
                        <div>
                            <button @click="newDescPhoto" class="btn btn-info p-3 my-2">Přidat popis</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-12 log-box auth mx-3 mt-4 p-3">
            <h3>
                Recenze
            </h3>
            <table class="table table-hover">
                <thead>
                <tr>
                    <td>ID</td>
                    <td>Název</td>
                    <td>Uživatel</td>
                    <td>Schváleno</td>
                    <td>Akce</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="review in reviews" :key="review.id">
                    <td>{{ review.id }}</td>
                    <td>{{ review.name }}</td>
                    <td>{{ review.user.first_name + " " + review.user.last_name }}</td>
                    <td>{{ (review.accepted) ? 'Schváleno' : 'Neschváleno' }}</td>
                    <td>
                        <button class="btn btn-primary m-0 px-4 py-2 h-100" @click="showReview(review)">Ukázat</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import Swal from 'sweetalert2'
import {VueEditor} from "vue3-editor";

export default {
    name: "EditItem",
    components: {Multiselect, VueEditor},
    props: {
        _item: Object,
        _tags: Array,
        _selected_tags: Array,
        _categories: Array,
        _reviews: Array,
        _photos: Array,
        _description_photos: Array,
        _parameters: Array,
        _selected_parameters: Array,
        _category: Object,
        _recommended: Array,
        _items: Array,
        _sizes: Array,
        _selected_sizes: Array,
        _tables: Array,
        _selected_table: Object
    },
    data: () => {
        return {
            recommended: [],
            toolbar: [
                ["bold", "italic", "underline"],
                [{list: "ordered"}, {list: "bullet"}],
                ["image", "code-block"]
            ],
            toolbar_en: [
                ["bold", "italic", "underline"],
                [{list: "ordered"}, {list: "bullet"}],
                ["image", "code-block"]
            ],
            del: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">\n' +
                '  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>\n' +
                '</svg>',
            photos: Array,
            description_photos: Array,
            reviews: [],
            item: Object,
            selected_tags: [],
            selected_parameters: {},
            fill_star: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">\n' +
                '  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>\n' +
                '</svg>',
            star: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">\n' +
                '  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>\n' +
                '</svg>',
            left: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">\n' +
                '  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>\n' +
                '</svg>',
            right: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">\n' +
                '  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>\n' +
                '</svg>',
            deleted: [],
            deleted_description: [],
            changed: false,
            selected_photo_id: 0,
            selected_sizes: [],
            selected_table: null
        }
    },
    async mounted() {
        let sets = ["photos", "description_photos", "reviews", "item", "selected_tags", "recommended", "selected_sizes", "selected_table"];
        for (const i in sets) this[sets[i]] = this["_" + sets[i]]
        this.item.is_highlighted = Boolean(this.item.is_highlighted)
        this.item.is_stocked = Boolean(this.item.is_stocked)
        for (const i in this._parameters) {
            if (this._selected_parameters[this._parameters[i].name].length == 0) {
                this.selected_parameters[this._parameters[i].name] = []
            } else {
                this.selected_parameters[this._parameters[i].name] = this._selected_parameters[this._parameters[i].name]
            }

        }

        window.addEventListener('beforeunload', e => {

            if (this.changed) {
                e.preventDefault()
                e.returnValue = ''

            }
        });
    },
    methods: {
        addManualTable(){
          axios.post('/admin/sizes/tables/create/manual', {
              name: "Velikostní tabulka k produktu "+this._item.name,
              item_id: this._item.id
          }).then(res => window.location.href = res.data.location)
        },
        deselectTable() {
            axios.post('/admin/items/' + this.item.id + '/tables/unset').catch(err => Swal.fire("Nepodařilo se odebrat velikostní tabulku", "", "error"))

        },
        deselectSize(size) {
            if (this._selected_sizes.filter(s => s.id == size.id).length > 0) {
                axios.post('/admin/items/' + this.item.id + '/sizes/' + size.id + '/unset').catch(err => Swal.fire("Nepodařilo se odebrat velikost", "", "error"))
            }

        },
        unsetRecommended(item) {
            axios.post('/admin/items/' + this.item.id + '/recommended/' + item.id + '/unset').catch(err => Swal.fire("Nepodařilo se odebrat doporučený předmět", "", "error"))
        },
        async deletePhoto(index) {
            if (this.photos[index].hasOwnProperty('id')) {
                this.deleted.push(this.photos[index]);
            }
            if (index < this.photos.length - 1) {
                for (let i = index; i < this.photos.length; i++) {
                    if (i != index) {
                        this.photos[i].position -= 1;
                    }
                }
            }

            this.photos.splice(index, 1);
            await this.photos.sort((a, b) => {
                return a.position - b.position;
            })
            this.changed = true;

        },
        showReview(review) {

            Swal.fire({
                title: review.name,
                html:
                    '<div class="swal2-textarea">' + review.content + '</div>',
                confirmButtonText: 'Schválit',
                confirmButtonColor: 'green',
                denyButtonColor: 'red',
                denyButtonText: 'Neschválit',
                showDenyButton: true,
            }).then(result => {
                if (result.isConfirmed) {
                    axios.put('/admin/reviews/' + review.id + '/accept').then(res => {
                        if (res.data.success) {
                            Swal.fire({title: res.data.message, icon: 'success'})
                        }
                    })
                }
                if (result.isDenied) {
                    axios.put('/admin/reviews/' + review.id + '/deny').then(res => {
                        if (res.data.success) {
                            Swal.fire({title: res.data.message, icon: 'success'})
                        }
                    })
                }
                axios.get('/admin/reviews/list/' + this.item.id).then(res => this.reviews = res.data)
            })
        },
        async move(index, v) {
            if (v == -1) {
                this.photos[index - 1].position += 1;
                this.photos[index].position -= 1;
            } else {
                this.photos[index].position += 1;
                this.photos[index + 1].position -= 1;
            }
            await this.photos.sort((a, b) => {
                return a.position - b.position;
            })
            this.changed = true;
        },
        setPhoto(images) {
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    let img = images.item(i)
                    if (!this.exists(img)) {
                        break;
                    }
                    let position = 1;
                    if (this.photos.length > 0) {
                        position = this.photos[this.photos.length - 1].position + 1;
                    } else {
                        position = i + 1;
                    }
                    let reader = new FileReader();
                    reader.readAsDataURL(img);
                    reader.onload = async () => {
                        await this.photos.push({
                            name: img.name,
                            path: reader.result,
                            item_id: this.item.id,
                            position: position
                        })
                    }
                }
            }
            this.changed = true;
        },
        selectDescPhoto(e, desc) {
            console.log(this.selected_photo_id);
            const file = e.target.files[0];
            this.description_photos[this.selected_photo_id].url = URL.createObjectURL(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.description_photos[this.selected_photo_id].path = e.target.result;
            };
        },
        selectDescPhotoId(key) {
            console.log("selected " + key)
            this.selected_photo_id = key;
        },
        newDescPhoto() {
            this.description_photos.push({
                path: "",
                item_id: this.item.id,
                description: ""
            })
        },
        deleteDescPhoto(key) {
            if (this.description_photos[key].hasOwnProperty('id')) {
                this.deleted_description.push(this.description_photos[key]);
            }
            this.description_photos = this.description_photos.filter((item, index) => index !== key)
        },
        deselectDescPhoto(index) {
            this.description_photos[index].path = "";
        },
        exists(img) {
            let exists = true
            this.photos.forEach(photo => {
                if (photo.name === img.name) {
                    console.log("Already exists.")
                    exists = false
                    return;
                }
            })
            return exists

        },
        async select(tag) {
            await axios.post('/admin/tags/' + this.item.id + '/set', {
                tag: tag
            }).catch(err => {
                Swal.fire({
                    title: 'Štítek se nepodařilo přiřadit.',
                    icon: 'error',
                    text: err.response.data.message,
                    confirmButtonColor: 'red',
                    confirmButtonText: 'OK'
                })
            })

        },
        async deselectParam(param) {
            let found = false;
            for (const i in this._selected_parameters) {
                if (this._selected_parameters[i].filter(p => p.id === param.id).length > 0) {
                    found = true;
                }
            }
            if (found) {
                await axios.post('/admin/parameters/' + this.item.id + '/remove', {parameter: param}).catch(err => {
                    Swal.fire({
                        title: 'Parametr se nepodařilo oddělat.',
                        icon: 'error',
                        text: err.response.data.message,
                        confirmButtonColor: 'red',
                        confirmButtonText: 'OK'
                    })
                })
            }
        },
        async deselect(tag) {
            await axios.post('/admin/tags/' + this.item.id + '/remove', {
                tag: tag
            }).catch(err => {
                Swal.fire({
                    title: 'Štítek se nepodařilo oddělat.',
                    icon: 'error',
                    text: err.response.data.message,
                    confirmButtonColor: 'red',
                    confirmButtonText: 'OK'
                })
            })
        },
        async save() {
            if (this.item.mother_item_id) {
                var axios_url = '/admin/items/' + this.item.mother_item_id + '/variants/' + this.item.id
            } else {
                var axios_url = '/admin/items/' + this.item.id + '/edit';
            }
            await axios.put(axios_url, {
                form: this.item,
                tags: this.selected_tags,
                photos: this.photos,
                description_photos: this.description_photos,
                deleted: this.deleted,
                deleted_description: this.deleted_description,
                params: this.selected_parameters,
                recommended: this.recommended,
                sizes: this.selected_sizes,
                table: this.selected_table
            }, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            }).catch(err => {
                Swal.fire({
                    title: 'Produkt se nepodařilo upravit, zkuste to prosím znovu.',
                    icon: 'error',
                    text: err.response.data.message,
                    confirmButtonColor: 'red',
                    confirmButtonText: 'OK'
                })

            }).then(res => {
                if (res.data.success) {
                    Swal.fire({
                        title: 'Produkt byl upraven.',
                        icon: 'success',
                        confirmButtonColor: 'green',
                        confirmButtonText: 'OK'
                    }).then(result => {
                        if (result.isConfirmed) {
                            this.changed = false
                            location.reload()
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.productImageSm {
    margin: auto;
    width: 100%;
    height: auto;
}
</style>
