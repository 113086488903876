<template>
    <ul v-for="cat in category.children" class="cat-list">
        <a :href="'/eshop/'+cat.slug" :class="'nav-link cat-'+cat_number">{{cat.name}}</a>
        <Subcategories :category="cat" :cat_number="cat_number+1" v-if="cat.children.length > 0"></Subcategories>
    </ul>
</template>

<script>
export default {
    name: "Subcategories",
    props: {
        category: Object,
        cat_number: Number
    }
}
</script>

<style scoped>

</style>
