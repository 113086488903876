<template>
    <div id="itemPage">
        <div class="container" v-if="!loading">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb my-5">
                    <li class="breadcrumb-item" v-for="p in categories" aria-current="page"><a
                        :href="'/eshop/' + p.slug">{{ locale == 'en'?p.en_name:p.name }}</a></li>
                    <li class="breadcrumb-item" v-for="p in path" aria-current="page"><a
                        :href="'/eshop/' + p.slug">{{ locale == 'en'?p.en_name:p.name }}</a></li>
                </ol>
            </nav>
            <!--FE-->
            <div class="row">
                <div class="col-12 pl-0 ml-0 col-md-1 d-none d-md-block flex-row">
                    <div v-for="(photo, index) in item.photos">
                        <img class="previewImg" v-lazy="photo.path" :id="index"
                             @click="clicked = photo.position" :alt="item.slug + '-' + photo.id"
                             :class="clicked == photo.position ? 'selected' : ''">
                    </div>
                    <!--                        další foto-->
                </div>

                <div class="col-12 col-md-1 d-block d-md-none">
                    <h1 class="item-name mb-0">{{ locale == 'en'?item.en_name:item.name }}</h1>
                    <div class="item-prices mb-0">
                        <div class="item-price d-inline-block item-card-sm" v-if="item.discounted_price_dph > 0">
                            <span class="obsolete">{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</span><br/>
                            <span class="discounted">{{ calculatePrice(price, currency) }} {{currency.symbol}}</span>
                        </div>
                        <div v-else class="item-price d-inline-block">
                            <span class="regular">{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</span>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-1 d-block d-md-none mb-3">
                    <div class="item-stars d-flex mb-1">
                        <a href="/">
                            <star-rating v-model:rating="item.rating" :read-only="true" :star-size="16"
                                         :show-rating="false"/>
                        </a>
                        <h6 class="item-price d-inline-block ml-auto my-auto">{{
                                calculatePrice(price, currency, false)
                            }}
                            {{ currency.symbol }} {{ __('bez DPH') }}</h6>
                    </div>

                </div>

                <div class="col-md-6 d-flex">
                    <div style="height: 100%; max-height: 700px; width: auto" class="mx-auto">
                    <img @load="loaded = true" :src="item.photos[0].path"
                         style="display: none; position: fixed" :alt="item.slug + '-' + item.photos[0].id">
                    <div v-if="item.photos" v-for="(photo, index) in item.photos">
                        <div
                             v-if="clicked == index + 1 && photo.path && this.loaded === true ">
                            <!--pic-box-->
                            <inner-image-zoom :src="photo.path" :zoomSrc="photo.path"
                                              fullscreenOnMobile="true" zoomType="click" style="height: 100%;
                                              max-height: 700px; width: auto"
                                              :alt="item.slug + '-' + photo.id" />

                        </div>

                    </div>
                    </div>
                </div>
                <div class="col-12 pl-0 ml-0 col-md-1 d-flex d-md-none flex-row previewContainer">
                    <div v-for="(photo, index) in item.photos">
                        <img class="previewImg" v-lazy="photo.path" :id="index"
                             @click="clicked = photo.position" :alt="item.slug + '-' + photo.id"
                             :class="clicked == photo.position ? 'selected' : ''">
                    </div>
                    <!--                        další foto-->
                </div>
                <div class="col-12 col-md-5 ml-auto">
                    <h1 class="item-name mb-0 d-none d-md-block">{{ locale == 'en'?item.en_name:item.name }}</h1>
                    <span class="badge badge-primary mr-1" v-for="tag in tags">
                    <span data-toggle="tooltip" data-placement="top"
                          :title="tag.comment"
                          v-if="tag.comment !== null">{{ tag.name }}</span>
                    <span v-else>{{ tag.name }}</span>
                </span>
                    <div class="item-prices d-none d-md-block">
                        <div class="item-price d-inline-block" v-if="item.discounted_price_dph > 0">
                            <span class="obsolete">{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</span><br/>
                            <span class="discounted">{{ calculatePrice(price, currency) }} {{currency.symbol}}</span>
                        </div>
                        <div v-else class="item-price d-inline-block">
                            <span class="regular">{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</span>
                        </div>
                    </div>
                    <div class="item-prices d-none d-md-block">
                        <h6 class="item-price d-inline-block">{{ calculatePrice(price, currency, false) }}
                            {{ currency.symbol }} {{ __('bez DPH') }}</h6>
                    </div>
                    <div class="item-stars d-none d-md-block">
                        <star-rating v-model:rating="item.rating" :read-only="true" :star-size="16"
                                     :show-rating="false"/>
                        <span class="font-weight-light"> (<a href="#reviews">{{ reviews.length }} {{
                                __('hodnocení')
                            }}</a>)</span>
                    </div>
                    <div class="row d-md-none">
                        <div class="col-3 px-0 pr-1">
                             <!-- další fotky-->
                        </div>
                    </div>
                    <div class="item-short-desc my-2" v-if="locale == 'en'">
                        <p>{{ item.description_en }}</p>
                    </div>
                    <div class="item-short-desc my-2" v-else>
                        <p>{{ item.description }}</p>
                    </div>
                    <div class="item-variants my-4">
                        <div class="item-colors my-2" v-if="children.length > 0">
                            <h6><b>{{ __('Varianty') }}:</b></h6>
                            <div class="row">
                                <div class="item-color col-2 p-0 d-flex"  v-for="child in children" >
                                    <a :href="'/eshop/product/'+child.slug" style="display: flex">
                                        <img v-lazy="child.photo.thumb_path" class="img-variety w-100 m-auto" :alt="child.slug"/>
                                        <!--                                                <a class="img-variety-a"-->
                                        <!--                                                   :href="'/eshop/product/'+child.slug">-->
                                        <!--                                                    {{  child.name  }}-->
                                        <!--                                                </a>-->

                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="item-colors my-2" v-if="parent">
                            <h6><b>{{ __('Varianty') }}:</b></h6>
                            <div class="row">
                                <div class="item-color col-2 p-0 d-flex"  >
                                    <a :href="'/eshop/product/'+parent.slug" style="display: flex">
                                        <img v-lazy="parent.photo.thumb_path" class="img-variety w-100 m-auto" :alt="parent.slug"/>
                                        <!--                                                <a class="img-variety-a"-->
                                        <!--                                                   :href="'/eshop/product/'+child.slug">-->
                                        <!--                                                    {{  child.name  }}-->
                                        <!--                                                </a>-->

                                    </a>
                                </div>
                                <div class="item-color col-2 p-0 d-flex" v-for="relative in relatives" >
                                    <a :href="'/eshop/product/'+relative.slug" style="display: flex">
                                        <img v-lazy="relative.photo.thumb_path" class="img-variety w-100 m-auto" :alt="relative.slug"/>
                                        <!--                                                <a class="img-variety-a"-->
                                        <!--                                                   :href="'/eshop/product/'+child.slug">-->
                                        <!--                                                    {{  child.name  }}-->
                                        <!--                                                </a>-->

                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="item-sizes my-2" v-if="parameters.length > 0">
                            <h6><b>{{ __('Vyberte velikost') }}:</b></h6>
                            <div class="row">
                                <!--                                <span class="item-size" :style="{background: (params.filter(param => param.parameter == index && param.value == v.value).length > 0)?'rgba(116, 170, 80, 0.85)':null}" v-for="v in p" @click="setParam(v.value, index)">{{ v.value }} </span>-->
                                <span class="item-size"
                                      :style="{background: this.size?.id==p.id?'rgba(116, 170, 80, 0.85)':null }"
                                      v-for="p in parameters" @click="setSize(p)"><span class="text-sm-center pr-1" v-if="p.price > 0">+ {{calculatePrice(p.price, currency, false)}} {{currency.symbol}}</span>
                                {{ p.name }}</span>
                            </div>
                        </div>
                        <div class="size-chart" v-if="table">
                            <div class="row">
                                <p class="small row">{{__('Nejste si jisti svou velikostí? Podívejte se do naší')}}
                                    &nbsp;
                                    <button type="button" class="btn btn-link m-0 p-0" data-toggle="modal"
                                            data-target="#sizing-chart" style="text-decoration: underline">

                                         {{__('tabulky velikostí')}}
                                    </button>
                                    .
                                </p>
                                <div class="modal fade"
                                     id="sizing-chart"
                                     tabindex="-1"
                                     role="dialog"
                                     aria-labelledby="exampleModalLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header border-bottom-0 pb-0">
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 d-md-block d-none">
                                                        <img :src="table.photo_path" :alt="table.name" style="width: 100% !important;">
                                                    </div>
                                                    <div class="col-lg-9 col-md-8 col-12 p-0">
                                                        <h1>{{ table.name }}</h1>
                                                        <div class="ql-snow ql-container" style="overflow: auto">
                                                            <div class="ql-editor ql" v-html="table.content"></div>
                                                        </div>
                                                        <div class="d-md-none d-block text-center">
                                                            <img v-lazy="table.photo_path" style="width: 50% !important;" :alt="table.name">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="add-to-cart">
                            <span class="green" v-if="item.is_stocked"><i class="fas fa-check"></i> {{ __('Skladem') }}</span>
                            <span v-else class="red"><i class="fas fa-times"></i> {{ __('Není skladem') }}</span>
                            <button class="btn btn-primary mt-3" @click="addItem"
                                    >{{ __('Přidat do košíku') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-11">
                    <div v-for="(photo, index) in item.description_photos" :id="index">
                        <div class="row my-5 productDesc" v-if="index%2 == 0">
                            <div class="col-md-6 col-12 my-auto text-md-right text-center">
                                <img v-lazy="photo.path" width="80%"  :alt="item.slug + '-desc-'+photo.id"/>
                            </div>
                            <div v-if="locale == 'en'" class="col-md-6 col-12 my-auto text-md-left text-center desc ql-snow ql-container ql-editor"
                                 v-html="photo.description_en">
                            </div>
                            <div v-else class="col-md-6 col-12 my-auto text-md-left text-center desc ql-snow ql-container ql-editor" v-html="photo.description">
                            </div>
                        </div>
                        <div v-else>
                            <div class="row my-5 productDesc d-md-none d-flex" >
                                <div class="col-md-6 col-12 my-auto text-md-right text-center">
                                    <img v-lazy="photo.path" width="100%" :alt="item.slug + '-desc-'+photo.id"/>
                                </div>
                                <div v-if="locale == 'en'" class="col-md-6 col-12 my-auto text-md-left text-center desc"
                                     v-html="photo.description_en">
                                </div>
                                <div v-else class="col-md-6 col-12 my-auto text-md-left text-center desc ql-snow ql-container ql-editor" v-html="photo.description">
                                </div>
                            </div>

                            <div class="row my-5 productDesc d-md-flex d-none">
                                <div v-if="locale == 'en'" class="col-md-6 col-12 my-auto text-md-right text-center desc ql-snow ql-container ql-editor"
                                     v-html="photo.description_en">
                                </div>
                                <div v-else class="col-md-6 col-12 my-auto text-md-right text-center desc ql-snow ql-container ql-editor"
                                     v-html="photo.description">
                                </div>
                                <div class="col-md-6 col-12 my-auto text-md-left text-center">
                                    <img v-lazy="photo.path" width="100%" :alt="item.slug + '-desc-'+photo.id"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!--               Výpis recenzí-->
            <div class="container bg-light py-4 my-5">
                <div class="row justify-content-center">
                    <div class="col-12 text-center mb-3">
                        <h3>{{ __('Hodnocení zákazníků') }}</h3>
                    </div>
                    <div v-for="(review, index) in reviews" v-if="index >= 1">
                        <div class="col-3 text-right">
                            <star-rating v-model:rating="review.stars" :star-size="20" :read-only="true"
                                         :show-rating="false"/>
                            <p class="text-uppercase mt-2 mb-0 pb-0">
                                {{ review.user.first_name + " " + review.user.last_name }}</p>
                            <p>{{ this.reviewDate(review) }}</p>
                        </div>
                        <div class="col-7 text-left">
                            <h5>{{ review.name }}</h5>
                            <p>{{ review.content }}</p>
                        </div>F
                        <div class="col-10">
                            <hr>
                        </div>
                    </div>
                    <div class="col-12 text-center" v-if="user !== null && can_review">
                        <button class="btn btn-primary btn-detail mx-auto" @click="showForm">
                            {{ __('Přidat hodnocení') }}
                        </button>
                    </div>
                    <!--                    TODO: Zobrazit formulář namísto hodnocení po kliknutí na "Přidat hodnocení"-->
                    <div class="col-12 text-center">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <form @submit.prevent="sendReview" style="display: none" class="col-md" id="form"
                                      v-if="user !== null && can_review">
                                    <div class="form-group">
                                        <label for="name">{{ __('Předmět hodnocení') }}</label>
                                        <input id="name"
                                               type="text"
                                               class="form-control"
                                               v-model="review.name"
                                               required/>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ __('Hodnocení') }}</label>
                                        <star-rating v-model:rating="review.stars" :star-size="20"
                                                     :show-rating="false"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="content">{{ __('Vaše hodnocení') }}</label>
                                        <textarea id="content"
                                                  v-model="review.content"
                                                  class="form-control"
                                                  required></textarea>
                                    </div>

                                    <input type="submit"
                                           :value="__('Odeslat')"
                                           class="btn btn-primary btn-detail mx-auto mt-3"/>
                                </form>
                                <div class="col-md" id="reviews">
                                    <div class="justify-content-center border-bottom"
                                         v-for="(review, index) in reviews">
                                        <div style="height: 1.2rem" v-if="index >= 1"/>
                                        <h5 class="text-info">{{ review.name }}</h5>
                                        <star-rating v-model:rating="review.stars" :star-size="20" :read-only="true"
                                                     :show-rating="false"/>
                                        <div class="d-flex flex-row">
                                            <p class="reviewUser">
                                                {{ review.user.first_name + " " + review.user.last_name }}</p>
                                            <p class="reviewDate ml-auto">{{ this.reviewDate(review) }}</p>
                                        </div>
                                        <p class="reviewContent">{{ review.content }}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="recommended.length > 0">
                <div class="col-md-12">
                    <h4 class="my-4"><b>{{ __('Mohlo by se Vám hodit') }}:</b></h4>
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-2 item-card item-card-sm" v-for="item in recommended">
                            <div class="card itmImg border-0 w-100">
                                <a :href="'/eshop/product/'+item.slug" class="d-flex" style="height: 300px"><img :src="item.photo.thumb_path"
                                                                            class="card-img-top item pb-0" width="100%" :alt="item.slug"></a>
                                <div class="p-0 card-body text-center d-flex flex-grow-0 mt-auto">
                                    <div class="mt-auto mx-auto col p-0">
                                        <div class="item-card-name">{{ item.name }}</div>
                                        <div class="row mb-2 align-items-end" style="min-height: 60px">
                                            <div class="col text-left p-0">
                                                <div v-if="item.discounted_price_dph > 0" style="line-height: 23px">
                                                    <span class="obsolete">{{ calculatePrice(item.price, currency) }} {{currency.symbol}}</span><br/>
                                                    <span class="discounted">{{ calculatePrice(item.discounted_price, currency) }} {{currency.symbol}}</span>
                                                </div>
                                                <div class="d-flex" v-else>
                                                    <span class="mt-auto regular"> {{ calculatePrice(item.price, currency) }} {{currency.symbol}} </span>
                                                </div>
                                            </div>
                                            <a :href="'/eshop/product/'+item.slug" class="btn btn-primary btn-detail mx-auto mb-0 mt-auto h-100">Detail</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else>
            Loading
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';
import {Carousel, Navigation, Slide, Pagination} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import StarRating from "vue-star-rating";
import {useToast} from "vue-toastification";
export default {
    name: "IndexItemsComponent",
    props: ["item", "path", "tags", 'reviews', "parameters", "children", "can_review", "recommended", "table", "categories", "parent", "relatives"],
    components: {
        Pagination,
        Carousel,
        Slide,
        Navigation,
        StarRating,
        'inner-image-zoom': InnerImageZoom
    },
    setup() {
        const toast = useToast();
        return {toast}
    },
    data: () => {
        return {
            table_opened: false,
            review: {
                name: null,
                content: null,
                stars: 0,
            },
            size: null,
            clicked: 1,
            loaded: false,
            loading: true,
            carouselSettings: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            carouselBreakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },
        }
    },
    mounted() {
        window.addEventListener('DOMContentLoaded', e => {
            this.loading = false
        })
    },
    watch: {
      clicked(a){
          if(this.item.photos.length < a) this.clicked = 1;
          if(a < 0)this.clicked = Math.abs(a)
          else if(a == 0)this.clicked = 1;
      }
    },
    computed: {
        selected() {
            return true
            // return this.params.every(i => i.value != null) && this.params.length > 0
        },
        user() {
            return window.auth_user
        },
        locale() {
            return window._locale
        },
        currency() {
            return window._currency;
        },
        price(){
            return this.item.discounted_price_dph > 0?this.item.discounted_price +(this.size != null?this.size.price/1.21:0): this.item.price +(this.size != null?this.size.price/1.21:0)
        }
    },
    methods: {
        tableSwitch(e) {
            this.table_opened = !this.table_opened
        },
        showForm() {
            document.getElementById('form').style.display = "block"
            document.getElementById('reviews').style.display = "none"
        },
        sendToRewDOM(e) {
            document.getElementById("reviews").scrollIntoView()
        },
        setSize(p) {
            this.size = p
            // this.parameters[index].forEach(p => {
            //     if (p.value == e) {
            //         this.params.forEach(i => {
            //             if (i.parameter == index) {
            //                 i.value = e
            //             }
            //         })
            //     }
            // })

        },
        sendReview() {
            if (this.review.name.length < 2) {
                Swal.fire({
                    title: this.__('Předmět musí být delší.'),
                    icon: 'error'
                })
                return
            }
            if (this.review.content.length < 8) {
                Swal.fire({
                    title: this.__('Text recenze musí být delší.'),
                    icon: 'error'
                })
                return
            }
            axios.post('/eshop/product/' + this.item.slug + "/review", {
                review: this.review,
                csrf: document.getElementsByTagName('meta').namedItem('csrf-token').content
            }).catch(err => {
                Swal.fire({
                    title: this.__('Recenzi se nepodařilo poslat.'),
                    text: err.response.data.message,
                    icon: 'error'
                })
            }).then(res => {
                if (res.data.success) {
                    Swal.fire({
                        title: res.data.message,
                        icon: 'success'
                    }).then(result => {
                        document.getElementById('form').style.display = "none"
                        document.getElementById('reviews').style.display = "block"
                        location.reload()
                    })
                }
            })
        },
        async addItem() {
            if(this.size == null){
                this.toast.error(this.__('Prosím vyberte velikost.'))
                return
            }
            const res = await axios.post("/api/eshop/cart/" + this.item.id + "/" + this.size.id);
            if(!res.data.success){
                this.toast.error(this.__('Produkt se nepodařilo přidat do košíku.'))
            }else {
                this.toast.success(this.__('Produkt byl přidán do košíku.'));
                this.$store.state.cart = res.data.cart
            }
        },
        reviewDate(review) {
            let date = review.created_at;
            let y = date.substr(0, 4);
            let m = date.substr(5, 2)
            let d = date.substr(8, 2)
            let prod = d + '. ' + m + '. ' + y;
            return prod;
        }
    }

}
</script>

<style scoped>
/* Showcase */

.img-container-style {
    border: 1px solid transparent;
    border-radius: 2px;
    transition: all 0.5s ease;
    background: #e9ecef;
    padding-top: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.img-container {
    margin-top: 25px;
    position: relative;
    text-align: center;
    color: white;
}

.img-container-style:hover {
    border: 1px solid #181818;
    border-radius: 2px;
    transition: all 0.5s ease;
}

.img-variety {
    /*object-fit: cover;*/
    /*width: 5em;*/
    /*height: 5em;*/
    /*margin: 0 auto;*/
}

.img-variety-a {

}

.previewImg {
    width: 100%;
    height: auto;
    cursor: pointer;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 2px;
    transition: all 0.5s ease;
    max-height: 120px;
    object-fit: contain;
    /*background: #e9ecef;*/
}

@media screen and (max-width: 768px) {
    .previewImg {
        max-width: 60px;
        height: 80px;
        margin: 5px 12px 5px 12px;
    }
}

.previewContainer {
    border-top: 2px solid #363636;
    border-bottom: 2px solid #363636;
    margin-top: 20px;
    margin-bottom: 10px;
}

.previewImg:hover {
    /*border: 1px solid #181818;*/
    border-radius: 2px;
    transition: all 0.5s ease;
}

.selected {
    /*border: 1px solid #282828;*/
    border-radius: 2px;
}

/*.pic-box {*/
/*    background: #e9ecef;*/
/*}*/

.productName {
    magin-top: 2rem;
    margin-bottom: 5rem;
}

.price {
    font-size: 16px;
    display: flex;
    position: relative;
}

.DPHprice {
    color: gray;
    display: flex;
}

.DPHpriceNum {
    color: gray;
    margin-left: auto;
}

.priceNum {
    color: red;
    font-size: 24px;
    text-align: right;
    margin-left: auto;
}

/* Recenze */
.reviewDate {
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0.3rem;
    font-size: 0.9em;
}

.reviewContent {
    color: rgba(0, 0, 0, 0.9);
}

.reviewUser {
    font-weight: 600;
    margin-bottom: 0.3rem;
    font-size: 0.9em;
}

/* Carousel */

.carousel__item {
    height: 154px;
}

.orderItemImg {
    object-fit: cover;
    object-position: top;
    width: 175px;
    height: 129px;
}

/*.orderItemImgFilter {*/
/*    object-fit: cover;*/
/*    object-position: top;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 180px;*/
/*    height: 121px;*/
/*    z-index: -1;*/
/*    background: rgba(255,250,205,0.5);*/
/*    border: 1px solid rgba(0,0,0,0.5);*/
/*}*/

/* Popis */

.dPhoto {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 200px;
}

.dText {

}
</style>
